//Material UI
import { Button, Grid, Typography, Box, Divider, IconButton } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import CachedIcon from '@mui/icons-material/Cached';

//React-Router
import { useNavigate } from 'react-router';

//react
import { useReducer, useEffect } from 'react';

//Local Imports
import MainCard from "components/MainCard";
import T from "utils/constants/T";
import { StyledBodyTypography } from '../CompanyTab/CompanyInfo';
import CashDCard from 'components/common/CashDCard';
import CashDAutocomplete from 'components/common/CashDAutocomplete';
// import { BANKING_ENVIRONMENT_LIST, TRANSACTION_FEE_DEDUCTION_TYPES, TRUE_OR_FALSE_OPTIONS } from 'utils/constants/companyDetails';
import { BANKING_ENVIRONMENT_LIST } from 'utils/constants/companyDetails';
import { SECRET_ENCRYPTION_KEY } from 'utils/ServerConstants';
import ViewBankingDetails from './ViewBankingDetails';
// import CashDTextField from 'components/common/CashDTextfield';
import { dispatch } from 'store';
// import { SyncPaymentPartnerTransactions, fetchPaymentPartnersList, getExistingPaymentPartner, getPaymentPartnersById, updateBankAccountValidate, updateBankingEnvironment, updatePaymentPartner } from 'store/reducers/companyBankingSettings';
import { 
    // SyncPaymentPartnerTransactions,
     fetchPaymentPartnersList, getExistingPaymentPartner, getPaymentPartnersById, updateBankingEnvironment, updatePaymentPartner } from 'store/reducers/companyBankingSettings';
import CashDDialog from 'components/common/CashDDialog';
import ChangeLenderDetails from './ChangeLenderDetails';

//react-redux
import { useSelector } from 'react-redux';

//lodash
import { get } from 'lodash';

//CSS
import '../../companyDetails.css';
import './banking.css';
import { updateCompanyDetails } from 'store/reducers/companyTabDetails';

//crypto-js
var CryptoJS = require("crypto-js");


const NO_PAYMENT_PARTNER = {
    code: ""
};
const EditPayrollSettings = () => {
    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const selectedCompanyId = get(selectedCompany, "_id", "");
    const { companyName, paymentSystemId, bankingEnvironment } = useSelector((state: any) => state.companyTabData);
    const { paymentPartnerList, existingPaymentPartner, accountNumber, lenderId, fundingType, nameOfBank, balance, accountName, bsb, interestRate } = useSelector((state: any) => state.bankingSettingsTabData);
    const existingPaymentPartnerObj = paymentPartnerList.find((res: any) => res.code === existingPaymentPartner) || NO_PAYMENT_PARTNER;

    // const formattedPaymentPartnerList = paymentPartnerList.map((item:any)=>item.code)
    const navigate = useNavigate();
    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any) => ({ ...prevState, ...newState }),
        {
            paymentPartner: existingPaymentPartnerObj,
            // validateBankAccount: bankingValidateBankAccount===false?T.FALSE:T.TRUE,
            environment: bankingEnvironment === false ? T.TEST : T.LIVE,
            openLenderChangeModal: false,

        }
    );

    const {
        paymentPartner,
        environment,
        openLenderChangeModal,
    } = localState;


    useEffect(() => {
        dispatch(fetchPaymentPartnersList());
    }, [])

    useEffect(() => {
        if (paymentSystemId) {
            dispatch(getPaymentPartnersById(paymentSystemId));
        }
        else {
            dispatch(getExistingPaymentPartner({ existingPaymentPartner: {} }))
            setLocalState({ paymentPartner: {} })
        }
    }, [paymentSystemId])

    const handleBack = () => {
        navigate('/companyDetails/bankingSettings/view');
    }

    //Decryption of encrypted banking data from backend
    const decryptBankingData = (text: any) => {
        const decryptedData = CryptoJS.AES.decrypt(text, SECRET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);

        return JSON.parse(JSON.stringify(decryptedData));
    }

    //Banking Environment Change handler
    const handleBankingEnvironmentChange = (type: string, newValue: any) => {

        const payload = {
            lenderId: lenderId,
            // lenderId: "",
            is_monoova_live_mode: environment === T.TEST ? true : false
        }

        dispatch(updateBankingEnvironment(selectedCompanyId, payload));
        dispatch(updateCompanyDetails({ key: 'bankingEnvironment', value: payload.is_monoova_live_mode }))
        setLocalState({ [type]: newValue });
    }

    //Payment partner Change handler
    const handlePaymentPartnerChange = (type: string, newValue: any) => {
        const id = get(newValue, "_id", "");
        const payload = {
            companyId: selectedCompanyId,
            paymentSystemId: id
        }

        dispatch(updatePaymentPartner(payload));
        setLocalState({ [type]: newValue });
    }

    //Payment partner transaction sync handler
    // const handlePaymentPartnerTransactionSync = () => {
    //     dispatch(SyncPaymentPartnerTransactions(selectedCompanyId));
    // }

    //Lender Change Modal Opening handler
    const handleLenderChangeModal = () => {
        setLocalState({ openLenderChangeModal: !openLenderChangeModal })
    }

    //Add or Edit button click handler
    const handleButtonAction = (text: string) => {
        handleLenderChangeModal()
    }

    //view banking read only data collection
    const renderViewBankingData = () => ({
        accountName,
        fundingType,
        nameOfBank,
        bsb: decryptBankingData(bsb),
        accountNumber: decryptBankingData(accountNumber),
        balance,
        interestRate
    })
    return (
        <>
            <MainCard
                title={companyName}
                secondary={
                    <>
                        <Button
                            variant="contained"
                            className='main-card-button'
                            onClick={handleBack}
                        >
                            {T.BACK}
                        </Button>
                        {/* &nbsp;&nbsp;&nbsp;
                        <Button
                            variant="outlined"
                            className='main-card-button'
                            startIcon={<CachedIcon />}
                            onClick={handlePaymentPartnerTransactionSync}
                        >
                            {T.SYNC_PAYMENT_PARTNER_TRANSACTIONS}
                        </Button> */}
                    </>
                }>

                <CashDCard borderRadius='20px'>
                    <Grid container rowSpacing={4} columnSpacing={15}>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <Grid container className='top-box' rowSpacing={1}>
                                    <Grid item xs={6}>
                                        <StyledBodyTypography>{T.PAYMENT_PARTNER_SELECTION}</StyledBodyTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CashDAutocomplete
                                            placeholder={T.CHOOSE_PAYMENT_PARTNER}
                                            fullWidth
                                            listDetails={paymentPartnerList}
                                            value={paymentPartner || null}
                                            getByLabelText={(option: any) => option.code}
                                            onHandleChange={(event: any, newValue: any) =>
                                                handlePaymentPartnerChange(
                                                    "paymentPartner",
                                                    newValue
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StyledBodyTypography>{T.ENVIRONMENT}</StyledBodyTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CashDAutocomplete
                                            disabled={!lenderId ? true : false}
                                            placeholder={T.CHOOSE_SETTING}
                                            fullWidth
                                            listDetails={BANKING_ENVIRONMENT_LIST}
                                            value={environment}
                                            getByLabelText={(option: any) => option}
                                            onHandleChange={(event: any, newValue: any) =>
                                                handleBankingEnvironmentChange(
                                                    "environment",
                                                    newValue
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CashDCard>
                {
                    lenderId ?
                        <CashDCard borderRadius='20px'>
                            <ViewBankingDetails
                                {...renderViewBankingData()}
                            />
                            <Divider />
                            <Box className='add-button'>
                                <Button variant='contained' onClick={() => handleButtonAction(T.EDIT.toLowerCase())}>
                                    {T.CHANGE}
                                </Button>
                            </Box>
                        </CashDCard>
                        :
                        <CashDCard borderRadius='20px'>
                            <Typography className='lender-heading'>
                                {T.LENDER_DETAILS}
                            </Typography>
                            <Box className='add-button'>
                                <Button variant='contained' onClick={() => handleButtonAction(T.ADD.toLowerCase())}>
                                    {T.ADD}
                                </Button>
                            </Box>
                        </CashDCard>
                }
            </MainCard>
            {

                <CashDDialog
                    open={openLenderChangeModal}
                    handleClose={handleLenderChangeModal}
                >
                    <Box className="header-box" >
                        <Typography variant="h4" className="view-table-heading">
                            {T.SELECT_LENDER}
                        </Typography>
                        <Box
                            className="MuiIconButton-root"
                        >
                            <IconButton
                                onClick={handleLenderChangeModal}
                            >
                                <CancelOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <ChangeLenderDetails
                        selectedCompanyId={selectedCompanyId}
                        handleLenderChangeModal={handleLenderChangeModal}
                    />
                </CashDDialog>
            }
        </>
    );
};

export default EditPayrollSettings;