import noop from "lodash/noop";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import TextField from '@mui/material/TextField';
import WithInputLabel from "./WithInputLabel";
import CashDTextField from "./CashDTextfield";

interface CashdDatePickerProps {
  label?: any;
  fullWidth?: any;
  autoComplete?: any;
  disabled?: boolean;
  required?: boolean;
  deprecatedLabel?: boolean;
  name?: string;
  width?: string;
  paddingStyle?: string;
  endIcon?: React.ReactNode;
  error?: string;
  placeholder?: string;
  size?: string;
  variant?: any;
  format?: string;
  value?: any;
  minDate?: string;
  handleChange?: (value: any, name: string) => void;
}

const CashdDatePicker: React.FC<CashdDatePickerProps> = ({
  label = "",
  fullWidth ="",
  autoComplete ="",
  disabled,
  required,
  deprecatedLabel = true,
  name = "",
  size = "",
  paddingStyle= "",
  width= "",
  variant = "",
  endIcon,
  error = "",
  placeholder = "SELECT",
  format = "MM/DD/YYYY",
  value = {},
  minDate = "",
  handleChange = noop,
  ...rest
}) => {
  return (
    <WithInputLabel
      label={deprecatedLabel && label}
      required={required}
      endIcon={endIcon}
      error={error}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat={format}
          value={value}
          minDate={minDate}
          disabled={disabled}
          onChange={(value) => handleChange(value, name)}
          {...rest}
          renderInput={(params) => (
            <CashDTextField
            variant="outlined"
            placeholder={placeholder}
            sx={{ "& .MuiOutlinedInput-root": { p: 0 },
                  "& .MuiButtonBase-root":{ pt:"5px" },
                  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall":{ pb: paddingStyle},
                  "& .MuiIconButton-root":{ mr: "0px"},
                  width :{width}
                }}
            {...params}
            />
          // <TextField 
          // {...params} 
          //     size="small"
          //     autoComplete ="on"
          //     variant="outlined"
          //     fullWidth
          
          // />
          )}
          // renderInput={(params) => (
          //   <TextField
          //     size={size}
          //     autoComplete={autoComplete}
          //     variant={variant}
          //     fullWidth ={fullWidth}
          //     {...params}
          //     sx={{
          //       backgroundColor: "background.white",
          //       "& .MuiOutlinedInput-input": {
          //         fontSize: 14,
          //       },
          //     }}
          //     InputProps={{
          //       ...params.inputProps,
          //       placeholder: placeholder,
          //     }}
          //   />
          // )}
        />
      </LocalizationProvider>
    </WithInputLabel>
  );
};

export default CashdDatePicker;