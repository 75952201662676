//react
import { useEffect, useReducer } from 'react';

//Material-UI
import { Typography, Button, Box } from "@mui/material"

//Local-Imports
import EditBankingTable from "./EditBankingTable";
import { UpdateCompanyLender, fetchLendersDetailedList } from 'store/reducers/companyBankingSettings';
import { dispatch } from 'store';
import T from 'utils/constants/T';
import { SECRET_ENCRYPTION_KEY } from 'utils/ServerConstants';

//react-redux
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import CashDDialog from 'components/common/CashDDialog';

//CSS
import './banking.css'

//crypto-js
var CryptoJS = require("crypto-js");



interface ChangeLenderDetailsProps {
    selectedCompanyId?: string,
    handleLenderChangeModal?: any
}


const ChangeLenderDetails = ({
    selectedCompanyId = "",
    handleLenderChangeModal = ""
}: ChangeLenderDetailsProps) => {

    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any) => ({ ...prevState, ...newState }),
        {
            openLenderChangeConfirmationModal: false,
            newLenderId: "",
            newLenderAccountName: ""

        }
    );

    const {
        openLenderChangeConfirmationModal,
        newLenderId,
        newLenderAccountName,
    } = localState;
    const { lendersList } = useSelector((state: any) => state.bankingSettingsTabData);

    //Decryption of encrypted banking data from backend
    const decryptBankingData = (text: any) => {
        if (!text) return text
        const decryptedData = CryptoJS.AES.decrypt(text, SECRET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);

        return JSON.parse(JSON.stringify(decryptedData));
    };

    let allTableRows: any[] = []
    lendersList.map((row: any, index: number) => {

        const creditVal = get(row, "total_amount", "");
        const debitVal = get(row, "total_deduction", "");
        const balanceVal = creditVal - debitVal;
        const iRateVal = get(row, "interest_rate_value", "");
        const iRateType = get(row, "interest_rate_type", "") === T.DOLLARTEXT ? T.DOLLAR : get(row, "interest_rate_type", "") === T.PERCENT && T.PERCENTAGE;
        const finalInterestRate = iRateType === T.DOLLAR ? `${iRateType}${iRateVal}` : `${iRateVal}${iRateType}`;
        const bsb = get(row, `test_receivables_account_bsb`, "");
        const accountNumber = get(row, `test_receivables_account_number`, "");
        allTableRows[index] = ({
            lenderId: get(row, "_id", ""),
            accountName: get(row, `test_receivables_account_name`, ""),
            fundingType: get(row, "funding_type", ""),
            nameOfBank: T.MONOOVA,
            bsb: decryptBankingData(bsb),
            accountNumber: decryptBankingData(accountNumber),
            balance: balanceVal,
            interestRate: finalInterestRate
        })
    })

    //Get lenders list from api on page render
    useEffect(() => {
        dispatch(fetchLendersDetailedList(selectedCompanyId))
    }, [])


    const lenderUpdate = (newLenderId: string) => {
        const payload = {
            newLenderId: newLenderId,
            companyId: selectedCompanyId,
        }
        dispatch(UpdateCompanyLender(payload))
    }


    //Add/change Lender Handler
    const handleAddChangeLender = (newLenderId: string, newLenderAccountName: string) => {
        setLocalState({ newLenderId: newLenderId, newLenderAccountName: newLenderAccountName });
        handleLenderChangeConfirmationModal();
    }


    //Lender Change confirmation Modal Opening handler

    const handleLenderChangeConfirmationModal = () => {
        setLocalState({ openLenderChangeConfirmationModal: !openLenderChangeConfirmationModal });
    }


    //Lender Change confirmation handler

    const handleChangeLenderConfirmation = () => {

        // update lender details on server
        lenderUpdate(newLenderId);

        // close lender change confirmation modal
        handleLenderChangeConfirmationModal();

        // close lender change modal 
        handleLenderChangeModal()
    }


    return (
        <>
            <EditBankingTable
                allTableRows={allTableRows}
                handleAddChangeLender={handleAddChangeLender}
            />

            <CashDDialog
                open={openLenderChangeConfirmationModal}
                handleClose={handleLenderChangeConfirmationModal}
            >
                <Typography>
                    {`${T.ARE_YOU_SURE_YOU_WANT_TO_ADD} ${newLenderAccountName}`}
                </Typography>
                <Box className="confirmation_modal">
                    <Button variant='outlined' onClick={handleLenderChangeConfirmationModal}>
                        {T.NO}
                    </Button>
                    <Button variant='contained' onClick={handleChangeLenderConfirmation}>
                        {T.YES}
                    </Button>
                </Box>
            </CashDDialog>

        </>
    );
};

export default ChangeLenderDetails;
