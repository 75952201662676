//react
import { useEffect } from 'react';


//Material- UI
import { Button } from '@mui/material';

//React-router-dom
import { useNavigate } from 'react-router-dom';

//react-redux
import { useSelector } from 'react-redux';

//Local imports
import MainCard from 'components/MainCard';
import '../../companyDetails.css';
import CompanyInfo from './CompanyInfo';
import T from 'utils/constants/T';

//lodash
import { get } from 'lodash';

const ShowCompanyDetails = () => {
    const { companyName } = useSelector((state: any) => state.companyTabData);
    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const selectedCompanyId= get(selectedCompany,"_id","")
    const navigate = useNavigate()
    const handleEdit = () => {
        navigate("/companyDetails/company/edit")
    }

    useEffect(()=>{
        if(!selectedCompanyId)
        {
            navigate("/dashboard")
        }
    },[selectedCompanyId])

    return (
        <MainCard
            title={companyName}
            secondary={
                <Button onClick={handleEdit} variant='contained' className='main-card-button'>
                    {T.EDIT}
                </Button>
            }
        >
            <CompanyInfo />

        </MainCard>

    );
};

export default ShowCompanyDetails;