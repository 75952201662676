import { Paper } from "@mui/material";
import {
  Button,
  Grid,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
  Typography,
  Table as TableView,
  TableBody,
} from "@mui/material";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "pages/CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import { useEffect, useReducer } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import T from "utils/constants/T";
import "./DeductionFiles.css";
import {
  fetchViewDeductionData,
  runDeduction,
} from "store/reducers/deductions";
import { useDispatch, useSelector } from "react-redux";
import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import MasterPagination from "utils/MasterPagination";
import RunDeduction from "./Deductions/DeductionActions/RunDeduction";
import { convertDateIntoFrontEndFormat } from "utils/date";
import Loader from "components/Loader";
import { formatAmount } from "utils/CommonMethods";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  textTransform: "capitalize",
  padding: "5px",
}));

export const StyledTableHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
}));

const DeductionFiles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { deductionTableList, deduction } = location.state;

  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const selectedCompanyId = get(selectedCompany, "_id", "");

  let { companyId: paramCompanyId, payperiodId: paramPayPeriodId } =
    useParams();
  const {
    deductionViewList,
    totalViewDeductionItems,
    totalViewDeductionPages,
    viewPageSize,
    viewPageNo,
  } = useSelector((state: any) => state.deductionData);

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      openRunDeductionDialog: false,
      allTableRows: [],
      isLoading: false,
    }
  );

  useEffect(()=>{
    if(selectedCompanyId !== paramCompanyId){
      navigate(-1)
    }
  },[selectedCompanyId])

  const { openRunDeductionDialog, isLoading } = localState;

  let viewDeductionRows: any = [];
  deductionViewList.map((item: any, index: number) => {
    viewDeductionRows[index] = {
      srNo: index + 1,
      employeeName: get(item, "staff.fullname", ""),
      employeeType: get(item, "staff.fullname", ""),
      totalAmountSent: get(item, "total_amount_sent", ""),
      totalAmountUnsent: get(item, "total_amount_unsent", ""),
      totalCapitalSent: get(item, "total_deductions_sent", ""),
      totalCapitalUnsent: get(item, "total_deductions_unsent", ""),
      totalFeeSent: get(item, "total_fee_sent", ""),
      totalFeeUnsent: get(item, "total_fee_unsent", ""),
      payroll_id: get(item, "staff.system_employee_id", ""),
      payroll_type: get(item, "staff.code", ""),
    };
  });

  let deductionDataList: any = [];
  deductionTableList.map((data: any, index: number) => {
    deductionDataList[index] = {
      company_id: get(data, "company_details._id", ""),
      company_name: get(data, "company_details.company_name", ""),
      pay_periods: `${convertDateIntoFrontEndFormat(
        data.start_date
      )} To ${convertDateIntoFrontEndFormat(data.end_date)}`,
      deduction_status: data.is_write_deductions_back_system
        ? T.SENT
        : T.UNSENT,
      amount: data.is_write_deductions_back_system
        ? data.total_deductions_sent
        : data.total_deductions_unsent,
      pay_period_id: get(data, "_id", ""),
      isPreventWithdrawals: get(data, "is_prevent_withdrawals", ""),
    };
  });

  let filteredDeductionDataList: any = [];
  filteredDeductionDataList = deductionDataList.filter(
    (item: any) =>
      item.company_id === paramCompanyId &&
      item.pay_period_id === paramPayPeriodId
  );
  let status = filteredDeductionDataList[0].deduction_status;
  console.log(status);
  const handleClose = () => { };

  //Deduction run handler
  const handleRunDeductionDailog = () => {
    setLocalState({ openRunDeductionDialog: !openRunDeductionDialog });
  };

  const handleRunDeduction = () => {
    handleRunDeductionDailog();
    handleClose();
  };

  const runSelectedDeductions = async () => {
    setLocalState({ isLoading: true });
    await dispatch(runDeduction(paramCompanyId, paramPayPeriodId));
    setLocalState({ isLoading: false });
    navigate(`/deductions`)
    handleRunDeductionDailog();
  };

  const checkSalaryWag = (salaryWag: any, systemCode: any) => {
    let employeeType;
    if (systemCode === "DEPUTY") {
      employeeType = "Timesheet";
    } else {
      if (salaryWag === 2) {
        employeeType = "Timesheet";
      } else if (salaryWag === 3) {
        employeeType = "Salary & TimeSheet";
      } else {
        employeeType = "Salary";
      }
    }
    return employeeType;
  };

  const onPageChange = (newPage: number) => {
    setLocalState({ page: newPage });
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === paramPayPeriodId
    );
    const statusDeduction: any = get(
      filteredList,
      "[0].is_write_deductions_back_system",
      ""
    );
    const deductionStatus = statusDeduction ? "sent" : "unsent"
    dispatch(
      fetchViewDeductionData(
        newPage,
        viewPageSize,
        paramCompanyId,
        paramPayPeriodId,
        deductionStatus
      )
    );
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const onRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({
      page: INITIAL_PAGE,
      pageSize: Number(value),
    });
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === paramPayPeriodId
    );
    const statusDeduction: any = get(
      filteredList,
      "[0].is_write_deductions_back_system",
      ""
    );
    const deductionStatus = statusDeduction ? "sent" : "unsent"
    dispatch(
      fetchViewDeductionData(
        INITIAL_PAGE,
        value,
        paramCompanyId,
        paramPayPeriodId,
        deductionStatus
      )
    );
  };

  useEffect(() => {
    setLocalState({ isLoading: true });
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === paramPayPeriodId
    );
    const statusDeduction: any = get(
      filteredList,
      "[0].is_write_deductions_back_system",
      ""
    );
    const deductionStatus = statusDeduction ? "sent" : "unsent"
    dispatch(
      fetchViewDeductionData(
        INITIAL_PAGE,
        ROWS_PER_PAGE,
        paramCompanyId,
        paramPayPeriodId,
        deductionStatus
      )
    );
    setLocalState({ isLoading: false });
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderTop: "inherit",
    overflow: "hidden",
    padding: "8px 0px 8px 10px",
    // flexWrap: "wrap"
    // ...theme.typography.subtitle1,
  }));

  return (
    <>
      {isLoading && <Loader />}
      <RunDeduction
        openRunDeductionDialog={openRunDeductionDialog}
        handleRunDeductionDailog={handleRunDeductionDailog}
        runSelectedDeductions={runSelectedDeductions}
      />
      <MainCard
        marginBottom="10px"
        title="Run Deduction"
        secondary={
          <>
            <Button
              variant="contained"
              onClick={handleRunDeduction}
              disabled={ status.toLowerCase()=== "sent" ? true : false}
            >
              Run
            </Button>
            <Button
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </>
        }
      >
        {/* upper box */}
        <Box>
          {filteredDeductionDataList.map((data: any) => {
            return (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <StyledTitleTypography>Company</StyledTitleTypography>
                    <StyledBodyTypography>
                      {data.company_name}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTitleTypography>
                      Payperiod & Schedule
                    </StyledTitleTypography>
                    <StyledBodyTypography>
                      {deduction.pay_periods}
                    </StyledBodyTypography>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <StyledTitleTypography>Status</StyledTitleTypography>
                    <StyledBodyTypography>
                      {data.deduction_status}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTitleTypography>Amount</StyledTitleTypography>
                    <StyledBodyTypography>
                      {formatAmount(data.amount)}
                    </StyledBodyTypography>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Box>

        <Box>
          <ScrollX>
            <Box overflow="hidden">
              <TableContainer
                component={Paper}
                sx={{ height: "inherit", overflowY: "auto" }}
              >
                <TableView stickyHeader>
                  <TableBody></TableBody>
                </TableView>
              </TableContainer>
            </Box>
          </ScrollX>
        </Box>
      </MainCard>
      <Grid
        item
        xs={12}
        color="#fff !important"
        padding="20px"
        border="1px solid #ddd"
        borderRadius="15px"
      >
        <TableView stickyHeader>
          <TableHead>
            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.S_NO}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.EMPLOYEE_NAME}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.PAYROLL_ID}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.PAY_TYPE}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.CAPITAL}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.FEE}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>

            <StyledTableHeaderCell align="center">
              <StyledTableHeaderTypography variant="body1" noWrap>
                {T.TOTAL}
              </StyledTableHeaderTypography>
            </StyledTableHeaderCell>
          </TableHead>
          <TableBody sx={{ minWidth: "auto" }}>
            {viewDeductionRows.map((record: any) => {
              console.log(record);
              return (
                <TableRow>
                  {status.toLowerCase() === "unsent" ? (
                    <>
                      <StyledTableCell align="center">
                        {record.srNo}.
                      </StyledTableCell>
                      <StyledTableCell align="center" width="200px">
                        {record.employeeName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {record.payroll_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {checkSalaryWag(
                          get(record, "salary_wag", ""),
                          deduction.systemCode
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(record.totalAmountUnsent)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(record.totalFeeUnsent)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(record.totalCapitalUnsent)}
                      </StyledTableCell>

                    </>
                  ) : (
                    <>
                      <StyledTableCell align="center">
                        {record.srNo}.
                      </StyledTableCell>
                      <StyledTableCell align="center" width="200px">
                        {record.employeeName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {record.payroll_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {checkSalaryWag(
                          get(record, "salary_wag", ""),
                          deduction.systemCode
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(Number(record.totalAmountSent))}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(Number(record.totalFeeSent))}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatAmount(Number(record.totalCapitalSent))}
                      </StyledTableCell>

                    </>
                  )}
                </TableRow>
              );
            })}

            {viewDeductionRows.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={7}>
                  <Typography variant="body1" textAlign="center">
                    {T.NO_RECORDS}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}

          </TableBody>
        </TableView>
        <MasterPagination
          page={viewPageNo}
          onPageChange={onPageChange}
          rowsPerPage={viewPageSize}
          onRowsPerPageChange={onRowsPerPageChange}
          totalTableRowsCount={totalViewDeductionItems}
          totalPageCount={totalViewDeductionPages}
        />
      </Grid>
    </>
  );
};

export default DeductionFiles;
