import noop from "lodash/noop";

import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";




import TableHeader from "../TableHeader";
import { PAGINATION } from "utils/constants/pagination";
import { TRANSACTION_TABLE_HEADER } from "utils/constants/transactions";
import TransactionsTableBody from "./TransactionsTableBody";
import MasterPagination from "utils/MasterPagination";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

interface TransactionsTableProps {
  page?: number,
  onPageChange?: (page:number)=>void;
  rowsPerPage?: number;
  onRowsPerPageChange?: (page:number)=>void;
  totalTableRowsCount?: number;
  totalPageCount?: number;
  allTableRows?: any;
  handleAddEditProjectInfoDialog?: ()=>void;
  handleDeleteProjectDialog?: ()=>void;

}
const TransactionsTable = ({
  page = INITIAL_PAGE,
  onPageChange= noop,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange= noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  handleAddEditProjectInfoDialog = noop,
  handleDeleteProjectDialog = noop,
}:TransactionsTableProps) => {
  return (
    <Card
      elevation={0}
      sx={{
        // p: "0px 8px",
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
          sx={{ height: "inherit", overflowY: "auto" }}
        >
          <TableView stickyHeader>
            <TableHeader columns={TRANSACTION_TABLE_HEADER} />
            <TransactionsTableBody
              page={page}
              rowsPerPage={rowsPerPage}
              totalTableRowsCount={totalTableRowsCount}
              totalPageCount={totalPageCount}
              allTableRows={allTableRows}
              handleAddEditProjectInfoDialog={handleAddEditProjectInfoDialog}
              handleDeleteProjectDialog={handleDeleteProjectDialog}
            />
          </TableView>
        </TableContainer>
      </Box>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default TransactionsTable;
