const Admincenter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2164_917)">
        <g clipPath="url(#clip1_2164_917)">
          <path
            d="M14.4443 1.80916L9 0.00390625L3.55575 1.80916C2.32575 2.21716 1.5 3.36091 1.5 4.65691V8.97316C1.5 13.8984 6.7545 16.9059 8.3625 17.7092L8.967 18.0122L9.5955 17.7602C11.2147 17.1077 16.5 14.5464 16.5 8.97241V4.65616C16.5 3.36016 15.6735 2.21716 14.4443 1.80916ZM15 8.97316C15 13.6067 10.4355 15.8049 9.036 16.3689C7.62 15.6609 3.00075 13.0382 3.00075 8.97316V4.65691C3.00075 4.00966 3.414 3.43666 4.02825 3.23341L9.00075 1.58491L13.9733 3.23341C14.5875 3.43666 15.0007 4.00966 15.0007 4.65691L15 8.97316ZM10.875 7.12516C10.875 7.89391 10.4122 8.55466 9.75 8.84341V12.0002H8.25V8.84341C7.58775 8.55391 7.125 7.89391 7.125 7.12516C7.125 6.08941 7.96425 5.25016 9 5.25016C10.0358 5.25016 10.875 6.08941 10.875 7.12516Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2164_917">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
        <clipPath id="clip1_2164_917">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Admincenter;
