import { Children } from "react";

import {
  Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  Box,
} from '@mui/material';

import { format } from 'date-fns';
import { memo } from "utils/memo";
import T from "utils/constants/T";
// import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";

//CSS
import "./transactionTable.css";

// const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1, 
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface TransactionsTableBodyProps {
  allTableRows: any[]
}
const TransactionsTableBody = ({
  allTableRows = []
}: TransactionsTableBodyProps) => {
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const status = get(record, "status", T.PENDING);
          const transactionDate = get(record, "Date", "");
          const date = new Date(transactionDate);
          const formattedDate = format(date, 'dd MMM yyyy h:mm a');
          const payPeriod = get(record, "pay_periods", "");
          const systemPayrollId = get(record, "system_payroll_id", T.NA);
          const scheduleName = get(record, "schedule_details.payPeriodName", "")
          return (
            <TableRow sx={{ background: "white" }} key={index}>
              {/* <StyledTableCell>{get(record,"srNo","")}.</StyledTableCell> */}
              <StyledTableCell>
                <Box className="table-column">
                  {formattedDate}
                </Box>
              </StyledTableCell>
              <StyledTableCell>{get(record, "company_name", "")}</StyledTableCell>
              <StyledTableCell >
                <Box className="table-column">
                  {payPeriod}
                </Box>
              </StyledTableCell>
              <StyledTableCell>{scheduleName}</StyledTableCell>
              <StyledTableCell>{get(record, "employee_name", "")}</StyledTableCell>
              <StyledTableCell align="center">{systemPayrollId}</StyledTableCell>
              <StyledTableCell align="center">{get(record, "transaction_id", "")}</StyledTableCell>
              <StyledTableCell>{`$${get(record, "debit", "")}`}</StyledTableCell>
              <StyledTableCell>{`$${get(record, "fee", "")}`}</StyledTableCell>
              <StyledTableCell>
                <Chip color={status === T.PENDING ? "error" : status === T.COMPLETED ? "success" : "info"} label={status} size="small" variant="light" />
              </StyledTableCell>
            </TableRow>
          );
        })
      )}

      {allTableRows && allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              {T.NO_RECORDS}
            </Typography>
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};



export default memo(TransactionsTableBody);
