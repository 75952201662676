import { Table as TableView, Box, Card, Grid, Paper, TableContainer } from "@mui/material"
import TableHeader from "pages/TransactionScreeen/TableHeader"
// import MasterPagination from "utils/MasterPagination"
import WalletsBody from "./WalletsBody"
import MasterPagination from "utils/MasterPagination"
import { WALLETS_TABLE_HEADER } from "utils/constants/wallets"

interface WalletProps {
    wallets: any,
    page: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number,
    open: boolean,
    anchorEl: any
    handleClick: any,
    handleClose: any,
    onPageChange: any,
    onRowsPerPageChange: any,
    dataWindow: any
}

const Wallets = ({
    wallets,
    page,
    pageSize,
    totalRecords,
    totalPages,
    open,
    anchorEl,
    handleClick,
    handleClose,
    onPageChange,
    onRowsPerPageChange,
    dataWindow
}: WalletProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        // p: "0px 8px",
                        p: "0px 0px",
                        mt: 1.5,
                    }}
                >
                    <Box overflow="hidden">
                        <TableContainer
                            component={Paper}
                            // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                            sx={{ height: "inherit", overflowY: "auto" }}
                        >
                            <TableView stickyHeader>
                                <TableHeader columns={WALLETS_TABLE_HEADER} />
                                <WalletsBody
                                    allTableRows={ wallets.data }
                                    dataWindow={dataWindow}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    totalTableRowsCount={totalRecords}
                                    totalPageCount={totalPages}
                                    opan={open}
                                    anchorEl={anchorEl}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                />
                            </TableView>
                        </TableContainer>
                    </Box>

                    <MasterPagination
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onRowsPerPageChange}
                        totalTableRowsCount={totalRecords}
                        totalPageCount={totalPages}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default Wallets