import authApi from "interceptor/authInterceptor";
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { dispatch } from "store";
import { onBoardingStatusUpdate, onboardedCompanyDetails } from "./auth";
import { updateSelectedCompany } from "./dashboardSearch";

interface initialState {
  loader: boolean;
  connections: [];
  tokenData: [];
  users:[];
  comanyData:[]
}

const initialState: initialState = {
  loader: false,
 connections: [],
 tokenData: [],
 users:[],
 comanyData:[]
};

const getXeroTenantData = createSlice({
  name: "getXeroTenantData",
  initialState,
  reducers: {
    getTenantData(state, action) {
      let { connections, tokenData, comanyData } = action.payload;
      state.connections = connections || initialState.connections;
      state.tokenData = tokenData || initialState.tokenData;
      state.comanyData= comanyData || initialState.comanyData;
    },
    getUserData(state, action){
      let {users} = action.payload;
      state.users = users || initialState.users;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
  },
});

export const { getTenantData, getUserData,isLoading } =
  getXeroTenantData.actions;

export default getXeroTenantData.reducer;

export function processXeroAccessToken(code: string) {
  return async () => {
    const response = await authApi.post(`/v2/api/xero/getAccessToken`, {
      code,
    });
    if(response.data){
      const xeroData = response.data.data.response;
      dispatch(getTenantData({
        connections: get(xeroData,"connections",""),
        tokenData : get(xeroData, "tokenData",""),
        comanyData: get(xeroData,"companyResponses","")
    }))
    }

    if (!response?.data?.data)
      throw new Error("Something went wrong, please try again");

    return response?.data?.data;
  };
}

export function getEmployeeInforXero(payload: any){
  return async()=>{
    try {
      const response = await authApi.post(`/v2/api/xero/getUserInfor`, payload);
      console.log(response.data, "API Response");
      if(response.data){
        const userData = response.data.data;
        dispatch(getUserData({
          users: get(userData, "cashdUser","")
        }))
      }

      return response
      // You can dispatch any action here if needed
      // dispatch({ type: 'COMPANY_INFO_SUCCESS', payload: response.data });
    } catch (error) {
      console.error("API Error:", error);
      throw error;
      // You can dispatch an error action here if needed
      // dispatch({ type: 'COMPANY_INFO_FAILURE', payload: error.message });
    }
  }
}

export function saveXeroDetails(payload: any){
 return async()=>{
  try {
    const response = await authApi.post(`/v2/api/xero/saveDetails`, payload);
    console.log(response.data, "API Response");
    console.log("response", response.data);
    const companyData = response?.data?.data?.company;

    // dispatch event to update company details
    dispatch(onBoardingStatusUpdate({ onBoardedCompanyData: companyData }));

    const onBoardedUserCompanyDetails = {
      company_name: get(companyData, "company_name", ""),
      _id: get(companyData, "_id", ""),
    };

    dispatch(
      onboardedCompanyDetails({
        onBoardedCompanyDetails: onBoardedUserCompanyDetails,
      })
    );

    dispatch(
      updateSelectedCompany({ selectedCompany: onBoardedUserCompanyDetails })
    );
    // You can dispatch any action here if needed
    // dispatch({ type: 'COMPANY_INFO_SUCCESS', payload: response.data });
  } catch (error) {
    console.error("API Error:", error);
    throw error;
    // You can dispatch an error action here if needed
    // dispatch({ type: 'COMPANY_INFO_FAILURE', payload: error.message });
  }
 }
}
