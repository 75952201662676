import axios from "axios";
import { dispatch } from "store";
import { hideLoader, showLoader } from "store/reducers/dashboard";
import toast from "utils/ToastNotistack";
import T from "../utils/constants/T";

// Create an instance of Axios
const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_PRIVATE_BASE_URL,
});

// Axios request interceptor
authApi.interceptors.request.use(
  (config) => {
    // set the loader state to true to turn on the loading animation
    dispatch(showLoader());

    config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
    config.headers["service"] = "Web Admin";

    // Get the access token from local storage or any other storage mechanism
    const accessToken = localStorage.getItem("serviceToken");

    // Add the access token to the Authorization header
    if (accessToken) {
      config.headers.token = `${accessToken}`;
    }

    return config;
  },
  (error) => {
    // hide loader
    dispatch(hideLoader());

    return Promise.reject(error);
  }
);

// Axios response interceptor
authApi.interceptors.response.use(
  (response) => {
    // hide loader
    dispatch(hideLoader());

    return response;
  },
  async (error) => {
    // hide loader
    dispatch(hideLoader());

    if (error?.response?.data?.code === 400)
      toast(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          T.SOMETHING_WENT_WRONG,
        {
          variant: "error",
        }
      );

    // Check if the response status code is 401 (unauthorized) then redirect to login page
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.statusCode == "JWT_EXPIRED"
    ) {
      localStorage.removeItem("serviceToken");
      window.location.replace("/login");
    }

    if (error?.response?.data?.code > 401)
      toast(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          T.SOMETHING_WENT_WRONG,
        {
          variant: "error",
        }
      );

    return error;
  }
);

export default authApi;
