

//Material UI
import Grid from '@mui/material/Grid';

//Local imports
import T from 'utils/constants/T';
import CashDTextField from 'components/common/CashDTextfield';
import { StyledBodyTypography } from './CompanyInfo';

//lodash
import { noop } from 'lodash';
import { isAlphabtetic } from 'utils/constants/validations';

interface EditCompanyInfoProps{
    contactPerson?:string;
    payrollSystem?:string;
    email?:string;
    attendanceSystem?:string;
    phoneNumber?:string;
    city?:string;
    abn?:string;
    onHandleChange?:(e:any)=>void;
}
const EditCompanyInfo = (
    {
        contactPerson= "",
        payrollSystem ="",
        email="",
        attendanceSystem="",
        phoneNumber="",
        city="",
        abn="",
        onHandleChange=noop
    }:EditCompanyInfoProps
) => {
     
    
     
    return (
        <Grid container spacing={4}>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.CONTACT_PERSON}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <CashDTextField
                                    required
                                    fullWidth
                                    placeholder={T.CONTACT_PERSON}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="contactPerson"
                                    value={contactPerson}
                                    error={!isAlphabtetic(contactPerson)}
                                    errorText={!isAlphabtetic(contactPerson) ? "Name must be alphabetic" : ""}
                                    onChange={onHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.PAYROLL_SYSTEM}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                               <StyledBodyTypography>{payrollSystem}</StyledBodyTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.EMAIL}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <CashDTextField
                                    required
                                    fullWidth
                                    placeholder={T.EMAIL}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="email"
                                    value={email}
                                    onChange={onHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.ATTENDANCE_SYSTEM}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                            <StyledBodyTypography>{payrollSystem}</StyledBodyTypography>
                                {/* <CashDTextField
                                    required
                                    fullWidth
                                    placeholder={T.ATTENDANCE_SYSTEM}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="attendanceSystem"
                                    value={ attendanceSystem}
                                    onChange={onHandleChange}
                                /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.PHONE_NUMBER}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <CashDTextField
                                    required
                                    fullWidth
                                    type="number"
                                    placeholder={T.PHONE_NUMBER}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={onHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.CITY}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <CashDTextField
                                    required
                                    fullWidth
                                    placeholder={T.CITY}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="city"
                                    value={city}
                                    error={!isAlphabtetic(city)}
                                    errorText={!isAlphabtetic(city) ? "City must be alphabetic" : ""}
                                    onChange={onHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display:"flex", justifyContent:"space-between"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledBodyTypography sx={{flexWrap:"nowrap"}}>{T.ABN}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <CashDTextField
                                    required
                                    fullWidth
                                    placeholder={T.ABN}
                                    autoComplete="on"
                                    size="small"
                                    variant="outlined"
                                    name="abn"
                                    value={abn}
                                    type="number"
                                    onChange={onHandleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
    );
};

export default EditCompanyInfo;