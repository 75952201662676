const REGEX = {
  IS_EMAIL: /\S+@\S+\.\S{2,}/,
  IS_MOBILE_NO: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  IS_NOT_A_NUMBER: /^([^0-9]*)$/,
  IS_ALPHABETIC: /^[a-zA-Z\s]*$/
};

export const isEmail = (email: any) => REGEX.IS_EMAIL.test(email);
export const isMobileNo = (mobile: any) => REGEX.IS_MOBILE_NO.test(mobile);
export const isNotANumber = (val: any) => REGEX.IS_NOT_A_NUMBER.test(val);
export const isAlphabtetic = (val: any) => REGEX.IS_ALPHABETIC.test(val);


export const cashdCurrentUser = () => ({
  token: localStorage.getItem("serviceToken"),
});
