//React
import { useEffect } from 'react';

//Material-UI
import { CardContent } from '@mui/material';
import { Card, styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//react-redux
import { useSelector, useDispatch } from 'react-redux';

//lodash
import { get } from 'lodash';

//Local Imports
import T from 'utils/constants/T';
import { fetchCompanyDetails } from 'store/reducers/companyTabDetails';
import { convertDateIntoFrontEndFormat } from 'utils/date';


//css
import "./companyDetails.css"


export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#495057",
    fontWeight: "bolder"
}));
export const StyledBodyTypography = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#262626",
}));

export const StyledCardBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px"
}))
const CompanyInfo = () => {
    const dispatch = useDispatch();

    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const selectedCompanyId = get(selectedCompany, "_id", "");

    const { contactPerson, companyEmail, companyPhone, companyMemberSince, city, abn, systemName } = useSelector((state: any) => state.companyTabData);

    const formattedDate = convertDateIntoFrontEndFormat(companyMemberSince);
    useEffect(() => {
        dispatch(fetchCompanyDetails(selectedCompanyId));
    }, [selectedCompanyId])
    return (
        <Grid container spacing={4}>
            <Grid item xs={4} sx={{ height: '100%' }}>
                <Card variant="outlined" className='view-card-height'>
                    <CardContent>
                        {/* <Box sx={{mb:3}}>
                                    <StyledBodyTypography sx={{ display:"flex", justifyContent:"center", fontStyle:"italic"}}>
                                        {companyName}
                                    </StyledBodyTypography> 
                                    <StyledTitleTypography sx={{ display:"flex", justifyContent:"center" }}>
                                        {formattedDate}
                                    </StyledTitleTypography> 
                                </Box>
                                <Divider/> */}
                        <Box>
                            <StyledCardBox >
                                <StyledTitleTypography>{T.MEMBER_SINCE}</StyledTitleTypography>
                                <StyledBodyTypography>{formattedDate}</StyledBodyTypography>
                            </StyledCardBox>
                            <StyledCardBox >
                                <StyledTitleTypography>{T.CONTACT_PERSON}</StyledTitleTypography>
                                <StyledBodyTypography>{contactPerson}</StyledBodyTypography>
                            </StyledCardBox>
                            <StyledCardBox >
                                <StyledTitleTypography>{T.EMAIL}</StyledTitleTypography>
                                <StyledBodyTypography>{companyEmail}</StyledBodyTypography>
                            </StyledCardBox>
                            <StyledCardBox >
                                <StyledTitleTypography>{T.PHONE_NUMBER}</StyledTitleTypography>
                                <StyledBodyTypography>{companyPhone}</StyledBodyTypography>
                            </StyledCardBox>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} sx={{ height: '100%' }}>
                <Card variant="outlined" className='view-card-height'>
                    <CardContent>
                        <StyledBodyTypography sx={{ mb: 2 }}>{T.COMPANY_DETAILS}</StyledBodyTypography>
                        <Divider />
                        <Grid container sx={{ mt: 2 }}>
                            {/* <Grid item xs={6}>
                                       <StyledTitleTypography>{T.NAME}</StyledTitleTypography> 
                                       <StyledBodyTypography>{companyName}</StyledBodyTypography>
                                    </Grid> */}
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.ABN}</StyledTitleTypography>
                                <StyledBodyTypography>{abn}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.PAYROLL_SYSTEM}</StyledTitleTypography>
                                <StyledBodyTypography>{systemName}</StyledBodyTypography>
                            </Grid>

                            <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.ATTENDANCE_SYSTEM}</StyledTitleTypography>
                                <StyledBodyTypography>{systemName}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.CITY}</StyledTitleTypography>
                                <StyledBodyTypography>{city}</StyledBodyTypography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CompanyInfo;