//Lodash
import noop from 'lodash/noop'

//Material- UI
import Dialog from "@mui/material/Dialog";
import { Box, styled } from '@mui/material';
import { Typography } from '@mui/material';

//CSS
import "./common.css";

export const DialogStatementTypography = styled(Typography)(() => ({
  fontSize: "15px",
  fontWeight: 500,
  paddingBottom: "20px"
}));
interface CashDDialogProps {
  open?: boolean,
  handleClose?: any,
  children?: any,
}
const CashDDialog = ({
  open = false,
  handleClose = noop,
  children,
}: CashDDialogProps) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      //   maxWidth="inherit"
      disableEscapeKeyDown
      sx={{ padding: "20px" }}
    >
      <Box className="table-style">
        {children}
      </Box>
    </Dialog>
  );
};

export default CashDDialog;
