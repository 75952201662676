// material-ui
import { useTheme } from '@mui/material/styles';

import logoIconDark from 'assets/images/CashD-logo.svg';
import logoIcon from 'assets/images/CashD-logo.svg';

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <>
      <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="CashD" width="200" />
    </>
  );
};

export default LogoIcon;
