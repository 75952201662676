import { Box, Divider, Button } from "@mui/material";
import CashDDialog, { DialogStatementTypography } from "components/common/CashDDialog";
import T from "utils/constants/T";
import "./viewDeduction.css";
import { noop } from "lodash";


interface RunDeductionProps{
    openRunDeductionDialog?: boolean;
    handleRunDeductionDailog?: any;
    runSelectedDeductions?: any;
}
const RunDeduction = ({
    openRunDeductionDialog= false,
    handleRunDeductionDailog= noop,
    runSelectedDeductions= noop,
    
}:RunDeductionProps) => {
    return (
        <CashDDialog
            open={openRunDeductionDialog}
        >
            <DialogStatementTypography>
                {T.ARE_YOU_SURE_YOU_WANT_TO_RUN_DEDUCTIONS_OF_THIS_PAY_PERIOD}
            </DialogStatementTypography>
            <Divider />
            <Box className="modal-buttons">
                <Button 
                    variant="outlined" 
                    onClick={handleRunDeductionDailog}
                >
                    {T.CANCEL}
                </Button>
                <Button 
                    variant="contained"
                    onClick={runSelectedDeductions}
                >
                    {T.CONFIRM}
                </Button>
            </Box>
        </CashDDialog>
    );
};

export default RunDeduction;