import React, { createContext, useEffect } from "react";

// third-party
import jwtDecode from "jwt-decode";

// project import
import Loader from "components/Loader";
import axios from "utils/axios";
import { KeyedObject } from "types/root";
import { JWTContextType } from "types/auth";
import { dispatch, useSelector } from "store";
import {
  loggedInUserData,
  loginUser,
  logoutUser,
  onBoardingStatusUpdate,
  updateStaffData,
  updateRole,
  clearAuth,
} from "store/reducers/auth";
// import ServerConstants from 'utils/ServerConstants';
import publicApi from "interceptor/publicInterceptor";
import { get } from "lodash";
import {
  clearDashboardSearch,
  updateSelectedCompany,
} from "store/reducers/dashboardSearch";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";
import {
  clearCompany,
  clearCompanyTabDetails,
} from "store/reducers/companyTabDetails";
import { clearAstute } from "store/reducers/astute";
import { clearBankingSettings } from "store/reducers/companyBankingSettings";
import { clearFinancialSettings } from "store/reducers/companyFinancialSettingsTab";
import { clearKybTab } from "store/reducers/companyKybTab";
import { clearDashboard } from "store/reducers/dashboard";
import { resetDeductions } from "store/reducers/deductions";
import { clearDeductionTransactionsFilter } from "store/reducers/deductionTransactionFilter";
import { clearMenu } from "store/reducers/menu";
import { clearMyob } from "store/reducers/myob";
import { clearRegisteredEmployees } from "store/reducers/registeredEmployees";
import { clearSnackbar } from "store/reducers/snackbar";
import { clearStepper } from "store/reducers/stepper";
import { clearTimesheet } from "store/reducers/timesheet";
import { clearTransactions } from "store/reducers/transactionData";
import { clearUnregisteredEmployees } from "store/reducers/unRegisteredEmployees";
import { clearWallet } from "store/reducers/wallets";
import { clearWhiteLabellingSettings } from "store/reducers/whiteLabellingSettings";
const noSelectedCompany = {
  company_name: "",
};
// const { SERVER_URL } = ServerConstants;
// var CryptoJS = require("crypto-js");
// const secretPass = "cashdSecretkey";

// const encryptData = (text: any) => {
//   const data = CryptoJS.AES.encrypt(
//     JSON.stringify(text),
//     secretPass
//   ).toString();
//   return data
// };
// const decryptData = async (text: any) => {
//   const data = CryptoJS.AES.decrypt(
//     JSON.parse(text),
//     secretPass
//   );
//   var originalText = await data.toString(CryptoJS.enc.Utf8);
//   return JSON.parse(originalText)
// };

// let headersOptions= {
//   headers: {
//         'Content-Type': 'application/json',
//         'x-api-key': 'ddYpb7BInj9p4mwh0jKQw9he40dJ2kC7Y01uNina',
//         'Accept': '*/*',
//         'Connection': 'keep-alive',
//         'Accept-Encoding': 'gzip, deflate, br'
//       }
// }
// let headersOptions = {
//   headers: {
//     'x-api-key': 'ddYpb7BInj9p4mwh0jKQw9he40dJ2kC7Y01uNina',
//   }
// }
const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.token = `${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.token;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  let state = useSelector((state) => state.auth);
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
        } else {
          localStorage.clear();
          dispatch(logoutUser({ isInitialized: true }));
        }
      } catch (err) {
        dispatch(logoutUser({ isInitialized: true }));
      }
    };

    init();
  }, []);

  const login = async (email: string) => {
    await publicApi.post(`/v2/api/users/signin`, { email });
    let setObject = { email };
    localStorage.setItem("users", JSON.stringify(setObject));
  };

  const codeVerification = async (otp: string) => {
    const localStorageData: any = localStorage.getItem("users");
    let email = JSON.parse(localStorageData).email;
    const response = await publicApi.post(`/v2/api/users/verifyOTP`, {
      email,
      OTP: +otp,
    });
    const {
      data: { userData, staffData, companyData },
    } = response.data;
    setSession(userData.token);
    // let encryptedUserData = null, encryptedCompanyData = null, encryptedStaffData = null
    let onBoardedUserCompanyDetails = {};
    const role = get(userData, "role_id.name", "");

    dispatch(updateRole(role));

    if (userData) {
      // encryptedUserData = await encryptData(userData)
      // localStorage.setItem('authenticated_user', JSON.stringify(encryptedUserData));
      dispatch(loginUser({ user: userData }));
    }

    if (staffData) {
      // encryptedStaffData = await encryptData(staffData)
      // localStorage.setItem('staffData', JSON.stringify(encryptedStaffData));
      dispatch(updateStaffData({ staffData }));
    }

    if (companyData) {
      // encryptedCompanyData = await encryptData(companyData)
      // localStorage.setItem('companyData', JSON.stringify(encryptedCompanyData));
      dispatch(onBoardingStatusUpdate({ onBoardedCompanyData: companyData }));

      onBoardedUserCompanyDetails = {
        company_name: get(companyData, "company_name", ""),
        _id: get(companyData, "_id", ""),
      };
    }

    dispatch(
      loggedInUserData({
        isCashDSuperAdmin: role === "superadmin" ? true : false,
        isEmployerAdmin: role === "employer" ? true : false,
        onBoardedCompanyDetails: onBoardedUserCompanyDetails,
      })
    );

    if (role === "superadmin") {
      dispatch(updateSelectedCompany({ selectedCompany: noSelectedCompany }));
    } else if (role === "employer") {
      dispatch(
        updateSelectedCompany({ selectedCompany: onBoardedUserCompanyDetails })
      );
    } else {
      localStorage.clear();
      logout();
      toast(T.INVALID_USER, { variant: "error" });
    }
  };

  const register = async (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    // todo: this flow need to be recode as it not verified
    const response = await publicApi.post(`/v2/api/users/signup`, {
      email,
      first_name: firstName,
      last_name: lastName,
      role: "employer",
    });
    let setObject = { email };
    localStorage.setItem("users", JSON.stringify(setObject));
    let user = response.data;
    const localStorageEmployer: any = localStorage.getItem("userInfor");
    const payload: any = JSON.parse(localStorageEmployer);
    if (user.statusCode=== 200) {
      await publicApi.post(`v2/api/users/alertEmployer`, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phnNo: payload.phnNo,
      });
    }
    if (
      localStorage.getItem("users") !== undefined &&
      localStorage.getItem("users") !== null
    ) {
      user = {
        email,
        name: `${firstName} ${lastName}`,
      };
    }
    localStorage.setItem("users", JSON.stringify(user));
  };
  const logout = () => {
    setSession(null);
    localStorage.clear();
    // localStorage.removeItem("users");
    // localStorage.removeItem("authenticated_user");
    // localStorage.removeItem("email");
    // localStorage.removeItem("staffData");
    // localStorage.removeItem("companyData");
    dispatch(clearWhiteLabellingSettings());
    dispatch(clearWallet());
    dispatch(clearUnregisteredEmployees());
    dispatch(clearTransactions());
    dispatch(clearTimesheet());
    dispatch(clearStepper());
    dispatch(clearSnackbar());
    dispatch(clearRegisteredEmployees());
    dispatch(clearMyob());
    dispatch(clearMenu());
    dispatch(clearDeductionTransactionsFilter());
    dispatch(resetDeductions());
    dispatch(clearDashboardSearch());
    dispatch(clearDashboard());
    dispatch(clearCompanyTabDetails());
    dispatch(clearKybTab());
    dispatch(clearFinancialSettings());
    dispatch(clearBankingSettings());
    dispatch(clearAstute());
    dispatch(clearAuth());
    dispatch(clearCompany());
    dispatch(logoutUser({ isInitialized: true }));
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  const resendOtp = async (email: string) => {
    await publicApi.post(`/v2/api/users/resendOTP`, { email });
  };

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        codeVerification,
        resetPassword,
        updateProfile,
        resendOtp,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
