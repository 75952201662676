//RTK
import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

//Local imports
import { dispatch } from "store";
// import ServerConstants from "utils/ServerConstants";

//Lodash
import { get } from "lodash";
import { getCompanyTabData } from "./companyTabDetails";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";
// import { cashdCurrentUser } from "utils/constants/validations";
import authApi from "interceptor/authInterceptor";

// const { SERVER_URL } = ServerConstants;

//types
export type financialSettingsTabProps = {
  monthlyActiveEmployees: string | number;
  totalAmountDrawnOnThisPeriod: string | number;
  loader: boolean;
  error: null;
};
//initial state
const initialState: financialSettingsTabProps = {
  monthlyActiveEmployees: "",
  totalAmountDrawnOnThisPeriod: "",
  loader: false,
  error: null,
};
// ==============================|| SLICE - Financial Settings Tab ||============================== //
const financialSettingsTabData = createSlice({
  name: "financialSettingsTabData",
  initialState,
  reducers: {
    getFinancialSettingsTabData(state, action) {
      let { monthlyActiveEmployees, totalAmountDrawnOnThisPeriod } =
        action.payload;
      state.monthlyActiveEmployees = monthlyActiveEmployees;
      state.totalAmountDrawnOnThisPeriod = totalAmountDrawnOnThisPeriod;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearFinancialSettings: (state) => initialState,
  },
});
export default financialSettingsTabData.reducer;

export const {
  getFinancialSettingsTabData,
  isLoading,
  hasError,
  clearFinancialSettings,
} = financialSettingsTabData.actions;

// ==============================|| SLICE - Get Financial Settings Details ||============================== //

export function fetchFinancialSettingsTabData(selectedCompanyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/financialSettingAdditionals/${selectedCompanyId}`
    );
    if (response.data) {
      const finalData = response.data.data;
      dispatch(
        getFinancialSettingsTabData({
          monthlyActiveEmployees: get(finalData, "totalActiveEmployees", ""),
          totalAmountDrawnOnThisPeriod: get(finalData, "totalDrawnPeriod", ""),
        })
      );
    }
  };
}

// ==============================|| SLICE - Save Financial Settings ||============================== //

export function saveFinancialSettingsTabData(
  payload: any,
  restData: any,
  companyTabData: any,
  navigator: any
) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/financialSettingupdate`,
      payload
    );

    if (response.data) {
      toast(T.FINACIAL_SETTINGS_UPDATED_SUCCESSFULLY, { variant: "success" });
      dispatch(
        getFinancialSettingsTabData({
          monthlyActiveEmployees: restData.monthlyActiveEmployees,
          totalAmountDrawnOnThisPeriod: restData.totalAmountDrawnOnThisPeriod,
        })
      );
      dispatch(
        getCompanyTabData({
          ...companyTabData,
          totalNumberOfEmployees: payload.limit_number_of_employee,
          totalAmountPerCompany: payload.limit_money,
          allowablePercentageDrawdown: payload.limit_allowable_percent_drawdown,
          transactionFee: payload.transaction_fee,
          transactionFeeType:
            payload.transaction_fee_type === T.PERCENT
              ? T.PERCENTAGE
              : payload.transaction_fee_type === T.DOLLARTEXT && T.DOLLAR,
          // floatAlertThresholdAmount: payload.threshold_amount,
          minimumWithdrawl: payload.min_withdrawal,
          // floatAlertRecipients: payload.recipients_float_alert.toString(),
        })
      );
      navigator("/companyDetails/financialSettings/view");
    }
  };
}
