export const PAGINATION: {
  INITIAL_PAGE: number;
  ROWS_PER_PAGE: number;
  ROWS_PER_PAGE_OPTIONS: number[];
  EXPORT_PAGE_SIZE: number;
} = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [5, 10, 25, 50],
  EXPORT_PAGE_SIZE: 100000,
};
