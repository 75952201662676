//react
import { ReactNode, useMemo, useReducer } from 'react';

//react-router
import { useNavigate } from 'react-router';

//react-router-dom
import { useLocation } from "react-router-dom";

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps, Box, Typography } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import useConfig from 'hooks/useConfig';
import IconButton from 'components/@extended/IconButton';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { dispatch, useSelector } from 'store';

// types
import { LAYOUT_CONST } from 'types/config';

//lodash
import { get, orderBy } from 'lodash';

//css
import "./header.css";


//local imports
import CashDAutocomplete from 'components/common/CashDAutocomplete';
import T from 'utils/constants/T';
import { fetchCompanies, updateCompanyList, updateSelectedCompany } from 'store/reducers/dashboardSearch';
import { clearCompany, fetchCompanyDetails } from 'store/reducers/companyTabDetails';
import { clearTimesheet } from 'store/reducers/timesheet';



const NO_COMPANY_SELECTED = {
  company_name: ''
};

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const { isCashDSuperAdmin, isEmployerAdmin, onBoardedCompanyDetails, onBoardedCompanyData } = useSelector(state => state.auth);
  const onBoardedCompanyName = get(onBoardedCompanyDetails, "company_name", "");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let { companiesList, selectedCompany } = useSelector(state => state.dashboardSearch)

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      searchInput: selectedCompany,
    }
  );
  const {
    searchInput,
  } = localState;
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  let debounceTimer: any = null;

  const handleOnChangeValue = async (searchText: string) => {

    clearTimeout(debounceTimer);

    if (searchText?.length >= 3) {
      debounceTimer = setTimeout(() => {
        dispatch(fetchCompanies(searchText));
      }, 500)
    }
  }

  //Autocomplete Change Handler
  const handleAutoCompleteChange = (type: string, newValue: any) => {



    // clear store values
    dispatch(clearTimesheet())

    // if no company is selcted, clear the selected in store
    if (newValue === null) {

      // reset company data reducer
      dispatch(clearCompany())

      // update selected company to null
      dispatch(updateSelectedCompany({ selectedCompany: NO_COMPANY_SELECTED }))

      // set company list to empoty array
      dispatch(updateCompanyList({ companyList: [] }))

      // set local state to empty company data
      setLocalState({ [type]: NO_COMPANY_SELECTED });

      // redirect to dashboard page
      if (pathname.includes("companyDetails")) navigate("/dashboard")

    }
    else if (newValue && pathname.includes("companyDetails")) {

      // set new company details in redux store
      if (newValue?._id) dispatch(fetchCompanyDetails(newValue._id))

      // update selected company value
      dispatch(updateSelectedCompany({ selectedCompany: newValue }))

      // set local state to empty company data
      setLocalState({ [type]: newValue });

      // redirect to company view page
      navigate("/companyDetails/company/view")
    }
    else {

      // set new company details in redux store
      if (newValue?._id) dispatch(fetchCompanyDetails(newValue._id))

      // update selected company value
      dispatch(updateSelectedCompany({ selectedCompany: newValue }))

      // set local state to empty company data
      setLocalState({ [type]: newValue });
    }


  }

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      {!isHorizontal ? (
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      ) : null}
      {
        <>

          {/* if cashd super admin then show the serach box with no company selected  */}
          {/* if company admin then only show company name */}

          <Box className="searchBox">
            {
              isCashDSuperAdmin ?
                <CashDAutocomplete
                  placeholder={T.ALL_COMPANIES}
                  fullWidth
                  fontWeight='700'
                  handleOnChangeValue={(e: any) => {
                    handleOnChangeValue(e.target.value)
                  }}
                  listDetails={orderBy(companiesList, ["company_name"], ["asc"])}
                  value={searchInput}
                  getByLabelText={(option: any) => get(option, 'company_name', "")}
                  onHandleChange={(event: any, newValue: any) => {
                    // setLocalState({ listOfCompanies: [] })
                    handleAutoCompleteChange("searchInput", newValue)
                  }
                  }
                />
                :
                !isCashDSuperAdmin && isEmployerAdmin && onBoardedCompanyData &&
                <Typography className='company-name-text'>
                  {onBoardedCompanyName}
                </Typography>
            }
          </Box>
        </>

      }
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      width: isHorizontal ? '100%' : open ? 'calc(100% - 260px)' : { xs: '100%', lg: 'calc(100% - 60px)' }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
