import TextField from "@mui/material/TextField";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import WithInputLabel from "./WithInputLabel";
import { noop } from "lodash";
import './common.css';
interface CashDTextFieldProps {
  label?: any;
  fullWidth?: any;
  placeholder?: string;
  size?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  endIcon?: React.ReactNode;
  variant?: "standard" | "outlined" | "filled";
  error?: any;
  value?: any;
  autoComplete?: string;
  readOnly?: boolean;
  InputProps?: any;
  SelectProps?: any;
  children?: any;
  deprecatedLabel?: boolean;
  multiline?: boolean;
  rows?: any;
  onChange?: any;
  onKeyDown?: any;
  errorText?: string;
  sx?: any;
  
}

const CashDTextField = ({
  label,
  fullWidth,
  placeholder,
  size,
  name,
  type,
  disabled,
  required,
  endIcon,
  value,
  variant = "outlined",
  error = "",
  autoComplete = "off",
  readOnly = false,
  InputProps = {},
  SelectProps = {},
  children,
  deprecatedLabel = true,
  multiline = false,
  onChange = noop,
  onKeyDown = noop,
  rows = 6,
  errorText="",
  sx,
  ...rest
}:CashDTextFieldProps) => {
  
  return (
    <WithInputLabel
      label={deprecatedLabel && label}
      required={required}
      endIcon={endIcon}
      error={error}
    >
      <TextField
        disabled={disabled}
        fullWidth={fullWidth}
        placeholder={placeholder}
        size={size}
        value={value}
        name={name}
        sx={sx}
        type={type}
        helperText={errorText}
        onChange={onChange}
        onKeyDown={onKeyDown}
        InputProps={{ ...InputProps, readOnly }}
        variant={variant}
        multiline={multiline}
        autoComplete={autoComplete}
        required={required}
        rows={multiline ? rows : undefined}
        label={deprecatedLabel ? undefined : label}
        {...rest}
        error={Boolean(error)}
        // helperText={helperText}
        SelectProps={{
          ...SelectProps,
          IconComponent: (props) => <KeyboardArrowDownRounded {...props} />,
          MenuProps: {
            elevation: 0,
            PaperProps: {
              sx: {
                boxShadow:
                  "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                maxHeight: 200,
              },
              variant: "outlined",
            },
          },
        }}
      />
       
    </WithInputLabel>
  );
};

export default CashDTextField;