//RTK
import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

//Local imports
import { dispatch } from "store";
// import ServerConstants from "utils/ServerConstants";

//Lodash
import { get } from "lodash";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";
// import { cashdCurrentUser } from "utils/constants/validations";
import authApi from "interceptor/authInterceptor";
import uploadApi from "interceptor/uploadInterceptor";

// const { SERVER_URL } = ServerConstants;

//types
export type kybTabProps = {
  documentUrls: any[];
  status: string;
  comments: string;
  staffName: string;
  employeeId: string;
  verificationCompletedDate: any;
  reVerificationDueDate: any;
  loader: boolean;
  error: null;
};
//initial state
const initialState: kybTabProps = {
  documentUrls: [],
  status: "",
  comments: "",
  staffName: "",
  employeeId: "",
  verificationCompletedDate: null,
  reVerificationDueDate: null,
  loader: false,
  error: null,
};
// ==============================|| SLICE - KYB Details Tab ||============================== //
const kybTabData = createSlice({
  name: "kybTabData",
  initialState,
  reducers: {
    getKybTabData(state, action) {
      let {
        status,
        comments,
        staffName,
        employeeId,
        verificationCompletedDate,
        reVerificationDueDate,
        documentUrls,
      } = action.payload;
      state.documentUrls = documentUrls || initialState.documentUrls;
      state.status = status || initialState.status;
      state.comments = comments || initialState.comments;
      state.staffName = staffName || initialState.staffName;
      state.employeeId = employeeId || initialState.employeeId;
      state.verificationCompletedDate =
        verificationCompletedDate || initialState.verificationCompletedDate;
      state.reVerificationDueDate =
        reVerificationDueDate || initialState.reVerificationDueDate;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearKybTab: (state) => initialState,
  },
});
export default kybTabData.reducer;

export const { getKybTabData, isLoading, hasError, clearKybTab } =
  kybTabData.actions;

// ==============================|| SLICE - Get KYB Details ||============================== //

export function fetchKybTabData(selectedCompanyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/KYB/kybCompany?company_id=${selectedCompanyId}`
    );
    if (response.data) {
      const finalData = response.data.data;
      dispatch(
        getKybTabData({
          documentUrls: get(finalData, "documentUrl", []),
          status: get(finalData, "status", ""),
          comments: get(finalData, "comments", ""),
          staffName: get(finalData, "staffName", ""),
          employeeId: get(finalData, "employeeId", ""),
          verificationCompletedDate: get(
            finalData,
            "verificationCompletedDate",
            ""
          ),
          reVerificationDueDate: get(finalData, "reverifiedDueDate", ""),
        })
      );
    }
  };
}

// ==============================|| SLICE - Save KYB Details ||============================== //

export function saveKybTabData(payload: any, navigator: any) {
  return async () => {
    const response = await authApi.post(`/v2/api/KYB/kybCompany`, payload);
    if (response.data) {
      toast(T.KYB_DETAILS_SAVED_STATEMENT, { variant: "success" });
      navigator("/companyDetails/kyb/view");
    }
  };
}

// ==============================|| SLICE - Upload KYB Files ||============================== //

export function uploadKyb(payload: any) {
  return async () => {
    const response = await uploadApi.post(
      `/v2/api/KYB/uploadFilesToS3`,
      payload.formdata
    );
    if (response.data) {
      const finalData = response.data.data.result;
      dispatch(
        getKybTabData({
          documentUrls: payload.documentUrl.concat(finalData),
          status: payload.status,
          comments: payload.comments,
          staffName: payload.cashDStaffName,
          employeeId: payload.employeeId,
          verificationCompletedDate: payload.verificationCompletedDate,
          reVerificationDueDate: payload.reVerificationDueDate,
        })
      );
    }
  };
}
