//react
import { useEffect, useReducer, useRef } from "react";
//Material- UI
import { Button, Grid, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

//React-router-dom
import { useNavigate } from "react-router-dom";

//react-redux
import { useDispatch, useSelector } from "react-redux";

//Local imports
import MainCard from "components/MainCard";
import "../../companyDetails.css";
import T from "utils/constants/T";

//lodash
import { get } from "lodash";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../CompanyTab/CompanyInfo";
import CashDAutocomplete from "components/common/CashDAutocomplete";

//Color picker
import { MuiColorInput } from "mui-color-input";

//css import
import "./whiteLabelling.css";
import {
  UpdateWhiteLabelling,
  deleteLogo,
  getWhiteLabellingSettings,
} from "store/reducers/whiteLabellingSettings";
import toast from "utils/ToastNotistack";
import CashDDialog from "components/common/CashDDialog";
import { fetchCompanyDetails } from "store/reducers/companyTabDetails";

const EditWhiteLabellingDetails = () => {
  const dispatch = useDispatch();
  const fileInputRef: any = useRef(null);
  if (fileInputRef.current) {
    fileInputRef.current.value = ""; // Set the value to an empty string
  }
  const settingsList: any[] = [T.DEFAULT, T.CUSTOM];
  const { companyName, logoUrl, primaryColor, secondaryColor, custom } =
  useSelector((state: any) => state.companyTabData);
  const existedSetting = custom ? T.CUSTOM : T.DEFAULT;
  const existedPrimaryColor = primaryColor;
  const exiestedSecondaryColor= secondaryColor;
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      setting: existedSetting,
      customSettings: false,
      primaryColor: "",
      secondaryColor: "",
      logo: "",
      openPrimaryColorPicker: false,
      openSecondaryColorPicker: false,
      primaryColorCode: "#2E5090",
      secondaryColorCode: "#46A1FB99",
      opneDeleteLogoConfirmationModal: false,
    }
  );

  const {
    setting,
    customSettings,
    logo,
    primaryColorCode,
    secondaryColorCode,
    opneDeleteLogoConfirmationModal,
  } = localState;
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const navigate = useNavigate();
  const navigator = (path: string) => {
    navigate(path, {
      state: { primaryColorCode, secondaryColorCode },
    });
  };
  useEffect(() => {
    if(existedSetting){
      setLocalState({customSettings : existedSetting === T.CUSTOM ? true : false});
      if(existedSetting === T.CUSTOM){
        setLocalState({primaryColorCode : existedPrimaryColor, secondaryColorCode: exiestedSecondaryColor})
      }
    }
    dispatch(fetchCompanyDetails(selectedCompanyId));
  }, [selectedCompanyId]);
  const handleSave = async () => {
    try {
      const payload = new FormData();
      if (setting === T.CUSTOM) {
        if (logo) {
          payload.append("logo", logo);
        }
        payload.append("custom", customSettings);
        payload.append("primaryColor", primaryColorCode);
        payload.append("secondaryColor", secondaryColorCode);
        dispatch(
          getWhiteLabellingSettings({
            primaryColor: primaryColorCode,
            secondaryColor: secondaryColorCode,
            logo: logo,
            custom: customSettings
          })
        );
      } else {
        if (logo) {
          payload.append("logo", "null");
        }
        payload.append("custom", customSettings);
        payload.append("primaryColor", primaryColorCode);
        payload.append("secondaryColor", secondaryColorCode);
        dispatch(
          getWhiteLabellingSettings({
            custom:customSettings,
            primaryColor: primaryColorCode,
            secondaryColor: secondaryColorCode,
            logo: logo,
          })
        );
      }

      await dispatch(
        UpdateWhiteLabelling(selectedCompanyId, payload, navigator)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const logoUrlArr = logoUrl.split("/");
  const logoURLName = logoUrlArr[logoUrlArr.length - 1];

  const handleSettingChange = (name: string, value: string) => {
    setLocalState({ [name]: value });
    if (value === T.CUSTOM) {
      setLocalState({ customSettings: true });
    }
    else if(value === T.DEFAULT){
      setLocalState({customSettings: false })
    }
  };

  const handleSelectPrimaryColor = (value: any) => {
     setLocalState({ primaryColorCode: value });
  };
  const handleSelectSecondaryColor = (value: any) => {
    setLocalState({ secondaryColorCode: value });
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const imageDataUrl = event.target.result;
        const img: any = new Image();
        img.src = imageDataUrl;
        img.onload = () => {
          const logoWidth = img.naturalWidth;
          const logoHeight = img.naturalHeight;
          if (logoWidth > 250 || logoHeight > 90 || file.size > 50120  ) {
            toast("size exceeds 50kb / Resolution is higher than 250 X 90 px", {
              variant: "error",
            });
          } else {
            setLocalState({ logo: file });
            toast("Logo uploaded", { variant: "success" });
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteLogo = () => {
    handleDeletLogoConfirmationModal();
  };

  const handleDeletLogoConfirmationModal = () => {
    setLocalState({
      opneDeleteLogoConfirmationModal: !opneDeleteLogoConfirmationModal,
    });
  };

  const handleDeleteLogoConfirmation = async () => {
    try {
      await dispatch(deleteLogo(selectedCompanyId));
      setLocalState({
        logo: null,
        logoURLName: null,
        opneDeleteLogoConfirmationModal: !opneDeleteLogoConfirmationModal,
      });
    } catch (error) {
      // Handle any errors that occur during the deleteLogo action
      console.error("Error deleting logo:", error);
    }
  };

  useEffect(() => {
    if (setting === T.DEFAULT) {
      setLocalState({
        primaryColorCode: "#2E5090",
        secondaryColorCode: "#46A1FB99",
        logo: "",
      });
    }
  }, [setting]);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    if (!selectedCompanyId) {
      navigate("/dashboard");
    }
  }, [selectedCompanyId]);

  return (
    <>
      <MainCard
        title={companyName}
        secondary={
          <>
            <Button
              variant="contained"
              className="main-card-button"
              onClick={handleSave}
              aria-disabled={!T.CUSTOM}
            >
              {T.SAVE}
            </Button>
          </>
        }
      >
        <Grid container>
          <Grid item xs={12} className="div-center" mt={2}>
            <Grid item xs={2}>
              <StyledBodyTypography>{T.CHOOSE_SETTING}</StyledBodyTypography>
            </Grid>
            <Grid item xs={2}>
              <CashDAutocomplete
                placeholder={ T.DEFAULT}
                fullWidth
                listDetails={settingsList}
                value={setting}
                getByLabelText={(option: any) => option}
                onHandleChange={(event: any, newValue: any) =>
                  handleSettingChange("setting", newValue)
                }
              />
            </Grid>
            <Grid item xs={8} />
          </Grid>
          {setting === T.CUSTOM ? (
            <>
              <Grid item xs={12} className="div-center" mt={4}>
                <Grid item xs={2}>
                  <StyledBodyTypography>
                    {T.CHOOSE_COLOURS}
                  </StyledBodyTypography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box mr={2}>
                    <Typography> {T.PRIMARY_COLOUR}</Typography>
                  </Box>
                  <Box>
                    <MuiColorInput
                      sx={{
                        width: "160px",
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: "8px",
                        },
                      }}
                      disabled={setting !== T.CUSTOM}
                      value={primaryColorCode}
                      format="hex"
                      onChange={(val) => handleSelectPrimaryColor(val)}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box mr={2}>
                    <Typography> {T.SECONDARY_COLOUR}</Typography>
                  </Box>
                  <Box>
                    <MuiColorInput
                      sx={{
                        width: "160px",
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: "8px",
                        },
                      }}
                      disabled={setting !== T.CUSTOM}
                      value={secondaryColorCode}
                      format="hex"
                      onChange={(val) => handleSelectSecondaryColor(val)}
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={4}></Grid> */}
              </Grid>
              <Grid item xs={12} className="div-center" mt={4}>
                <Grid item xs={2}>
                  <StyledBodyTypography>{T.UPLOAD_LOGO}</StyledBodyTypography>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                  >
                    {T.CLICK_TO_UPLOAD}
                    <VisuallyHiddenInput
                      disabled={setting !== T.CUSTOM}
                      type="file"
                      accept=".png"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                    />
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <StyledTitleTypography>
                    {T.FILE_SIZE_SHOULD_NOT_EXCEED}
                  </StyledTitleTypography>
                </Grid>
              </Grid>
              <Grid item xs={12} className="div-center">
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  {logo ? (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <StyledBodyTypography>
                          {logo.name}
                          <IconButton
                            aria-label="delete"
                            sx={{ marginLeft: "8px" }}
                            onClick={handleDeleteLogo}
                            disabled={setting !== T.CUSTOM}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledBodyTypography>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Link href={logoUrl}>
                        <Typography>{logoURLName}</Typography>
                      </Link>
                      {logoURLName && (
                        <IconButton
                          aria-label="delete"
                          sx={{ marginLeft: "8px" }}
                          onClick={handleDeleteLogo}
                          disabled={setting !== T.CUSTOM}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  )}
                  <CashDDialog
                    open={opneDeleteLogoConfirmationModal}
                    handleClose={handleDeletLogoConfirmationModal}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 , width: 450}}>
                      {`${T.DELETE_CONFIRMATION}`}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 , width: 450, textAlign:"center"}}>
                      {`${T.ARE_YOU_SURE_YOU_WANT_TO_DELETE}`}
                    </Typography>
                    <Box className="confirmation_modal">
                      <Button
                        variant="outlined"
                        onClick={handleDeletLogoConfirmationModal}
                      >
                        {T.NO}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDeleteLogoConfirmation}
                      >
                        {T.YES}
                      </Button>
                    </Box>
                  </CashDDialog>
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </MainCard>
    </>
  );
};

export default EditWhiteLabellingDetails;
