//Material UI
import { Button, Box, Typography } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';

//React-Router
import { useNavigate } from 'react-router';

//Local Imports
import MainCard from 'components/MainCard';
import T from 'utils/constants/T';
import CashDCard from 'components/common/CashDCard';

//CSS
import './payrollSettings.css';
import '../../companyDetails.css';
import { StyledTitleTypography } from '../CompanyTab/CompanyInfo';

interface ViewPayrollSettingsTimeSheetProps {
    includeSubmitted: boolean,
    companyName: string,
    systemName: string,
}
const ViewPayrollSettingsTimeSheet = ({
    includeSubmitted = false,
    companyName = "",
    systemName = ""
}: ViewPayrollSettingsTimeSheetProps) => {
    const navigate = useNavigate();


    const handleEdit = () => {
        navigate('/companyDetails/payrollSettings/edit');
    }
    return (
        <MainCard
            title={systemName}
            secondary={
                <>
                    {/* <Button
                        variant="outlined"
                        className='main-card-button'
                        startIcon={<CachedIcon />}
                    >
                        {T.SYNC_PAYROLL}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                    <Button
                        variant="contained"
                        className='main-card-button'
                        onClick={handleEdit}
                    >
                        {T.EDIT}
                    </Button>
                </>
            }>
            <CashDCard borderRadius='20px'>
                <Box >
                    <Typography className='time-sheet-text'>
                        {T.TIME_SHEETS_STATUS}
                    </Typography>
                    {
                        !includeSubmitted
                            ?
                            <StyledTitleTypography align='center' mt={1}>
                                {T.INCLUDE_SUBMITTED}
                            </StyledTitleTypography>
                            :
                            <StyledTitleTypography align='center' mt={1}>
                                {T.DO_NOT_INCLUDE_SUBMITTED}
                            </StyledTitleTypography>

                    }

                </Box>
            </CashDCard>
        </MainCard>
    );
};

export default ViewPayrollSettingsTimeSheet;