//RTK
import { createSlice } from "@reduxjs/toolkit";

//Local imports
import { dispatch } from "store";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";

import authApi from "interceptor/authInterceptor";

//types
export type payrollSettingsTabProps = {
  deductionFileCategories: any[];
  loader: boolean;
  error: null;
};
//initial state
const initialState: payrollSettingsTabProps = {
  deductionFileCategories: [],
  loader: false,
  error: null,
};
// ==============================|| SLICE - Payroll Settings Tab ||============================== //
const payrollSettingsTabData = createSlice({
  name: "payrollSettingsTabData",
  initialState,
  reducers: {
    getPayrollSettingsTabData(state, action) {
      let { deductionFileCategories } = action.payload;
      state.deductionFileCategories =
        deductionFileCategories || initialState.deductionFileCategories;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearPayrollSettings: (state) => initialState,
  },
});
export default payrollSettingsTabData.reducer;

export const {
  getPayrollSettingsTabData,
  isLoading,
  hasError,
  clearPayrollSettings,
} = payrollSettingsTabData.actions;

// ==============================|| SLICE - Get Payroll Settings Details ||============================== //

export function fetchPayrollSettingsTabData(
  selectedCompanyId: string,
  systemPayrollCode: string
) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${selectedCompanyId}/payroll?code=${systemPayrollCode}`
    );
    if (response.data) {
      const finalData = response.data.data;
      if (systemPayrollCode === T.KEYPAY.toUpperCase()) {
        dispatch(
          getPayrollSettingsTabData({
            deductionFileCategories: finalData,
          })
        );
      }
    }
  };
}

// ==============================|| SLICE - Save Payroll Settings ||============================== //

export function savePayrollSettingsTabData(
  payload: any,
  selectedCompanyId: string,
  navigator: any
) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/${selectedCompanyId}/payroll`,
      payload
    );
    if (response.data) {
      toast(T.PAYROLL_SETTINGS_UPDATED_SUCCESSFULLY, { variant: "success" });
      navigator("/companyDetails/payrollSettings/view");
    }
  };
}
