const Deduction = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2256_1556)">
        <path
          d="M13.3333 0H6.66667C5.562 0.00132321 4.50296 0.440735 3.72185 1.22185C2.94073 2.00297 2.50132 3.062 2.5 4.16667V19.1667C2.50012 19.3182 2.54157 19.4669 2.6199 19.5967C2.69823 19.7264 2.81047 19.8324 2.94453 19.9031C3.07859 19.9738 3.22941 20.0066 3.38073 19.998C3.53206 19.9894 3.67817 19.9397 3.80333 19.8542L5.55833 18.655L7.31333 19.8542C7.45199 19.9491 7.61612 19.9999 7.78417 19.9999C7.95222 19.9999 8.11634 19.9491 8.255 19.8542L10.005 18.655L11.755 19.8542C11.8937 19.9493 12.058 20.0002 12.2262 20.0002C12.3945 20.0002 12.5588 19.9493 12.6975 19.8542L14.4475 18.6558L16.1975 19.8533C16.3226 19.9386 16.4685 19.9881 16.6197 19.9966C16.7708 20.0052 16.9214 19.9724 17.0553 19.9018C17.1892 19.8312 17.3013 19.7255 17.3797 19.596C17.458 19.4664 17.4996 19.318 17.5 19.1667V4.16667C17.4987 3.062 17.0593 2.00297 16.2782 1.22185C15.497 0.440735 14.438 0.00132321 13.3333 0ZM15.8333 17.5833L14.9167 16.9567C14.7778 16.8614 14.6134 16.8103 14.445 16.8103C14.2766 16.8103 14.1122 16.8614 13.9733 16.9567L12.2233 18.1558L10.4733 16.9567C10.3346 16.8615 10.1703 16.8106 10.0021 16.8106C9.83386 16.8106 9.66958 16.8615 9.53083 16.9567L7.78083 18.1558L6.03083 16.9567C5.89226 16.8619 5.7283 16.8112 5.56042 16.8112C5.39254 16.8112 5.22857 16.8619 5.09 16.9567L4.16667 17.5833V4.16667C4.16667 3.50363 4.43006 2.86774 4.8989 2.3989C5.36774 1.93006 6.00363 1.66667 6.66667 1.66667H13.3333C13.9964 1.66667 14.6323 1.93006 15.1011 2.3989C15.5699 2.86774 15.8333 3.50363 15.8333 4.16667V17.5833Z"
          fill="currentColor"
        />
        <path
          d="M13.334 6.66699H6.66732C6.20708 6.66699 5.83398 7.04009 5.83398 7.50033C5.83398 7.96056 6.20708 8.33366 6.66732 8.33366H13.334C13.7942 8.33366 14.1673 7.96056 14.1673 7.50033C14.1673 7.04009 13.7942 6.66699 13.334 6.66699Z"
          fill="currentColor"
        />
        <path
          d="M11.6673 10H6.66732C6.20708 10 5.83398 10.3731 5.83398 10.8333C5.83398 11.2936 6.20708 11.6667 6.66732 11.6667H11.6673C12.1276 11.6667 12.5007 11.2936 12.5007 10.8333C12.5007 10.3731 12.1276 10 11.6673 10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2256_1556">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Deduction;
