// import { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ListItemIcon } from '@mui/material';
// import { Box, FormControl, InputAdornment, OutlinedInput, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

// assets
// import { SearchOutlined } from '@ant-design/icons';
// import { dispatch, useSelector } from 'store';
import { useSelector } from 'store';
// import { fetchCompanies, updateCompanies } from 'store/reducers/dashboardSearch';
import { NavItemType } from 'types/menu';
// import { isEqual } from 'lodash';
// import { useNavigate } from 'react-router';
// ==============================|| NAVIGATION - LIST ITEM ||============================== //
interface Props {
  item: NavItemType;
}

const NavInput = ({ item }: Props) => {
  // const navigate =useNavigate();
  // let [selected, setSelected] = useState({})
  // let [value, setValue] = useState("")
  // let { companiesList } = useSelector(state => state.dashboardSearch)
  const theme = useTheme();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  // useEffect(() => {
  //   if (!isEqual(selected,{})) {
      
  //     dispatch(updateCompanies({ selectedCompany: selected }))
  //   }
  // }, [selected])
  // const onChangeHandler = (e: any) => {
  //   setValue(e.target.value)
  //   if (e.target.value.length > 3) {
  //     dispatch(fetchCompanies(value));
  //     navigate('/dashboard');
  //   }
  //   if (e.target.value.length === 0) {
  //     navigate('/dashboard');
  //     dispatch(updateCompanies({ companiesList: [] }))
  //   }
  // }

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  return (
    <>
    {itemIcon && !drawerOpen && (
      <Box sx={{ ml: { xs: 2, md: 1.5 } }}>
      <ListItemIcon
            sx={{
              minWidth: 28,
              // color: isSelected ? iconSelectedColor : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                }
              })
            }}
          >
            {itemIcon}
          </ListItemIcon>
          </Box>
        )}
      {/* <Box sx={{ width: '90%', ml: { xs: 2, md: 2 }, mb: { xs: 2, md: 1 } }}>
        {drawerOpen && <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
          <OutlinedInput
            value={value}
            size="small"
            id="header-search"
            startAdornment={
              <InputAdornment position="start" sx={{ mr: -0.5 }}>
                <SearchOutlined />
              </InputAdornment>
            }
            aria-describedby="header-search-text"
            inputProps={{
              'aria-label': 'weight'
            }}
            onChange={onChangeHandler}
            placeholder="Search Company"
          />
        </FormControl>}
        <List sx={{ p: 0.5 }}>
          {companiesList.map((obj: any, index) => (
            <ListItem button key={obj._id} sx={{ p: 1 }} onClick={e => setSelected(obj)}>
              <ListItemText primary={obj.company_name} />
            </ListItem>
          ))}
        </List>
      </Box> */}
    </>
  );
};

export default NavInput;
