import { Divider, Grid, Button } from "@mui/material";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
import { useNavigate, useParams } from "react-router";
import MainCard from "components/MainCard";
const ViewEmployeeSettings = () => {
  const navigate = useNavigate();
  const { employeeID } = useParams();
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);
  const handleEdit = () => {
    navigate(`/employees/employeeDetails/${employeeID}/settings/edit`);
  };
  return (
    <MainCard
      title="          "
      secondary={
        <Button
          variant="contained"
          onClick={handleEdit}
          className="main-card-button"
        >
          {T.EDIT}
        </Button>
      }
    >
      <Grid item xs={12}>
        <CashDCard borderRadius="20px">
          <Grid container rowSpacing={2}>
            <Grid item xs={6}>
              <StyledBodyTypography>{T.EMPLOYEE_NAME}</StyledBodyTypography>
              <StyledTitleTypography>
                {getUserDataByUSerID.employeeName}
              </StyledTitleTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledBodyTypography>{T.EMPLOYEE_EMAIL}</StyledBodyTypography>
              <StyledTitleTypography>
                {getUserDataByUSerID.email}
              </StyledTitleTypography>
            </Grid>
            <Grid className="divider" item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <StyledBodyTypography>
                {T.MAXIMUM_WITHDRAWAL}
              </StyledBodyTypography>
              {getUserDataByUSerID.maxType ? (
                <StyledTitleTypography>
                  {`${getUserDataByUSerID.maxWithdrawalPercent}${T.PERCENTAGE}`}
                </StyledTitleTypography>
              ) : (
                <StyledTitleTypography>
                  {`${T.DOLLAR}${getUserDataByUSerID.maxWithdrawalMoney}`}
                </StyledTitleTypography>
              )}
            </Grid>
            <Grid item xs={6}>
              <StyledBodyTypography>
                {T.MINIMUM_WITHDRAWAL}
              </StyledBodyTypography>
              <StyledTitleTypography>
                {T.DOLLAR}
                {getUserDataByUSerID.minWithdrawal}
              </StyledTitleTypography>
            </Grid>
          </Grid>
        </CashDCard>
      </Grid>
    </MainCard>
  );
};

export default ViewEmployeeSettings;
