// material-ui
import { Button, Stack, Grid, Typography, Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

//Lodash
import { noop } from 'lodash';

// third-party
import { useDropzone } from 'react-dropzone';

// project import
import RejectionFiles from './RejectionFiles';
import PlaceholderContent from './PlaceholderContent';
import FilesPreview from './FilesPreview';
import { getFileNameFromUrl } from 'pages/CompanyDetailsScreen/CompanyDetails/KYBTab/ShowKyb';
import CashDTextField from 'components/common/CashDTextfield';
import CashDCard from 'components/common/CashDCard';
import CashdDatePicker from 'components/common/CashdDatePicker';
import T from 'utils/constants/T';

// types
import { CustomFile, DropzopType, UploadMultiFileProps } from 'types/dropzone';


const DropzoneWrapper = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.secondary.main}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}));

// ==============================|| UPLOAD - MULTIPLE FILE ||============================== //

const MultiFileUpload = ({
  error, 
  files, 
  type, 
  setFieldValue, 
  sx, 
  documentUrl=[],
  status="",
  comments="",
  cashDStaffName="",
  employeeId="",
  verificationCompletedDate="",
  reVerificationDueDate="",
  onUpload=noop, 
  handleRemoveUrl= noop,
  onHandleChange= noop,
  onHandleDateChange= noop,
  handleRadioChange= noop,
  ...other }: UploadMultiFileProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles: CustomFile[]) => {
      if (files) {
        setFieldValue('files', [
          ...files,
          ...acceptedFiles.map((file: CustomFile) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        ]);
      } else {
        setFieldValue(
          'files',
          acceptedFiles.map((file: CustomFile) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      }
    }
  });

  const onRemoveAll = () => {
    setFieldValue('files', null);
  };

  const onRemove = (file: File | string) => {
    const filteredItems = files && files.filter((_file) => _file !== file);
    setFieldValue('files', filteredItems);
  };
  
  return (
    <>
      <Grid
      container
      spacing={5}
        sx={{
          width: '100%',
          ...(type === DropzopType.standard && { width: 'auto', display: 'flex' }),
          ...sx
        }}
      >
        <Grid item xs={12}>
          <DropzoneWrapper
              {...getRootProps()}
              sx={{
                ...(type === DropzopType.standard && {
                  p: 0,
                  m: 1,
                  width: 64,
                  height: 64
                }),
                ...(isDragActive && { opacity: 0.72 }),
                ...((isDragReject || error) && {
                  color: 'error.main',
                  borderColor: 'error.light',
                  bgcolor: 'error.lighter'
                })
              }}
            >
              <input {...getInputProps()} />
              <PlaceholderContent type={type} />
            </DropzoneWrapper>
            {type === DropzopType.standard && files && files.length > 1 && (
              <Button variant="contained" color="error" size="extraSmall" onClick={onRemoveAll}>
                Remove all
              </Button>
            )}
        </Grid>
        {/* <Stack {...(type === DropzopType.standard && { alignItems: 'center' })}> */}
          
        {/* </Stack> */}
        <Grid item xs={12}>
           {
              ((files && files.length > 0) || fileRejections.length > 0) &&
              <CashDCard borderRadius="20px">
                  <Typography fontSize="15px" align='center' fontWeight={700}>{T.DOCUMENTS}</Typography>
                  {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
                  {files && files.length > 0 && <FilesPreview files={files}  onRemove={onRemove} type={type} />}
                  {type !== DropzopType.standard && files && files.length > 0 && (
                    <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mt: 1.5 }}>
                      <Button color="inherit" size="small" onClick={onRemoveAll}>
                        Remove all
                      </Button>
                      <Button size="small" variant="contained" onClick={()=>onUpload(files)}>
                        Upload files
                      </Button>
                    </Stack>
                  )}
              </CashDCard>
            }
          

        </Grid>

        <Grid item xs={6}>
                    <CashDCard borderRadius='20px' minHeight='250px'>
                        <Typography align='center' className='table-heading'>
                            {T.SAVED_DOCUMENTS}
                        </Typography>
                        {documentUrl.length!==0 ? documentUrl.map((url: string, index: number)=>{
                          return (
                            <Card className= "urlCard" elevation={0} key={index}>
                                <Typography>
                                    {getFileNameFromUrl(url)}
                                </Typography>
                                <IconButton onClick={()=>handleRemoveUrl(index)}  aria-label="delete" size="medium">
                                    <DeleteIcon  fontSize="inherit" />
                                </IconButton>
                            </Card>
                          )
                        })
                        :
                        
                        <Typography className='no-files-typo'>
                          {T.NO_FILES_UPLOADED}    
                        </Typography>
                        
                        } 
                    </CashDCard>
                </Grid>
        <Grid item xs={6}>
          <CashDCard borderRadius="20px" minHeight='250px'>
              <Typography fontSize="15px" align='center' fontWeight={700}>{T.SET_STATUS}</Typography>
              <Box sx={{p:"20px 60px"}}>
                <FormControl>
                  <RadioGroup
                    // aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Unverified"
                    value={status}
                    name="status"
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel value={T.UNVERIFIED} control={<Radio />} label={T.UNVERIFIED} />
                    <FormControlLabel value={T.COMPLETE} control={<Radio />} label={T.COMPLETE} />
                    <FormControlLabel value={T.RE_SUBMISSION_REQUIRED} control={<Radio />} label={T.RE_SUBMISSION_REQUIRED} />
                  </RadioGroup>
              </FormControl>
              </Box>
          </CashDCard>  
        </Grid>
        <Grid item xs={12}>
          <CashDTextField
            fullWidth
            label={T.COMMENTS}
            multiline
            rows={4}
            name='comments'
            value={comments}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CashDCard>
                <Typography fontSize="15px" fontWeight={700} mb={2}>{T.COMPLETED_BY}</Typography>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <CashDTextField
                        label={T.CASHD_STAFF_NAME}
                        fullWidth
                        placeholder={T.CASHD_STAFF_NAME}
                        autoComplete="on"
                        size="small"
                        variant="outlined"
                        name="cashDStaffName"
                        value={cashDStaffName}
                        onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CashDTextField
                        label={T.EMPLOYEE_ID}
                        fullWidth
                        placeholder={T.EMPLOYEE_ID}
                        autoComplete="on"
                        size="small"
                        variant="outlined"
                        name="employeeId"
                        value={employeeId}
                        onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CashdDatePicker
                     label={T.VERIFICATION_COMPLETED_DATE}
                     fullWidth
                     format="MM/DD/YYYY"
                     placeholder={T.VERIFICATION_COMPLETED_DATE}
                     autoComplete="on"
                     size="small"
                     variant="outlined"
                     name="verificationCompletedDate"
                     value={verificationCompletedDate}
                     handleChange={onHandleDateChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CashdDatePicker
                      label={T.REVERIFICATION_DUE_DATE}
                      fullWidth
                      format="MM/DD/YYYY"
                      placeholder={T.REVERIFICATION_DUE_DATE}
                      autoComplete="on"
                      size="small"
                      variant="outlined"
                      name="reVerificationDueDate"
                      value={reVerificationDueDate}
                      handleChange={onHandleDateChange}
                      />
                  </Grid>
                </Grid>
          </CashDCard>
        </Grid>
      </Grid>

    </>
  );
};

export default MultiFileUpload;
