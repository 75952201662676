import { Pagination, styled } from "@mui/material";

const CashDPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul li button": {
    fontSize: 14,
  },
  "& .MuiPagination-ul li button.Mui-selected": {
    background: "grey",
    color: "white",
  },
}));

export default CashDPagination;
