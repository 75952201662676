//Material UI
import { Grid, Button, Typography, Divider, Card, Link } from '@mui/material';
//local imports
import MainCard from 'components/MainCard';
import CashDCard from 'components/common/CashDCard';
import { useNavigate } from 'react-router';
import T from 'utils/constants/T';
import { StyledBodyTypography, StyledTitleTypography } from '../CompanyTab/CompanyInfo';
import { fetchKybTabData } from 'store/reducers/companyKybTab';
import { convertDateIntoFrontEndFormat } from 'utils/date';

//react-redux
import { useSelector, useDispatch } from 'react-redux';

//Lodash
import { get } from 'lodash';

//CSS
import './kyb.css';
import '../../companyDetails.css';
import { useEffect } from 'react';

export const getFileNameFromUrl =(url: string) =>{
    const splitUrl = url.split("/");
    const fileName = splitUrl[splitUrl.length-1]
    return fileName;
}
const ShowKyb = () => {
    const navigate =useNavigate();
    const dispatch= useDispatch();
    const  { selectedCompany } = useSelector((state:any)=>state.dashboardSearch);
    const { companyName } = useSelector((state: any) => state.companyTabData);

    const { documentUrls,status: kybStatus, comments: kybComments, staffName: kybStaffName, employeeId: kybEmployeeId, verificationCompletedDate : kybVerificationCompletedDate, reVerificationDueDate: kybReVerificationDueDate}= useSelector((state: any)=>state.kybTabData);
    useEffect(()=>{
        dispatch(fetchKybTabData(get(selectedCompany,"_id","")));
    },[])
    
    const handleEdit = () =>{
        navigate('/companyDetails/kyb/edit');
    };
    
    return (
        <MainCard
        title={companyName}
        secondary={
            <Button
                variant='contained'
                onClick={handleEdit}
                className='main-card-button'
            >
                {T.EDIT}
            </Button>
        }
        >
            <Grid container spacing={3}>
            <Grid item xs={6}>
                    <CashDCard borderRadius='20px' minHeight="160px">
                        <Typography align='center' className='table-heading'>
                            {T.SAVED_DOCUMENTS}
                        </Typography>
                        {documentUrls.length!==0 ? documentUrls.map((url: string,index: number)=>{
                            return(
                                <Card className= "urlCard" elevation={0} key={index}>
                                    <Link href={url}>
                                        <Typography>
                                            {getFileNameFromUrl(url)}
                                        </Typography>
                                    </Link>
                                </Card>
                            )
                        })
                        :
                        
                        <Typography className='no-files-typo'>
                          {T.NO_FILES_UPLOADED}    
                        </Typography>
                        } 
                    </CashDCard>
                </Grid>
                <Grid item xs={6}>
                    <CashDCard borderRadius='20px' minHeight="160px">
                        <Typography className='status-card-heading'>
                            {T.STATUS}
                        </Typography>
                        <Typography className='status-card-text'>
                            {kybStatus}
                        </Typography>
                    </CashDCard>
                </Grid>
                <Grid item xs={12}>
                    <Typography className='comments-heading'>
                        {T.COMMENTS}
                    </Typography>
                    <Typography>
                    { kybComments }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MainCard title={T.COMPLETED_BY}>
                        <Grid container>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.CASHD_STAFF_NAME}</StyledTitleTypography>
                                <StyledBodyTypography>{kybStaffName}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.EMPLOYEE_ID}</StyledTitleTypography>
                                <StyledBodyTypography>{kybEmployeeId}</StyledBodyTypography>
                            </Grid>
                            <Grid className='divider' item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.VERIFICATION_COMPLETED_DATE}</StyledTitleTypography>
                                <StyledBodyTypography>{convertDateIntoFrontEndFormat(kybVerificationCompletedDate)}</StyledBodyTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTitleTypography>{T.REVERIFICATION_DUE_DATE}</StyledTitleTypography>
                                <StyledBodyTypography>{convertDateIntoFrontEndFormat(kybReVerificationDueDate)}</StyledBodyTypography>
                            </Grid>
                            <Grid className='divider' item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
            
        </MainCard>
    );
};

export default ShowKyb;