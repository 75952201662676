// third-party
import { combineReducers } from "redux";

// project import
import auth from "./auth";
import astute from "./astute";
import stepper from "./stepper";
import dashboardSearch from "./dashboardSearch";
import transactionData from "./transactionData";
import deductionData from "./deductions";
import companyTabData from "./companyTabDetails";
import kybTabData from "./companyKybTab";
import registeredEmployees from "./registeredEmployees";
import unregisteredEmployees from "./unRegisteredEmployees";
import financialSettingsTabData from "./companyFinancialSettingsTab";
import bankingSettingsTabData from "./companyBankingSettings";
import payrollSettingsTabData from "./companyPayrollSettings";
import deductionTransactionFilter from "./deductionTransactionFilter";
import whiteLabellingSettings from "./whiteLabellingSettings";
import getEmailSettingsData from "./getEmailSettings";
import getEmployeeData from "./getEmployeeData";
import getXeroTenantData from './xero';
import myobOnboarding from "./myob";
import dashboard from "./dashboard";
import wallets from "./wallets";
import timesheet from "./timesheet";
import menu from "./menu";
import snackbar from "./snackbar";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  astute,
  stepper,
  dashboardSearch,
  transactionData,
  deductionData,
  companyTabData,
  kybTabData,
  financialSettingsTabData,
  bankingSettingsTabData,
  payrollSettingsTabData,
  deductionTransactionFilter,
  menu,
  snackbar,
  registeredEmployees,
  unregisteredEmployees,
  wallets,
  timesheet,
  myobOnboarding,
  dashboard,
  whiteLabellingSettings,
  getEmailSettingsData,
  getEmployeeData,
  getXeroTenantData
});

export default reducers;
