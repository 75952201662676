//Material UI
import { Button, Typography, Checkbox, FormControl, FormControlLabel, Box } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';


//Local Imports
import MainCard from "components/MainCard";
import T from "utils/constants/T";
// import { StyledBodyTypography } from '../CompanyTab/CompanyInfo';
import CashDCard from 'components/common/CashDCard';


//CSS
import './payrollSettings.css';
import '../../companyDetails.css';
import { noop } from 'lodash';

interface EditPayrollSettingsTimeSheetProps{
    includeSubmitted: boolean,
    handleCheck:(e:any)=>void
    handleSave:any,
    companyName: string
}
const EditPayrollSettingsTimeSheet = ({
        includeSubmitted=false,
        companyName= "",
        handleCheck =noop,
        handleSave =noop
    }:EditPayrollSettingsTimeSheetProps) => {
   
    
    return (
        <MainCard 
            title={companyName} 
            secondary={
                <>
                    {/* <Button 
                        variant="outlined"
                        className='main-card-button' 
                        startIcon={<CachedIcon />}
                    >
                        {T.SYNC_PAYROLL}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                    <Button
                        variant="contained"
                        className='main-card-button' 
                        onClick={handleSave}
                        >
                        {T.SAVE}
                    </Button>
                </>
            }>

                <CashDCard borderRadius='20px'>
                    <Box >
                        <Typography className='time-sheet-text'>
                            {T.TIME_SHEETS_STATUS}
                        </Typography>
                        <FormControl component="fieldset"  className='time-sheet-check'>
                            <FormControlLabel
                                value="includeSubmitted"
                                name='includeSubmitted'
                                control={<Checkbox 
                                    checked={includeSubmitted}
                                    name='includeSubmitted'
                                    onChange={handleCheck}
                                />}
                                label={T.DO_NOT_INCLUDE_SUBMITTED}
                                labelPlacement="start"
                                onChange={handleCheck}
                            />
                        </FormControl>
                    </Box>
                </CashDCard>
        </MainCard>
    );
};

export default EditPayrollSettingsTimeSheet;