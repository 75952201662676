// third-party
import { FormattedMessage } from 'react-intl';
// import Transaction from 'assets/images/icons/transaction';
import Deduction from 'assets/images/icons/deductions';
import Employee from 'assets/images/icons/employee';
// assets
import {
  SearchOutlined,
  DashboardOutlined,
  LineChartOutlined,
  WalletOutlined,
  DollarCircleOutlined,
  FieldTimeOutlined,
  TeamOutlined,
  UserOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  SearchOutlined: SearchOutlined,
  dashboardOutlined: DashboardOutlined,
  lineChartOutlined: LineChartOutlined,
  walletOutlined: WalletOutlined,
  dollarCircleOutlined: DollarCircleOutlined,
  fieldTimeOutlined: FieldTimeOutlined,
  teamOutlined: TeamOutlined,
  userOutlined: UserOutlined,
  customerServiceOutlined: CustomerServiceOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const dashboard: NavItemType = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboardOutlined
    },
    {
      id: 'transactions',
      title: <FormattedMessage id="transactions" />,
      type: 'item',
      url: '/transactions',
      icon: icons.lineChartOutlined
    },
    {
      id: 'deductions',
      title: <FormattedMessage id="deductions" />,
      type: 'item',
      url: '/deductions',
      iconSvg: Deduction
    },
    {
      id: 'timesheets',
      title: <FormattedMessage id="timesheets" />,
      type: 'item',
      url: '/timesheet',
      icon: icons.fieldTimeOutlined,
    },
    {
      id: 'wallets',
      title: <FormattedMessage id="wallets" />,
      type: 'item',
      url: '/wallets',
      icon: icons.walletOutlined
    },
    {
      id: 'employees',
      title: <FormattedMessage id="employees" />,
      type: 'item',
      url: '/employees/registered',
      iconSvg: Employee
    }
  ]
};

export default dashboard;
