import { Children } from "react";

import {
  // Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  IconButton,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import { format } from "date-fns";
import { memo } from "utils/memo";
import T from "utils/constants/T";
// import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import { Menu } from "@mui/material";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1,
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface UnregisteredEmployeesBodyProps {
  allTableRows: any[];
  page: number;
  rowsPerPage: number;
  totalTableRowsCount: number;
  totalPageCount: number;
  opan: boolean;
  anchorEl: any;
  handleClick: any;
  handleClose: any;
  isCheckBoxSelected: any;
  handleRowCheckBoxClick: any;
  inviteUnregisteredEmployee: any;
  sendInvitationReminder: any;
  deleteUserProfile: any;
  systemCode: string
}
const UnregisteredEmployeesBody = ({
  allTableRows = [],
  page,
  rowsPerPage,
  totalTableRowsCount,
  totalPageCount,
  opan,
  anchorEl,
  handleClick,
  handleClose,
  isCheckBoxSelected,
  handleRowCheckBoxClick,
  inviteUnregisteredEmployee,
  sendInvitationReminder,
  deleteUserProfile,
  systemCode
}: UnregisteredEmployeesBodyProps) => {

  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch)
  const companyId = get(selectedCompany, "_id", "");

  // const getScheduleName = (data: any, systemCode: String) => {
  //   let scheduleName = { payPeriodName: "", payPeriodId: "" };

  //   if (systemCode == T.DEPUTY.toUpperCase()) {
  //     scheduleName.payPeriodName = get(
  //       data,
  //       "pay_period_originations[0].name",
  //       ""
  //     );
  //     scheduleName.payPeriodId = get(
  //       data,
  //       "pay_period_originations[0]._id",
  //       ""
  //     );
  //   } else if (systemCode == T.KEYPAY.toUpperCase()) {
  //     scheduleName.payPeriodName = get(
  //       data,
  //       "keypay_pay_schedules[0].name",
  //       ""
  //     );
  //     scheduleName.payPeriodId = get(data, "keypay_pay_schedules[0]._id", "");
  //   } else {
  //     scheduleName.payPeriodName = get(data, "xero_pay_calendars[0].Name", "");
  //     scheduleName.payPeriodId = get(data, "xero_pay_calendars[0]._id", "");
  //   }

  //   return scheduleName;
  // };

  const checkSalaryWag = (salaryWag: any, systemCode: any) => {
    let employeeType;
    if (systemCode === "DEPUTY") {
      employeeType = "Timesheet";
    } else {
      if (salaryWag === 2) {
        employeeType = "Timesheet";
      } else if (salaryWag === 3) {
        employeeType = "Salary & TimeSheet";
      } else {
        employeeType = "Salary";
      }
    }
    return employeeType;
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          // const scheduleName = getScheduleName(
          //   record,
          //   get(record?.companies[0]?.systems, "code", "")
          // );
          // const registrationDate = get(record, "created_date", "");
          // const date = new Date(registrationDate);
          // const registrationDateText = format(date, "dd MMM yyyy h:mm a");
          const isItemSelected = isCheckBoxSelected(record._id);
          const labelId = `enhanced-table-checkbox-${index}`;
          const businessUnitName = get(record, "business_units[0].name")
          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={record._id}
              selected={isItemSelected}
              sx={{ background: "white" }}
            >
              <StyledTableCell>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      onClick={(event) => handleRowCheckBoxClick(record._id)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId
                      }}
                    />} label=" " checked={get(record, 'selected', '')} />
                </FormGroup>
              </StyledTableCell>
              {!companyId && (
                <StyledTableCell>
                  {get(record, "companies[0].company_name", "")}
                </StyledTableCell>
              )}
              <StyledTableCell >
                {get(record, "fullname", "")}
              </StyledTableCell>
              <StyledTableCell>
                {get(record, "email", "")}
              </StyledTableCell>
              <StyledTableCell>
                {get(record, "system_employee_id", "")}
              </StyledTableCell>
              <StyledTableCell>
                {checkSalaryWag(
                  get(record, "salary_wag", ""),
                  get(record?.companies[0]?.systems, "code", "")
                )}
              </StyledTableCell>
              <StyledTableCell>{record.is_invited === 0 ?
              <Chip color='error' label='Uninvited' size="small" variant="light" />
              : 
              <Chip color='success' label='Invited' size="small" variant="light" />
              }</StyledTableCell>
              <StyledTableCell>
                {get(record, "invitation_date", "-")}
              </StyledTableCell>
              {/* <StyledTableCell>{scheduleName.payPeriodName}</StyledTableCell> */}
              {systemCode == T.ASTUTE.toUpperCase() && (
                <StyledTableCell>{businessUnitName}</StyledTableCell>
              )}
              <StyledTableCell>
                <IconButton
                  //  id="basic-button"
                  aria-controls={"basic-menu"}
                  aria-haspopup="true"
                  //  aria-expanded={'true' : undefined}
                  onClick={(e: any) => handleClick(e, record._id)} // set employee id for use in actions dropdown
                >
                  <MoreVertIcon />
                </IconButton>
              </StyledTableCell>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={inviteUnregisteredEmployee}>Invite</MenuItem>
                <MenuItem onClick={sendInvitationReminder}>Send Reminder</MenuItem>
                <MenuItem onClick={deleteUserProfile}>Delete</MenuItem>

              </Menu>
            </TableRow>
          );
        })
      )}

      {allTableRows && allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              {T.NO_RECORDS}
            </Typography>
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default memo(UnregisteredEmployeesBody);
