//Material-UI
import {
  TableRow,
  TableCell,
  TableBody,
  styled,
} from '@mui/material';



//Date-fns
// import { format } from 'date-fns';

//Local imports
import { memo } from "utils/memo";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1, 
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface ViewBankingTableBodyProps {
  accountName?: string,
  fundingType?: string,
  nameOfBank?: string,
  bsb?: string,
  accountNumber?: string,
  balance?: string,
  interestRate?: string
}
const ViewBankingTableBody = ({
  accountName = "",
  fundingType = "",
  nameOfBank = "",
  bsb = "",
  accountNumber = "",
  balance = "",
  interestRate = ""
}: ViewBankingTableBodyProps) => {
  return (

    <TableBody>
      <TableRow sx={{ background: "white" }} >
        <StyledTableCell>{accountName}</StyledTableCell>
        <StyledTableCell>{fundingType}</StyledTableCell>
        <StyledTableCell>{nameOfBank}</StyledTableCell>
        <StyledTableCell>{bsb}</StyledTableCell>
        <StyledTableCell>{accountNumber}</StyledTableCell>
        <StyledTableCell>{balance}</StyledTableCell>
        <StyledTableCell>{interestRate}</StyledTableCell>
      </TableRow>
      {/* {Children.toArray(
        allTableRows.map((record, index) => {
          return (
            <TableRow sx={{ background: "white" }} key={index}>
              <StyledTableCell>{get(record,"srNo","")}.</StyledTableCell>
            </TableRow>
          );
        })
      )} */}

      {/* {allTableRows && allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              {T.NO_RECORDS}
            </Typography>
          </StyledTableCell>
        </TableRow>
      )} */}
    </TableBody>
  );
};



export default memo(ViewBankingTableBody);
