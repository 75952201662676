import { noop } from "lodash";
import { PAGINATION } from "utils/constants/pagination";
import TransactionsTable from "./TransactionsTable";

interface TransactionsProps {
  page?: number;
  onPageChange?: (page: number) => void;
  rowsPerPage?: number;
  allTableRows?: any;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  totalTableRowsCount?: number;
  totalPageCount?: number;
  handleAddEditProjectInfoDialog?: () => void;
  handleDeleteProjectDialog?: () => void;
}

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Transactions = ({
  page = INITIAL_PAGE,
  onPageChange = noop,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  onRowsPerPageChange = noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleAddEditProjectInfoDialog = noop,
  handleDeleteProjectDialog = noop,
}: TransactionsProps) => {
  return (
    <TransactionsTable
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      allTableRows={allTableRows}
      handleAddEditProjectInfoDialog={handleAddEditProjectInfoDialog}
      handleDeleteProjectDialog={handleDeleteProjectDialog}
    />
  );
};

export default Transactions;
