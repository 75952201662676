import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, Tooltip } from '@mui/material';
// project import
import Dot from 'components/@extended/Dot';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import T from 'utils/constants/T';
// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {

  const theme = useTheme();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen, openItem } = menu;
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'primary.main';
  let { onBoardedCompanyData, isCashDSuperAdmin } = useSelector(state => state.auth)

  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  if (item?.external) {
    listItemProps = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} onClick={handleClickOpen} />) };
  }
  const handleClickOpen = () => {
    alert(T.NO_PERMISSION);
  };
  const Icon = item.icon!;
  const IconSvg = item.iconSvg!;
  const IconSVG = item.iconSvg ? <IconSvg style={{ fontSize: drawerOpen ? '1.25rem' : '1.25rem' }} /> : false;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1.25rem' : '1.25rem' }} /> : false;
  const itemIconTitle = item.title;
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const disableListItemButton = () => {

    // if supser admin then show do not disable list
    if (isCashDSuperAdmin) return false

    // if company admin and no copmany data then disable the list
    if (!onBoardedCompanyData?._id) return true

    return false
  }

  return (
    <>
      {menuOrientation === LAYOUT_CONST.VERTICAL_LAYOUT || downLG ? (
        <ListItemButton
          {...listItemProps}
          disabled={disableListItemButton()}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            pl: drawerOpen ? `${level * 28}px` : 1.5,
            py: !drawerOpen && level === 1 ? 0.75 : 0.25,
            ...(drawerOpen && {
              "&:hover": {
                bgcolor:
                  theme.palette.mode === "dark" ? "divider" : "primary.lighter",
              },
              "&.Mui-selected": {
                bgcolor:
                  theme.palette.mode === "dark" ? "divider" : "primary.lighter",
                borderRight: `2px solid ${theme.palette.primary.main}`,
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "divider"
                      : "primary.lighter",
                },
              },
            }),
            ...(!drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
          }}
          {...(matchDownLg && {
            onClick: () => dispatch(openDrawer(false)),
          })}
        >
          {(itemIcon || IconSVG) && (
            <Tooltip title={itemIconTitle} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 28,
                  color: isSelected ? iconSelectedColor : textColor,
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    width: 40,
                    height: 40,
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "secondary.light"
                          : "secondary.lighter",
                    },
                  }),
                  ...(!drawerOpen &&
                    isSelected && {
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? "primary.900"
                        : "primary.lighter",
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "primary.darker"
                          : "primary.lighter",
                    },
                  }),
                }}
              >
                {itemIcon ? (
                  itemIcon
                ) : (
                  IconSVG
                )}
              </ListItemIcon>
            </Tooltip>
          )}
          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
              sx={{
                paddingTop: '10px',
                height: '40px'
              }}
              primary={
                <Typography
                  variant="h6"
                  sx={{ color: isSelected ? iconSelectedColor : textColor }}
                >
                  {item.title}
                </Typography>
              }
            />
          )}
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          {...listItemProps}
          disabled={disableListItemButton()}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            ...(drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                bgcolor: "transparent",
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor: "transparent",
                },
              },
            }),
            ...(!drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 36,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                  bgcolor: "transparent",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          {!itemIcon && (
            <ListItemIcon
              sx={{
                color: isSelected ? "primary.main" : "secondary.main",
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                  bgcolor: "transparent",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }),
              }}
            >
              <Dot size={4} color={isSelected ? "primary" : "secondary"} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography variant="h6" color="inherit">
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
