import { get } from "lodash";
import T from "./constants/T";
import authApi from "interceptor/authInterceptor";

//export function
export const exportData = (data: any, fileName: any, type: any) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

//limit a number to 2 decimal places\
export const decimalLimiter = (number: any) => {
  const numberConvert = Number(number)
  let incomingNumber = numberConvert.toFixed(2);
  return incomingNumber;
}

export const formatAmount = (amount: any) => {
  try {
    return `$${parseFloat(amount).toFixed(2)}`
  } catch (error) {
    return amount
  }
}

export const getScheduleName = (data: any, systemCode: String) => {

  let scheduleName = { payPeriodName: "", payPeriodId: "" };

  if (systemCode == T.DEPUTY.toUpperCase()) {
    scheduleName.payPeriodName = get(data, "pay_period_originations[0].name", "");
    scheduleName.payPeriodId = get(data, "pay_period_originations[0]._id", "");
  } else if (systemCode == T.KEYPAY.toUpperCase()) {
    scheduleName.payPeriodName = get(data, "keypay_pay_schedules[0].name", "");
    scheduleName.payPeriodId = get(data, "keypay_pay_schedules[0]._id", "");
  } else {
    scheduleName.payPeriodName = get(data, "xero_pay_calendars[0].Name", "");
    scheduleName.payPeriodId = get(data, "xero_pay_calendars[0]._id", "");
  }

  return scheduleName;

}

export const isEmailExists = async (email: string) => {
  const response = await authApi.get(`/v2/api/users/emailExists?email=${email}`)
  return response?.data?.data?.status
}

export function maskNumber(bankAccount: any) {
  let maskedPart = "";
  for (let i = 0; i < bankAccount?.length - 3; i++) {
    maskedPart += "*";
  }
  maskedPart += bankAccount?.slice(-3);
  return maskedPart;
}