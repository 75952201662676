//react
import { useEffect, useReducer } from "react";

//Material- UI
import { Button, Divider, Grid, Link } from "@mui/material";

//React-router-dom
import { useLocation, useNavigate } from "react-router-dom";

//react-redux
import { useDispatch, useSelector } from "react-redux";

//Local imports
import MainCard from "components/MainCard";
import "../../companyDetails.css";
import T from "utils/constants/T";

//lodash
import { get } from "lodash";
import CashDCard from "components/common/CashDCard";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../CompanyTab/CompanyInfo";

import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { fetchCompanyDetails } from "store/reducers/companyTabDetails";
const ViewWhiteLabellingDetails = () => {
  const location = useLocation();
  const { state: colorState } = location;
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const dispatch = useDispatch();
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      newPrimaryColor: "",
      newSecondaryColor: "",
      newLogo: null,
    }
  );

  const { newPrimaryColor, newSecondaryColor, newLogo } = localState;
  const { companyName, primaryColor, secondaryColor, logoUrl, custom } =
    useSelector((state: any) => state.companyTabData);
  const logoUrlArr = logoUrl.split("/");
  const logoURLName = logoUrlArr[logoUrlArr.length - 1];
  let selectedSetting = custom ? T.CUSTOM : T.DEFAULT;
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate("/companyDetails/whiteLabelling/edit");
  };
  // useEffect(() => {
  // }, [selectedCompanyId]);
  useEffect(() => {
    if (!selectedCompanyId) {
      navigate("/dashboard");
    }
    dispatch(fetchCompanyDetails(selectedCompanyId));
    setLocalState({
      newPrimaryColor: primaryColor,
      newSecondaryColor: secondaryColor,
      newLogo: logoURLName,
    });
  }, [selectedCompanyId, primaryColor ,secondaryColor, newLogo, logoURLName]);

  useEffect(() => {
    if (colorState !== null && colorState !== undefined) {
      setLocalState({
        newPrimaryColor: colorState?.primaryColorCode,
        newSecondaryColor: colorState?.secondaryColorCode,
      });
    }
  }, []);

  return (
    <MainCard
      title={companyName}
      secondary={
        <Button
          onClick={handleEdit}
          variant="contained"
          className="main-card-button"
        >
          {T.EDIT}
        </Button>
      }
    >
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <StyledTitleTypography>{T.SETTING}</StyledTitleTypography>
                <StyledBodyTypography sx={{ marginLeft: "16px" }}>
                  {selectedSetting}
                </StyledBodyTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <StyledTitleTypography>{T.PRIMARY_COLOUR}</StyledTitleTypography>
              {primaryColor === "Default" ? (
                <StyledBodyTypography sx={{ marginLeft: "16px" }}>
                  {T.DEFAULT}
                </StyledBodyTypography>
              ) : (
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    backgroundColor: newPrimaryColor,
                    padding: "10px",
                    marginLeft: "25px",
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <StyledTitleTypography>
                {T.SECONDARY_COLOUR}
              </StyledTitleTypography>
              {secondaryColor === "Default" ? (
                <StyledBodyTypography sx={{ marginLeft: "16px" }}>
                  {T.DEFAULT}
                </StyledBodyTypography>
              ) : (
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    backgroundColor: newSecondaryColor,
                    padding: "10px",
                    marginLeft: "25px",
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <StyledTitleTypography>{T.LOGO}</StyledTitleTypography>
              {selectedSetting === "Custom" ? (
                <>
                  {newLogo ? (
                    <Link href={logoUrl}>
                      <Typography sx={{ marginLeft: "16px" }}>
                        {newLogo}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography sx={{ marginLeft: "16px" }}>
                      {T.NO_LOGO_UPLOADED}
                    </Typography>
                  )}
                </>
              ) : (
                <Link href={"#"}>
                <Typography sx={{ marginLeft: "16px" }}>
                  {T.DEFAULT}
                </Typography>
              </Link>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CashDCard>
    </MainCard>
  );
};

export default ViewWhiteLabellingDetails;
