import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "store";
import {
  onBoardingStatusUpdate,
  onboardedCompanyDetails,
  updateStaffData,
} from "./auth";
import authApi from "interceptor/authInterceptor";
import { updateDisplayStepper } from "./stepper";
import { updateSelectedCompany } from "./dashboardSearch";
import { get } from "lodash";

// types
export type AstuteProps = {
  companies: object[];
  selectedCompany: {
    name: string;
    abn: string;
    email: string;
    phone: string;
    address_street: string;
    address_locality: string;
    address_region: string;
    address_postcode: string;
  };
  error: null;
  loader: Boolean;
  isLoadingEntities: Boolean;
  apiDetails: {
    apiKey: string;
    apiUsername: string;
    apiPassword: string;
  };
  apiKey: String;
  apiUsername: String;
  apiPassword: String;
};

// initial state
const initialState: AstuteProps = {
  companies: [],
  selectedCompany: {
    name: "",
    abn: "",
    email: "",
    phone: "",
    address_street: "",
    address_locality: "",
    address_region: "",
    address_postcode: "",
  },
  error: null,
  loader: false,
  isLoadingEntities: false,
  apiDetails: {
    apiKey: "",
    apiUsername: "",
    apiPassword: "",
  },
  apiKey: "",
  apiUsername: "",
  apiPassword: "",
};

// ==============================|| SLICE - ASTUTE ||============================== //

const astute = createSlice({
  name: "astute",
  initialState,
  reducers: {
    getAstuteCompaniesSuccess(state, action) {
      state.companies = action.payload;
    },
    updateAstuteSelectedCompanySuccess(state, action) {
      state.selectedCompany = action.payload;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearAstute: (state) => initialState,
    updateAstuteReducer: (state: any, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setApiDetails(state, action) {
      console.log("action.payload", action.payload);
      const { apiKey, apiUsername, apiPassword } = action.payload;
      console.log("apiKey", apiKey);
      state.apiKey = apiKey;
      state.apiUsername = apiUsername;
      state.apiPassword = apiPassword;
    },
  },
});

export default astute.reducer;

export const {
  getAstuteCompaniesSuccess,
  updateAstuteSelectedCompanySuccess,
  clearAstute,
  updateAstuteReducer,
  setApiDetails,
} = astute.actions;

export function getAstuteCompanyEntities(payload: any) {
  return async () => {
    // dispatch(
    //   astute.actions.updateAstuteReducer({
    //     key: "isLoadingEntities",
    //     value: true,
    //   })
    // );
    const response = await authApi.post(
      `/v2/api/companies/astute/entityQuery`,
      payload
    );
    if (response.data) {
      dispatch(astute.actions.getAstuteCompaniesSuccess(response.data.data));
      dispatch(astute.actions.setApiDetails(payload));
      // dispatch(
      //   astute.actions.updateAstuteReducer({
      //     key: "isLoadingEntities",
      //     value: false,
      //   })
      // );
    }
  };
}

export function updateAstuteCompany(
  formValues: any,
  selectedCompany: any,
  apiKey: any,
  apiUsername: any,
  apiPassword: any
) {
  return async () => {
    const body = {
      // form values
      email_company: formValues.email,
      company_name: formValues.companyName,
      phone_company: selectedCompany.phone,
      abn: formValues.abn,

      // selectec company values
      company_region: selectedCompany.address_region,
      address: selectedCompany.address_street,
      suburb: selectedCompany.payroll_address_suburb,
      city: selectedCompany.address_locality,
      state: selectedCompany.address_region,
      postcode: selectedCompany.address_postcode,
      currency: selectedCompany.payroll_currency_default,
      system_cm_cid: selectedCompany.CM_CID,
      contact_person: selectedCompany.payroll_empdupe_contact,

      // company api details
      system_company_id: apiKey,
      system_api_username: apiUsername,
      system_api_password: apiPassword,
    };
    const response = await authApi.post(`/v2/api/users/astuteCompanies`, body);
    console.log("response from astute companies", response);
    if (response?.data?.code === 200) {
      dispatch(updateDisplayStepper({ displayStepper: false }));

      const companyData = response.data.data?.companyData;

      const onBoardedUserCompanyDetails = {
        company_name: get(companyData, "company_name", ""),
        _id: get(companyData, "_id", ""),
      };

      dispatch(
        onboardedCompanyDetails({
          onBoardedCompanyDetails: onBoardedUserCompanyDetails,
        })
      );

      dispatch(
        onBoardingStatusUpdate({
          onBoardedCompanyData: onBoardedUserCompanyDetails,
        })
      );
      dispatch(
        updateSelectedCompany({
          selectedCompany: response.data.data?.companyData,
        })
      );
      dispatch(updateStaffData({ staffData: response.data.data?.staffData }));
    }
  };
}
