import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { dispatch } from "store";
import toast from "utils/ToastNotistack";

const initialState = {
  employees: [],
  page: 0,
  pageSize: 10,
  totalRecords: 0,
  totalPages: 0,
  isLoading: false,
};

const unregisteredEmployees = createSlice({
  name: "unregisteredEmployees",
  initialState,
  reducers: {
    getEmployees: (state, action) => {
      const { employees, page, pageSize, totalRecords, totalPages } =
        action.payload;
      state.employees = employees;
      state.page = page;
      state.pageSize = pageSize;
      state.totalRecords = totalRecords;
      state.totalPages = totalPages;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearUnregisteredEmployees: () => initialState,
  },
});

export const { getEmployees, setIsLoading, clearUnregisteredEmployees } =
  unregisteredEmployees.actions;

export default unregisteredEmployees.reducer;

export function fetchUnregisteredEmployees(
  page: number,
  pageSize: number,
  companyId: string,
  employeeName: string,
  businessUnitId?: string
) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/users/unregistered?page=${page}&pageSize=${pageSize}&companyId=${companyId}&employeeName=${employeeName}&businessUnitId=${
        businessUnitId ? businessUnitId : ""
      }`
    );
    if (response.data) {
      dispatch(
        getEmployees({
          employees: response.data.data.length
            ? response.data.data.map((emp: any) => ({
                ...emp,
                selected: false,
              }))
            : [],
          page: response.data.page,
          pageSize: response.data.pageSize,
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  };
}

export function loadUnregisteredEmployees(unregisteredEmployees: any) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/users/loadUnregistered`,
      unregisteredEmployees
    );
    if (response.data) {
      if (response.data.message === "Added Successfully") {
        toast(response.data.message, { variant: "success" });
      } else {
        toast(response.data.message, { variant: "info" });
      }
    }
  };
}

export function inviteUnregistered(payload: any) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/users/inviteEmployees`,
      payload
    );

    if (response.data) {
      toast("Invited successfully", { variant: "success" });
    }
  };
}

export function sendBulkInvitationReminder(staffIds: Array<string>) {
  return async () => {
    console.log("staffIds", staffIds);
    const response = await authApi.post(
      `/v2/api/users/invitation/sendBulkInvitationReminder`,
      { staffIds }
    );

    if (response.data) {
      toast("Invitation Reminders Sent successfully", { variant: "success" });
    }
  };
}

export function deleteUnregisteredUser(employeeId: any) {
  return async () => {
    const response = await authApi.delete(
      `/v2/api/users/unregistered/${employeeId}`
    );
    if (response.data) {
      toast(response.data.message, { variant: "success" });
    }
  };
}
