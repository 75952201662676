import T from "./T";

const unregisteredEmployeesHeader = (companyId: string, systemCode: string) => {
  const {
    INVITE,
    COMPANY,
    EMPLOYEE_NAME,
    EMAIL,
    PAYROLL_ID,
    PAY_TYPE,
    STATUS,
    INVITATION_DATE,
    BUSINESS_UNIT,
    // SCHEDULE,
    // REGISTRATION_DATE,
    ACTION,
  } = T;

  const businessUnit = [];
  if (systemCode == T.ASTUTE.toUpperCase())
    businessUnit.push({ label: BUSINESS_UNIT, type: "text" });

  const tableFields = [
    { label: INVITE, type: "checkbox" },
    { label: EMPLOYEE_NAME, type: "text" },
    { label: EMAIL, type: "text" },
    { label: PAYROLL_ID, type: "text" },
    { label: PAY_TYPE, type: "text" },
    { label: STATUS, type: "text" },
    { label: INVITATION_DATE, type: "text" },
    // { label: SCHEDULE, type: "text" },
    ...businessUnit,
    // { label: REGISTRATION_DATE, type: "text" },
    { label: ACTION, type: "text" },
  ];

  if (!companyId) tableFields.splice(1, 0, { label: COMPANY, type: "text" });

  return tableFields;
};

export default unregisteredEmployeesHeader;
