import T from "./T";

const {
  EMPLOYEE_NAME,
  EMAIL,
  PAYROLL_ID,
  // DATE_TIME,
  PAY_PERIOD,
  AVAILABLE_BALANCE,
  SALARY,
  LIMIT,
  CURRENT_FEE,
  WITHDRAWLS,
  SALARY_BALANCE,
} = T;
export const WALLETS_TABLE_HEADER = [
  { label: EMPLOYEE_NAME },
  { label: EMAIL },
  { label: PAYROLL_ID },
  // { label: DATE_TIME },
  { label: PAY_PERIOD },
  { label: AVAILABLE_BALANCE },
  { label: SALARY },
  { label: LIMIT },
  { label: CURRENT_FEE },
  { label: WITHDRAWLS },
  { label: SALARY_BALANCE },
];
