import { Divider, Grid } from "@mui/material";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
const ViewPersonalDetails = () => {
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);

  return (
    <Grid item xs={12}>
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.FIRST_NAME}</StyledBodyTypography>
            <StyledTitleTypography>
              {getUserDataByUSerID.firstName}
            </StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.LAST_NAME}</StyledBodyTypography>
            <StyledTitleTypography>
              {getUserDataByUSerID.lastName}
            </StyledTitleTypography>
          </Grid>
          <Grid className="divider" item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.EMAIL}</StyledBodyTypography>
            <StyledTitleTypography>
              {getUserDataByUSerID.email}
            </StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.PHONE}</StyledBodyTypography>
            <StyledTitleTypography>
              {getUserDataByUSerID.phone}
            </StyledTitleTypography>
          </Grid>
          <Grid className="divider" item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.ADDRESS}</StyledBodyTypography>
            <StyledTitleTypography>
              {getUserDataByUSerID.userAddress}
            </StyledTitleTypography>
          </Grid>
        </Grid>
      </CashDCard>
    </Grid>
  );
};

export default ViewPersonalDetails;
