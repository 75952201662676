//Lodash
import { noop } from "lodash";

//Local-Imports
import { PAGINATION } from "utils/constants/pagination";
import DeductionTable from "./DeductionTable";


interface DeductionProps {
    page?: number;
    onPageChange?: (page:number) => void;
    rowsPerPage?: number;
    allTableRows?: any;
    onRowsPerPageChange?: (rowsPerPage:number) => void;
    totalTableRowsCount?: number;
    totalPageCount?: number;
    open?: boolean,
    handleExportViewDeduction?: any,
    handleExportViewTransaction?: any,
    deductionViewList?: any[],
    anchorEl?: any,
    handleClick?: any,
    handleClose?: any,
    handleViewDeduction?: any,
    handleRunDeduction?: any,
    handleReverseDeduction?: any,
  }

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Deductions = ({
  page = INITIAL_PAGE,
  onPageChange=noop,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows= [],
  onRowsPerPageChange=noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  open = false,
  anchorEl,
  handleExportViewDeduction=noop,
  handleExportViewTransaction=noop,
  deductionViewList=[],
  handleClick = noop,
  handleClose = noop,
  handleViewDeduction = noop,
  handleRunDeduction = noop,
  handleReverseDeduction = noop,
}:DeductionProps) => {
  return (
    <DeductionTable
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      open={open}
      anchorEl={anchorEl}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      allTableRows={allTableRows}
      onRowsPerPageChange={onRowsPerPageChange}
      handleClick={handleClick}
      handleClose={handleClose}
      handleExportViewDeduction={handleExportViewDeduction}
      handleExportViewTransaction={handleExportViewTransaction}
      deductionViewList={deductionViewList}
      handleViewDeduction={handleViewDeduction}
      handleRunDeduction={handleRunDeduction}
      handleReverseDeduction={handleReverseDeduction}
    />
  );
};

export default Deductions;
