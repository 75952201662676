import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// project import
// import axios from 'utils/axios';

// types
import { AuthProps } from "types/auth";
import publicApi from "interceptor/publicInterceptor";

// initial state
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  onBoardedCompanyData: null,
  staffData: null,
  isCashDSuperAdmin: false,
  isEmployerAdmin: false,
  onBoardedCompanyDetails: {
    company_name: "",
  },
  role: "",
};

// ==============================|| SLICE - MENU ||============================== //

export const fetchDashboard = createAsyncThunk("", async () => {
  const response = await publicApi.get("/api/dashboard");
  return response.data;
});

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateRole(state, action) {
      state.role = action.payload;
    },
    loginUser(state, action) {
      let { user } = action.payload;
      state.isInitialized = true;
      state.isLoggedIn = true;
      state.user = user || initialState.user;
    },
    register(state, action) {
      let { user } = action.payload;
      state.user = user || initialState.user;
    },
    logoutUser(state, action) {
      let { isInitialized } = action.payload;
      state.isInitialized = isInitialized || initialState.isInitialized;
      state.isLoggedIn = false;
      state.user = null;
      state.onBoardedCompanyData = null;
      state.staffData = null;
    },
    onBoardingStatusUpdate(state, action) {
      let { onBoardedCompanyData } = action.payload;
      state.onBoardedCompanyData = onBoardedCompanyData;
    },
    updateStaffData(state, action) {
      let { staffData } = action.payload;
      state.staffData = staffData;
    },
    loggedInUserData(state, action) {
      let { isCashDSuperAdmin, isEmployerAdmin, onBoardedCompanyDetails } =
        action.payload;
      state.isCashDSuperAdmin =
        initialState.isCashDSuperAdmin || isCashDSuperAdmin;
      state.isEmployerAdmin = initialState.isEmployerAdmin || isEmployerAdmin;
      state.onBoardedCompanyDetails = onBoardedCompanyDetails;
    },
    onboardedCompanyDetails(state, action) {
      state.onBoardedCompanyDetails = action.payload.onBoardedCompanyDetails;
    },
    clearAuth: (state) => initialState,
  },

  extraReducers(builder) {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });
  },
});

export default auth.reducer;

export const {
  loginUser,
  register,
  logoutUser,
  onBoardingStatusUpdate,
  updateStaffData,
  loggedInUserData,
  updateRole,
  onboardedCompanyDetails,
  clearAuth,
} = auth.actions;
