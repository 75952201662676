import { Table as TableView, Box, Card, Grid, Paper, TableContainer } from "@mui/material"
import TableHeader from "pages/TransactionScreeen/TableHeader"
// import MasterPagination from "utils/MasterPagination"
import MasterPagination from "utils/MasterPagination"
import TimesheetBody from "./timesheetBody"
import { TIMESHEET_TABLE_HEADER } from "utils/constants/timesheet"

interface WalletProps {
    timesheet: any,
    page: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number,
    onPageChange: any,
    onRowsPerPageChange: any,
    systemPayrollCode: string
}

const Timesheet = ({
    timesheet,
    page,
    pageSize,
    totalRecords,
    totalPages,
    onPageChange,
    onRowsPerPageChange,
    systemPayrollCode
}: WalletProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        // p: "0px 8px",
                        p: "0px 0px",
                        mt: 1.5,
                    }}
                >
                    <Box overflow="hidden">
                        <TableContainer
                            component={Paper}
                            // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                            sx={{ height: "inherit", overflowY: "auto" }}
                        >
                            <TableView stickyHeader>
                                <TableHeader columns={TIMESHEET_TABLE_HEADER} />
                                <TimesheetBody
                                    allTableRows={timesheet}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    totalTableRowsCount={totalRecords}
                                    totalPageCount={totalPages}
                                    systemPayrollCode={systemPayrollCode}
                                />
                            </TableView>
                        </TableContainer>
                    </Box>

                    <MasterPagination
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onRowsPerPageChange}
                        totalTableRowsCount={totalRecords}
                        totalPageCount={totalPages}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default Timesheet