import { Divider, Grid } from "@mui/material";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
const ViewBankDetails = () => {
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);

  return (
    <Grid item xs={12}>
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.BANK_ACCOUNT_NAME}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.bankAccountName}</StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.BANK_BSB_NUMBER}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.bankBSBNumber}</StyledTitleTypography>
          </Grid>
          <Grid className="divider" item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.BANK_ACCOUNT_NUMBER}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.bankAccountNumber}</StyledTitleTypography>
          </Grid>
        </Grid>
      </CashDCard>
    </Grid>
  );
};

export default ViewBankDetails;
