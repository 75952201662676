import { createSlice } from "@reduxjs/toolkit";

// types
export type StepperProps = {
  displayStepper: Boolean;
};

// initial state
const initialState: StepperProps = {
  displayStepper: false,
};

// ==============================|| SLICE - ASTUTE ||============================== //

const stepper = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    updateDisplayStepper(state, action) {
      let { displayStepper } = action.payload;
      state.displayStepper = displayStepper || initialState.displayStepper;
    },
    clearStepper: () => initialState,
  },
});

export default stepper.reducer;

export const { updateDisplayStepper, clearStepper } = stepper.actions;
