import { useEffect, useReducer } from "react";
import { Box, Tabs, Tab, styled } from "@mui/material";
import { IdcardOutlined, SettingOutlined } from "@ant-design/icons";
import T from "utils/constants/T";

import {
  Link,
  useLocation,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import MainCard from "components/MainCard";
import { get } from "lodash";
import { useSelector } from "store";
import CompanyIcon from "assets/images/icons/company";
import PayrollSettingsIcon from "assets/images/icons/payrollSettingsIcon";
import BankingSettingsIcon from "assets/images/icons/bankingSettingsIcon";

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "16px",
  alignItems: "center",
  justifyContent: "flex-start",
  color: "black",
  minHeight: "unset",
  backgroundColor: "white",
  //   border: `1px solid gray`,
}));

const EmployeeDetailsPage = () => {
  let { selectedCompany } = useSelector((state) => state.dashboardSearch);
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);
  let selectedTab = 0;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      value: selectedTab,
    }
  );

  const { value } = localState;
  const { employeeID } = useParams();

  useEffect(() => {
    navigate(`/employees/employeeDetails/${employeeID}/personalProfile/view`);
    if (!selectedCompanyId) {
      if (
        pathname ===
        `/employees/employeeDetails/${employeeID}/personalProfile/view`
      ) {
        setLocalState({
          value: 0,
        });
      }
    } else if (
      selectedCompanyId &&
      selectedCompanyId !== getUserDataByUSerID.companyID
    ) {
      navigate("/employees/registered");
    }
  }, [selectedCompanyId, getUserDataByUSerID.companyID]);
  switch (pathname) {
    case `/employees/employeeDetails/${employeeID}/settings/edit`:
      selectedTab = 4;
      break;
    case `/employees/employeeDetails/${employeeID}/settings/view`:
      selectedTab = 4;
      break;
    case `/employees/employeeDetails/${employeeID}/bankDetails/view`:
      selectedTab = 3;
      break;
    case `/employees/employeeDetails/${employeeID}/payrollDetails/view`:
      selectedTab = 2;
      break;
    case `/employees/employeeDetails/${employeeID}/companyDetails/view`:
      selectedTab = 1;
      break;
    case `/employees/employeeDetails/${employeeID}/personalProfile/view`:
    default:
      selectedTab = 0;
  }

  const handleTabChange = (event: any, newValue: number) => {
    setLocalState({
      value: newValue,
    });
  };

  return (
    <MainCard pageHeading={T.SETTINGS} border={false} boxShadow>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="account profile tab"
        >
          <Tab
            icon={<IdcardOutlined />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/personalProfile/view`}
            label={T.PERSONAL_DETAILS}
          />
          <Tab
            icon={<CompanyIcon />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/companyDetails/view`}
            label={T.COMPANY_DETAILS}
          />
          <Tab
            icon={<PayrollSettingsIcon />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/payrollDetails/view`}
            label={T.PAYROLL_DETAILS}
          />
          <Tab
            icon={<BankingSettingsIcon />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/bankDetails/view`}
            label={T.BANK_DETAILS}
          />
          <Tab
            icon={<SettingOutlined />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/settings/view`}
            label={T.SETTINGS}
          />
          {/* <Tab
            icon={<SettingOutlined />}
            iconPosition="start"
            component={Link}
            to={`/employees/employeeDetails/${employeeID}/settings/edit`}
            label={T.SETTINGS}
          /> */}
        </Tabs>
      </Box>
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        <Outlet />
      </Box>
    </MainCard>
  );
};

export default EmployeeDetailsPage;
