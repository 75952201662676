import T from "./T";

const {
  COMPANY_NAME,
  PAY_PERIOD,
  SCHEDULE,
  DEDUCTION_STATUS,
  WITHDRAWAL_STATUS,
  DATE,
  TIME,
  AMOUNT,
  ACTION,
  SENT,
  UNSENT,
} = T;

const deductionTableHeader = (companyId: string, systemCode: string) => {
 const headerFields = [
    { label: PAY_PERIOD },
    { label: DEDUCTION_STATUS, align: "center" },
    { label: WITHDRAWAL_STATUS, align: "center" },
    { label: `${DATE} & ${TIME}` },
    { label: AMOUNT },
    { label: ACTION },
  ];

  // if copmany id provided then add schdule column too
  if (systemCode !== "MYOBADVANCED") {
    if (companyId) headerFields.splice(2, 0, { label: SCHEDULE });
    // if company id not selected, then only show company name column
    else headerFields.unshift({ label: COMPANY_NAME });
  }

  return headerFields;
};

export default deductionTableHeader;

export const DEDUCTION_STATUS_LIST = [SENT, UNSENT];
