// import { useSelector } from "store";
import T from "./T";
// import { get } from "lodash";

// const data = useSelector((state: any) => state.dashboardSearch);
// console.log("data", data);
// const companyId = get(selectedCompany, "_id", "");
// console.log("companyId", companyId);

const employeesTableHeader = (companyId: string, systemCode: string) => {
  const {
    COMPANY,
    EMPLOYEE_NAME,
    EMAIL,
    PAYROLL_ID,
    PAY_TYPE,
    SCHEDULE,
    BUSINESS_UNIT,
    STATUS,
    REGISTRATION_DATE,
    ACTION,
  } = T;

  const businessUnit: any = [];
  if (systemCode == T.ASTUTE.toUpperCase())
    businessUnit.push({ label: BUSINESS_UNIT });

  const tableFields = [
    { label: EMPLOYEE_NAME },
    { label: EMAIL },
    { label: PAYROLL_ID },
    { label: PAY_TYPE },
    { label: SCHEDULE },
    ...businessUnit,
    { label: STATUS },
    { label: REGISTRATION_DATE },
    { label: ACTION },
  ];

  if (!companyId) tableFields.unshift({ label: COMPANY });

  return tableFields;
};

export default employeesTableHeader;
