import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { dispatch } from "store";
import toast from "utils/ToastNotistack";

interface initialState {
  employees: Array<object>;
  page: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
  loader: boolean;
}
const initialState: initialState = {
  employees: [],
  page: 0,
  pageSize: 100,
  totalRecords: 0,
  totalPages: 0,
  loader: false,
};

const registeredEmployees = createSlice({
  name: "registeredEmployees",
  initialState,
  reducers: {
    getEmployees: (state, action) => {
      const { employees, page, pageSize, totalRecords, totalPages } =
        action.payload;
      state.employees = employees;
      state.page = page;
      state.pageSize = pageSize;
      state.totalRecords = totalRecords;
      state.totalPages = totalPages;
    },
       isLoading(state, action) {
      state.loader = action.payload;
    },
    clearRegisteredEmployees: () => initialState,
  },
});

export const { getEmployees, isLoading, clearRegisteredEmployees } =
  registeredEmployees.actions;

export default registeredEmployees.reducer;



export function fetchRegisteredEmployees(
  page: number,
  pageSize: number,
  companyId: string,
  employeeName?: string,
  businessUnitId?: string
) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/users/registered?page=${page}&pageSize=${pageSize}&companyId=${companyId}&employeeName=${
        employeeName ? employeeName : ""
      }&businessUnitId=${businessUnitId ? businessUnitId : ""}`
    );

    if (response.data) {
      dispatch(
        getEmployees({
          employees: response.data.data,
          page: response.data.page,
          pageSize: response.data.pageSize,
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  };
}

export function deleteEmployeeProfile(staffId: any) {
  return async () => {
    const response = await authApi.delete(`/v2/api/users/${staffId}`);
    if (response.data) {
      toast(response.data.message, { variant: "success" });
    }
  };
}


