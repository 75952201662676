import React, { useCallback, useEffect, useReducer, useRef } from "react";
import {
  Table as TableView,
  Paper,
  TableContainer,
  Grid,
  Box,
  Button,
  Divider,
  TableBody,
  TableRow,
  Typography,
  styled,
  TableCell,
  InputBase,
  alpha
} from "@mui/material";
import UnregisteredEmployees from "./UnregisteredEmployees";
import MainCard from "components/MainCard";
import {
  fetchUnregisteredEmployees,
  loadUnregisteredEmployees,
  inviteUnregistered as inviteUnregisteredEmployees,
  sendBulkInvitationReminder,
  deleteUnregisteredUser
} from "store/reducers/unRegisteredEmployees";
import { dispatch, useSelector } from "store";
import ScrollX from "components/ScrollX";
import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import { Link } from "react-router-dom";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import { checkSalaryWag, getScheduleName, getStatus } from "utils/employees";
// import { format } from "date-fns";
import authApi from "interceptor/authInterceptor";
// import Papa from "papaparse";
// import { exportData } from "utils/CommonMethods";
import toast from "utils/ToastNotistack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CashDDialog from "components/common/CashDDialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { StyledTitleTypography } from "pages/CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import T from "utils/constants/T";
import * as XLSX from "xlsx";
import TableHeader from "pages/DeductionScreen/TableHeader";
import MasterPagination from "utils/MasterPagination";
import SearchIcon from "@mui/icons-material/Search";
import { SendOutlined } from "@ant-design/icons";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import { fetchDeductionTransactionBusinessUnitList } from "store/reducers/deductionTransactionFilter";
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // width: '100%',
  border: "1px solid #d9d9d9",
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 10px 8px 10px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1,
}));

const INVITE_ALL_EMPLOYEE = [
  { label: "First Name" },
  { label: "Last Name" },
  { label: "Payroll Employee ID" },
  { label: "Business Unit" },
  { label: "Mobile Phone" },
  { label: "Email" },
  { label: "Employer ID" },
];

const UnregisteredEmployeesPage = () => {

  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch)
  const companyId = get(selectedCompany, "_id", "");
  const unregisteredEmployees = useSelector((state) => state.unregisteredEmployees)
  const { businessUnitList } = useSelector((state: any) => state.deductionTransactionFilter)
  const { systemCode } = useSelector((state: any) => state.companyTabData);
  const { employees, page, pageSize, totalRecords, totalPages } = unregisteredEmployees;
  // const { INITIAL_PAGE, ROWS_PER_PAGE, EXPORT_PAGE_SIZE } = PAGINATION;
  const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
  console.log(systemCode,"SYSTEMCODE")

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      pageNumber: page,
      rowsPerPage: pageSize,
      openLenderChangeConfirmationModal: false,
      openModal: false,
      totalModalRecords: totalRecords,
      totalPages: totalPages,
      totalFetchedRecords: 0,
      totalFetchedPages: 0,
      employeeName: "",
      paginatedJsonData: [],
      fileUploaded: false,
      selectedCheckBoxes: new Set(),
      selectedEmployeeId: "", // when action button dropdown is clicked, a employee id is selcted for actions,
      filesRecords: [],
      validFileRecords: [],
      businessUnit: null
    }
  );

  const {
    openLenderChangeConfirmationModal,
    openModal,
    rowsPerPage,
    totalFetchedRecords,
    totalFetchedPages,
    employeeName,
    paginatedJsonData,
    fileUploaded,
    selectedCheckBoxes,
    selectedEmployeeId,
    filesRecords,
    validFileRecords,
    businessUnit
  } = localState;

  const isCheckBoxSelected = (emloyeeId: string) => {
    return selectedCheckBoxes.has(emloyeeId)
  }

  // console.log('selectedCheckBoxes', selectedCheckBoxes)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const selectAllCheckboxRef = useRef<HTMLInputElement | null>(null);
  const inviteEmployeeFileRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, employeeId: string) => {
    setAnchorEl(event.currentTarget);
    setLocalState({ selectedEmployeeId: employeeId });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (page: number) => {
    setLocalState({ page: page });
    dispatch(
      fetchUnregisteredEmployees(page, rowsPerPage, companyId, employeeName, businessUnit?._id)
    );
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
    dispatch(
      fetchUnregisteredEmployees(INITIAL_PAGE, value, companyId, employeeName, businessUnit?._id)
    );
  };

  const handleLenderChangeConfirmationModal = async () => {

    let payloadData = [];

    for (let i = 0; i < validFileRecords.length; i++) {
      payloadData.push({
        first_name: validFileRecords[i][0],
        last_name: validFileRecords[i][1],
        system_employee_id: validFileRecords[i][2],
        business_unit: validFileRecords[i][3],
        mobile: validFileRecords[i][4],
        email: validFileRecords[i][5],
      });
    }

    let payloadBody = {
      companyId: companyId,
      system_employees_list: payloadData,
    };

    await dispatch(loadUnregisteredEmployees(payloadBody))
    await dispatch(fetchUnregisteredEmployees(INITIAL_PAGE, rowsPerPage, companyId, employeeName, businessUnit?._id))

    setLocalState({ openLenderChangeConfirmationModal: !openLenderChangeConfirmationModal })
  };

  const handleModalClickButton = () => {
    setLocalState({ fileUploaded: false, openModal: true, openLenderChangeConfirmationModal: false })
  };

  const handleButtonClick = () => {
    setLocalState({ openModal: !openModal, openLenderChangeConfirmationModal: !openLenderChangeConfirmationModal });
  };

  // const fetchEmployees: any = async (
  //   page: number,
  //   pageSize: number,
  //   companyId: string
  // ) => {
  //   // fetch unregistered employees
  //   const response = await authApi.get(
  //     `/v2/api/users/unregistered?page=${page}&pageSize=${pageSize}&companyId=${companyId}`
  //   );
  //   const { data: employees, totalPages, page: currentPage } = response.data;

  //   // check for page size, if current page is equal or greater than totalPages, then return
  //   if (currentPage + 1 >= totalPages) return employees;

  //   // recursiverly fetch all unregisteredEmployees from fetchemployees method and return
  //   const nextPage = await fetchEmployees(page + 1, pageSize, companyId);
  //   return [...employees, ...nextPage];
  // };

  // const formattedExportData = (employees: any) => {
  //   const formattedData: any = [];

  //   employees.forEach((employee: any) => {
  //     const schedule = getScheduleName(
  //       employee,
  //       get(employee, "companies[0].systems.code", "")
  //     );
  //     const registrationDate = get(employee, "created_date", "");
  //     const date = new Date(registrationDate);
  //     let status = getStatus(parseInt(employee.is_active));
  //     const registrationDateText = format(date, "dd MMM yyyy h:mm a");

  //     formattedData.push({
  //       companyName: get(employee, "companies[0].company_name", ""),
  //       firstName: get(employee, "first_name", ""),
  //       lastName: get(employee, "last_name", ""),
  //       payrollID: get(employee, "system_employee_id", ""),
  //       payType: checkSalaryWag(
  //         get(employee, "salary_wag", ""),
  //         get(employee, "companies[0].systems.code", "")
  //       ),
  //       schedule: schedule?.payPeriodName,
  //       status: status.status,
  //       registrationDate: registrationDateText,
  //     });
  //   });

  //   return formattedData;
  // };

  // const handleCSVExport = async () => {
  //   try {
  //     const employees = await fetchEmployees(
  //       INITIAL_PAGE,
  //       EXPORT_PAGE_SIZE,
  //       companyId
  //     );
  //     const finalExportData = formattedExportData(employees);
  //     const csvData = Papa.unparse(finalExportData);
  //     exportData(csvData, "data.csv", "text/csv;charset=utf-8;");
  //   } catch (error) {
  //     toast(get(error, "message", ""), { variant: "error" });
  //   }
  // };

  const handleNullish = useCallback((json: string[] | unknown) => {
    const parsed = JSON.parse(JSON.stringify(json));
    // Null value converted to string
    const updatedJson = parsed.map((row: string[]) =>
      row.map((cell: string) => cell ?? "")
    );
    // Remove empty arrays
    let filteredData = updatedJson.filter(
      (subarray: string[]) => subarray.length > 0
    );
    return filteredData;
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    // console.log('file data', file)

    // set file upload button status if file is selected
    if (file) setLocalState({ fileUploaded: true })
    else setLocalState({ fileUploaded: false })

    if (file) {
      // console.log('in here for file')

      const reader = new FileReader();
      // console.log('reader', reader);

      reader.onload = (e: any) => {

        // console.log('in onload method')

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // remove the 0th index from array
        json.shift()

        // filter the fields
        json = json.filter((item: any) => {
          if ((item[0] && item[0] !== "") || (item[1] && item[1] !== "") || item[5] && item[5] !== "") return true
          return false
        })

        // console.log('json records', json)
        // console.log('json records length', json.length)

        // console.log('flierecords json', json)

        const updatedJson = handleNullish(json)
        setLocalState({ filesRecords: updatedJson });

        let ogData = [];

        const validatData = (item: any): boolean => {

          let emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

          if (item[0] && item[0] !== "" && item[1] && item[1] !== "" && item[5] && item[5] !== "" && emailRegEx.test(item[5])) {
            return true
          }
          else {
            return false
          }

        }

        for (let i = 0; i < json.length; i++) {
          // console.log('in loop')
          if (validatData(json[i])) {
            ogData.push(json[i])
          }
          else {
            // console.log('not valid')
          }
        }

        console.log('ogData', ogData)

        const totalData = ogData.length;
        const totalDataPages = Math.ceil(totalData / rowsPerPage);
        setLocalState({
          totalFetchedRecords: totalData,
          totalFetchedPages: totalDataPages,
        });

        // console.log('ogData', ogData)

        setLocalState({ validFileRecords: ogData })

        // console.log('validFileRecords', validFileRecords)
      }

      reader.readAsArrayBuffer(file);
    };

  }

  const handlePageModalChange = (newPage: number) => {
    setLocalState({ pageNumber: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleRowsPerPageModalChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ pageNumber: INITIAL_PAGE, rowsPerPage: value });
  };

  // clear filter values if company is changed
  useEffect(() => {
    setLocalState({ businessUnit: null })
  }, [companyId])

  useEffect(() => {

    // fetch unreg. employees
    dispatch(fetchUnregisteredEmployees(INITIAL_PAGE, ROWS_PER_PAGE, companyId, employeeName, businessUnit?._id))

    // fetch business units if astute company
    if (companyId && systemCode == T.ASTUTE.toUpperCase()) dispatch(fetchDeductionTransactionBusinessUnitList(companyId))

  }, [companyId, businessUnit, systemCode])
  console.log(systemCode, "SYSTEMCODE")

  useEffect(() => {
    // console.log('in useeffct for paginated json')
    // console.log('validFileRecords', validFileRecords)
    const paginatedJson = handleNullish(validFileRecords).slice(
      localState.pageNumber * localState.rowsPerPage,
      localState.pageNumber * localState.rowsPerPage + localState.rowsPerPage
    );
    // console.log('paginatedJson in same', paginatedJson)

    setLocalState({ paginatedJsonData: paginatedJson });
  }, [openModal, localState.pageNumber, localState.rowsPerPage, validFileRecords]);

  useEffect(() => {
    const totalDataPages = Math.ceil(totalFetchedRecords / rowsPerPage);
    setLocalState({ totalFetchedPages: totalDataPages });
  }, [localState.rowsPerPage]);

  let debounceTimer: any = null;
  const handleEmployeeNameSearch = (employeeName: any) => {
    const { name, value } = employeeName.target;
    setLocalState({ [name]: value })
    clearTimeout(debounceTimer);

    if (value?.length >= 3 || value?.length === 0) {
      debounceTimer = setTimeout(() => {
        dispatch(fetchUnregisteredEmployees(INITIAL_PAGE, ROWS_PER_PAGE, companyId, value, businessUnit?._id))
      }, 500)
    }
  }
  const onHanleAllSelect = (e: any) => {
    employees.map((emp: any) => ({ ...emp, selected: e.target.checked }))
  }

  const handleRowCheckBoxClick = (employeeId: string) => {
    if (selectedCheckBoxes.has(employeeId)) selectedCheckBoxes.delete(employeeId)
    else selectedCheckBoxes.add(employeeId)

    setLocalState({ selectedCheckBoxes: selectedCheckBoxes })
  }

  const inviteMultipleUnregistered = async () => {

    // uncheck all checkboxes
    setLocalState({ selectedCheckBoxes: new Set() })

    await dispatch(inviteUnregisteredEmployees({
      companyId: companyId,
      employeeIds: [...selectedCheckBoxes]
    }))

  }

  const inviteUnregisteredEmployee = async () => {

    await dispatch(inviteUnregisteredEmployees({
      companyId: companyId,
      employeeIds: [selectedEmployeeId]
    }))

    dispatch(fetchUnregisteredEmployees(page, pageSize, companyId, employeeName, businessUnit?._id))

  }

  const selectAllEmployees = () => {

    // console.log('employees', employees)

    if (!selectAllCheckboxRef?.current) return setLocalState({ selectedCheckBoxes: new Set() })
    if (!selectAllCheckboxRef?.current?.checked) return setLocalState({ selectedCheckBoxes: new Set() })

    const employeeIds = employees.map((employee: any) => employee._id)
    setLocalState({ selectedCheckBoxes: new Set(employeeIds) })
  }

  const handleModal2Close = () => {
    setLocalState({ openLenderChangeConfirmationModal: false, openModal: false })
  }

  const handleModal1Close = () => {
    setLocalState({ openLenderChangeConfirmationModal: false, openModal: false })
  }

  const sendInvitationReminder = async () => {
    const response = await authApi.post(`/v2/api/users/${selectedEmployeeId}/invitation/sendReminder`)
    console.log('response from sendreminder', response);
    if (response?.data?.code) toast(get(response, "data.message", ""), { variant: "success" });
  }

  
  const deleteUserProfile = async () => {

    const isConfirmed = confirm('Are you sure you want to delete this user profile?')
    if (isConfirmed) {
        await dispatch(deleteUnregisteredUser(selectedEmployeeId))
        await dispatch(fetchUnregisteredEmployees(page, pageSize, companyId, employeeName, businessUnit?._id))
        handleClose()
    }
}

  const onHandleBusinessUnitChange = (value: string) => {
    setLocalState({ businessUnit: value })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>

              {/* filter on left side */}
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box>
                  <Button
                    component={Link}
                    to="/employees/registered"
                    variant="text"
                  >
                    Registered
                  </Button>
                </Box>
                <Box>
                  <Button variant="contained">Unregistered</Button>
                </Box>
                <Box sx={{ marginLeft: '20px' }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder={T.EMPLOYEE}
                      name="employeeName"
                      value={employeeName}
                      inputProps={{ "aria-label": "search" }}
                      onChange={handleEmployeeNameSearch}
                    />
                  </Search>
                </Box>
                {companyId && systemCode == T.ASTUTE.toUpperCase() && (
                  <Box>
                    <CashDAutocomplete
                      placeholder={T.BUSINESS_UNIT}
                      listDetails={businessUnitList}
                      value={businessUnit}
                      getByLabelText={(option: any) =>
                        `${option.name}`
                      }
                      onHandleChange={(event: any, newValue: any) =>
                        onHandleBusinessUnitChange(newValue)
                      }
                      fullWidth
                      width="200px"
                    />
                  </Box>
                )}

              </Box>

              {/* action buttons on right side   */}
              <Box>
                <Button
                  startIcon={<SendOutlined />}
                  variant="contained"
                  sx={{ marginRight: "4px" }}
                  disabled={!selectedCheckBoxes.size}
                  onClick={inviteMultipleUnregistered}
                >
                  Invite
                </Button>
                <Button
                  startIcon={<SendOutlined />}
                  variant="contained"
                  sx={{ marginRight: "4px" }}
                  disabled={!selectedCheckBoxes.size}
                  onClick={() => dispatch(sendBulkInvitationReminder(Array.from(selectedCheckBoxes)))}
                >
                  Send Reminder
                </Button>
                {/* <Button
                  startIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                  onClick={handleCSVExport}
                  disabled={!totalRecords ? true : false}
                  sx={{ marginRight: "4px" }}
                >
                  Export
                </Button> */}
                {(systemCode == T.ASTUTE.toUpperCase() || systemCode == T.MYOBADVANCED.toUpperCase() )&&
                <Button
                  component="label"
                  variant="contained"
                  onClick={handleModalClickButton}
                  disabled={!companyId}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                </Button>
                }

                {/* first modal box for uploading file */}
                <CashDDialog
                  handleClose={handleModal1Close}
                  open={openModal}
                >
                  <Box overflow="hidden">
                    <TableContainer
                      component={Paper}
                      // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                      sx={{ height: "inherit", overflowY: "auto" }}
                    >
                      <StyledTitleTypography variant="h1" noWrap>
                        {T.CASHD}
                      </StyledTitleTypography>
                      <Divider />
                      <Box className="modal-buttons">
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          {/* <CloseIcon /> */}
                        </IconButton>
                        <DialogContent dividers>
                          <input
                            type="file"
                            accept=".xlsx , .xls, .csv"
                            onChange={handleFileChange}
                            ref={inviteEmployeeFileRef}
                          />
                        </DialogContent>
                        <Button
                          variant="contained"
                          onClick={handleButtonClick}
                          disabled={!fileUploaded}
                        >
                          {T.SUBMIT}
                        </Button>
                      </Box>
                    </TableContainer>
                  </Box>
                </CashDDialog>

                {/* second modal box for listing employees from file */}
                <CashDDialog
                  handleClose={handleModal2Close}
                  open={openLenderChangeConfirmationModal}
                >
                  {/* <Box overflow="hidden"> */}
                  <StyledTitleTypography style={{ paddingBottom: "20px" }}>
                    {T.EMPLOYEES_TO_UPLOAD}
                  </StyledTitleTypography>

                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Typography>
                        Total Records Found: {filesRecords.length}
                      </Typography>
                      <Typography>
                        Total Valid Records: {validFileRecords.length}
                      </Typography>
                    </Grid>
                  </Grid>
                  <TableContainer
                    component={Paper}
                    // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                    sx={{
                      maxHeight: "calc(100vh - 400px)",
                      height: "inherit",
                      "& .MuiTableCell-root:first-of-type ": {
                        paddingLeft: "0px",
                      },
                    }}
                  >
                    {/* <Divider /> */}
                    <TableView stickyHeader>
                      <TableHeader
                        padding={"8px 10px 8px 10px"}
                        columns={INVITE_ALL_EMPLOYEE}
                      />
                      <TableBody>
                        {paginatedJsonData.map((row: any, rowIndex: number) => (
                          <TableRow key={rowIndex}>
                            {row.map((cell: any, cellIndex: number) => (
                              <StyledTableCell key={cellIndex}>
                                {cell}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                      {paginatedJsonData && paginatedJsonData.length === 0 && (
                        <TableRow>
                          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
                            <Typography variant="body1" textAlign="center">
                              {T.NO_RECORDS}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </TableView>
                  </TableContainer>
                  <Button
                    variant="contained"
                    sx={{ float: "right", marginTop: "10px" }}
                    onClick={handleLenderChangeConfirmationModal}
                  >
                    {T.UPLOAD}
                  </Button>
                  <MasterPagination
                    page={localState.pageNumber}
                    onPageChange={handlePageModalChange}
                    rowsPerPage={localState.rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPageModalChange}
                    totalTableRowsCount={totalFetchedRecords}
                    totalPageCount={totalFetchedPages}
                  />

                  {/* </Box> */}
                </CashDDialog>
              </Box>

            </Box>
            <ScrollX>
              <UnregisteredEmployees
                employees={employees}
                page={page}
                pageSize={pageSize}
                totalRecords={totalRecords}
                totalPages={totalPages}
                open={open}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                handleAllSelected={onHanleAllSelect}
                isCheckBoxSelected={isCheckBoxSelected}
                handleRowCheckBoxClick={handleRowCheckBoxClick}
                inviteUnregisteredEmployee={inviteUnregisteredEmployee}
                selectAllEmployees={selectAllEmployees}
                selectAllCheckboxRef={selectAllCheckboxRef}
                selectedCheckBoxes={selectedCheckBoxes}
                sendInvitationReminder={sendInvitationReminder}
                deleteUserProfile={deleteUserProfile}
                systemCode={systemCode}
              />
            </ScrollX>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );

}

export default UnregisteredEmployeesPage;
