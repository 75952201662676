import { useEffect, useReducer } from "react";
import { Box, Tabs, Tab, styled } from "@mui/material";
import { IdcardOutlined } from '@ant-design/icons';
// import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import T from "utils/constants/T";

import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { get } from "lodash";
import { useSelector } from 'store';
import CompanyIcon from "assets/images/icons/company";
import FinancialSettingsIcon from "assets/images/icons/financialSettingIcon";
import PayrollSettingsIcon from "assets/images/icons/payrollSettingsIcon";
import BankingSettingsIcon from "assets/images/icons/bankingSettingsIcon";
import WhitelabellingSettingIcon from "assets/images/icons/whitelabellingSettingsIcon";
import EmailSettingsIcon from "assets/images/icons/emailSettingsIcon";

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "16px",
  alignItems: "center",
  justifyContent: "flex-start",
  color: "black",
  minHeight: "unset",
  backgroundColor: "white"
  //   border: `1px solid gray`,
}));

const CompanyDetailsPage = () => {
  let { selectedCompany } = useSelector(state => state.dashboardSearch);
  const selectedCompanyId = get(selectedCompany, "_id", "");
  let selectedTab = 0;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      value: selectedTab,
    }
  );

  const {
    value,
  } = localState;

  useEffect(() => {
    navigate('/companyDetails/company/view');
    if (pathname === '/companyDetails/company/view') {
      setLocalState({
        value: 0
      });
    }
  }, [selectedCompanyId])
  switch (pathname) {
    case '/companyDetails/emailSettings':
      selectedTab= 6;
      break;
    case '/companyDetails/whiteLabelling/view':
      selectedTab = 5;
      break;
    case '/companyDetails/whiteLabelling/edit':
      selectedTab = 5;
      break;
    case '/companyDetails/bankingSettings/view':
      selectedTab = 4;
      break;
    case '/companyDetails/bankingSettings/edit':
      selectedTab = 4;
      break;
    case '/companyDetails/payrollSettings/view':
      selectedTab = 3;
      break;
    case '/companyDetails/payrollSettings/edit':
      selectedTab = 3;
      break;
    case '/companyDetails/financialSettings/edit':
      selectedTab = 2;
      break;
    case '/companyDetails/financialSettings/view':
      selectedTab = 2;
      break;
    case '/companyDetails/kyb/edit':
      selectedTab = 1;
      break;
    case '/companyDetails/kyb/view':
      selectedTab = 1;
      break;
    case '/companyDetails/company/edit':
      selectedTab = 0;
      break;
    case '/companyDetails/company/view':
    default:
      selectedTab = 0;
  }



  const handleTabChange = (event: any, newValue: number) => {
    setLocalState({
      value: newValue
    });
  };


  return (

    <MainCard pageHeading={T.SETTINGS} border={false} boxShadow>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', backgroundColor: "white" }}>
        <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">

          <Tab icon={<CompanyIcon />} iconPosition="start" component={Link} to="/companyDetails/company/view" label={T.COMPANY} />
          <Tab icon={<IdcardOutlined />} iconPosition="start" component={Link} to="/companyDetails/kyb/view" label={T.KYB} />
          <Tab icon={<FinancialSettingsIcon />} iconPosition="start" component={Link} to="/companyDetails/financialSettings/view" label={T.FINANCIAL} />
          <Tab icon={<PayrollSettingsIcon />} iconPosition="start" component={Link} to="/companyDetails/payrollSettings/view" label={T.PAYROLL} />
          <Tab icon={<BankingSettingsIcon />} iconPosition="start" component={Link} to="/companyDetails/bankingSettings/view" label={T.BANKING} />
          <Tab icon={<WhitelabellingSettingIcon /> }  iconPosition="start" component ={Link} to="/companyDetails/whiteLabelling/view" label={T.BRAND} />
          <Tab icon={<EmailSettingsIcon /> }  iconPosition="start" component ={Link} to="/companyDetails/emailSettings/edit" label={T.EMAIL} />
        </Tabs>
      </Box>
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        <Outlet />
      </Box>
    </MainCard>
  );
};

export default CompanyDetailsPage;
