import { Box } from '@mui/material'
const CompanyIcon = () => {
    return (
        <Box sx={{ marginRight: '8px' }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2298_1380)">
                    <path d="M2.33333 7.58333H4.08333V8.75H2.33333V7.58333ZM5.25 8.75H7V7.58333H5.25V8.75ZM2.33333 11.0833H4.08333V9.91667H2.33333V11.0833ZM5.25 11.0833H7V9.91667H5.25V11.0833ZM2.33333 4.08333H4.08333V2.91667H2.33333V4.08333ZM5.25 4.08333H7V2.91667H5.25V4.08333ZM2.33333 6.41667H4.08333V5.25H2.33333V6.41667ZM5.25 6.41667H7V5.25H5.25V6.41667ZM14 4.66667V14H0V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L7.58333 0C8.04746 0 8.49258 0.184374 8.82077 0.512563C9.14896 0.840752 9.33333 1.28587 9.33333 1.75V2.91667H12.25C12.7141 2.91667 13.1592 3.10104 13.4874 3.42923C13.8156 3.75742 14 4.20254 14 4.66667ZM8.16667 1.75C8.16667 1.59529 8.10521 1.44692 7.99581 1.33752C7.88642 1.22812 7.73804 1.16667 7.58333 1.16667H1.75C1.59529 1.16667 1.44692 1.22812 1.33752 1.33752C1.22812 1.44692 1.16667 1.59529 1.16667 1.75V12.8333H8.16667V1.75ZM12.8333 4.66667C12.8333 4.51196 12.7719 4.36358 12.6625 4.25419C12.5531 4.14479 12.4047 4.08333 12.25 4.08333H9.33333V12.8333H12.8333V4.66667ZM10.5 8.75H11.6667V7.58333H10.5V8.75ZM10.5 11.0833H11.6667V9.91667H10.5V11.0833ZM10.5 6.41667H11.6667V5.25H10.5V6.41667Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_2298_1380">
                        <rect width="14" height="14" fill="currentColor" />
                    </clipPath>
                </defs>
            </svg>
        </Box>

    )
}

export default CompanyIcon