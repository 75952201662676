import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { dispatch } from "store";

const initialState = {
  timesheet: [],
  page: 0,
  pageSize: 100,
  totalRecords: 0,
  totalPages: 0,
};

const timesheet = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    getTimesheet: (state, action) => {
      const { timesheet, page, pageSize, totalRecords, totalPages } =
        action.payload;
      state.timesheet = timesheet;
      state.page = page;
      state.pageSize = pageSize;
      state.totalRecords = totalRecords;
      state.totalPages = totalPages;
    },
    clearTimesheet: (state) => {
      return initialState;
    },
  },
});

export const { getTimesheet, clearTimesheet } = timesheet.actions;

export default timesheet.reducer;

export function fetchTimesheet(
  page: number,
  pageSize: number,
  companyId: string,
  employeeName?:string
) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/timesheet?page=${page}&pageSize=${pageSize}&employeeName=${employeeName? employeeName: ""}`
    );
    if (response.data) {
      dispatch(
        getTimesheet({
          timesheet: response.data.data,
          page: response.data.page,
          pageSize: response.data.pageSize,
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  };
}
