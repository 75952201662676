import { Box, Divider, Button } from "@mui/material";
import CashDDialog, { DialogStatementTypography } from "components/common/CashDDialog";
import T from "utils/constants/T";
import "./viewDeduction.css";
import { noop } from "lodash";


interface ReverseDeductionProps{
    openReverseDeductionDialog?: boolean
    handleReverseDeductionDailog?: any
    reverseSelectedDeductions?: any
}
const ReverseDeduction = ({
    openReverseDeductionDialog= false,
    handleReverseDeductionDailog= noop,
    reverseSelectedDeductions= noop,
    
}:ReverseDeductionProps) => {
    return (
        <CashDDialog
            open={openReverseDeductionDialog}
        >
            <DialogStatementTypography>
                {T.ARE_YOU_SURE_YOU_WANT_TO_UNDO_DEDUCTIONS_OF_THIS_PAY_PERIOD}
            </DialogStatementTypography>
            <Divider />
            <Box className="modal-buttons">
                <Button 
                    variant="outlined" 
                    onClick={handleReverseDeductionDailog}
                >
                    {T.CANCEL}
                </Button>
                <Button 
                    variant="contained"
                    onClick={reverseSelectedDeductions}
                >
                    {T.CONFIRM}
                </Button>
            </Box>
        </CashDDialog>
    );
};

export default ReverseDeduction;