//Local-Imports
import { maskNumber } from "utils/CommonMethods";
import ViewBankingTable from "./ViewBankingTable";



interface ViewBankingDetailsProps {
    accountName?:string,
    fundingType?: string,
    nameOfBank?: string,
    bsb?: string,
    accountNumber?: string,
    balance?: string,
    interestRate?: string
  }


const ViewBankingDetails = ({
    accountName= "",
    fundingType= "",
    nameOfBank= "",
    bsb= "",
    accountNumber= "",
    balance= "",
    interestRate= ""
}:ViewBankingDetailsProps) => {
  return (
    <ViewBankingTable
        accountName={accountName}
        fundingType={fundingType}
        nameOfBank={nameOfBank}
        bsb={bsb}
        accountNumber={maskNumber(accountNumber)}
        balance={balance}
        interestRate={interestRate}
    />
  );
};

export default ViewBankingDetails;
