import { Table as TableView, Box, Card, Grid, Paper, TableContainer } from "@mui/material"
import TableHeader from "pages/TransactionScreeen/TableHeader"
// import MasterPagination from "utils/MasterPagination"
import employeesTableHeader from "utils/constants/employees"
import RegisteredEmployeesBody from "./RegisteredEmployeesBody"
import MasterPagination from "utils/MasterPagination"

interface RegisteredEmployeesProps {
    employees: any,
    page: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number,
    open: boolean,
    anchorEl: any
    handleClick: any,
    handleClose: any,
    onPageChange: any,
    onRowsPerPageChange: any
    companyId: string
    systemCode: string
}

const RegisteredEmployees = ({
    employees,
    page,
    pageSize,
    totalRecords,
    totalPages,
    open,
    anchorEl,
    handleClick,
    handleClose,
    onPageChange,
    onRowsPerPageChange,
    companyId,
    systemCode
}: RegisteredEmployeesProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        // p: "0px 8px",
                        p: "0px 0px",
                        mt: 1.5,
                    }}
                >
                    <Box overflow="hidden">
                        <TableContainer
                            component={Paper}
                            // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                            sx={{ height: "inherit", overflowY: "auto" }}
                        >
                            <TableView stickyHeader>
                                <TableHeader columns={employeesTableHeader(companyId, systemCode)} />
                                <RegisteredEmployeesBody
                                    allTableRows={employees}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    totalTableRowsCount={totalRecords}
                                    totalPageCount={totalPages}
                                    opan={open}
                                    anchorEl={anchorEl}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    companyId={companyId}
                                    systemCode={systemCode}
                                />
                            </TableView>
                        </TableContainer>
                    </Box>

                    <MasterPagination
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onRowsPerPageChange}
                        totalTableRowsCount={totalRecords}
                        totalPageCount={totalPages}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default RegisteredEmployees