//React
import { useEffect, useReducer } from 'react';

//material UI
import { Button } from '@mui/material';

//react-router-dom
import { useNavigate } from 'react-router-dom';

//local imports
import T from 'utils/constants/T';
import EditCompanyInfo from './EditCompanyinfo';
import MainCard from 'components/MainCard';
import Loader from 'components/Loader';
import { saveCompanyTabData } from 'store/reducers/companyTabDetails';

//react-redux
import { useDispatch, useSelector } from 'react-redux';

//lodash
import { get } from 'lodash';


//CSS
import 'assets/css/custom.css';
import '../../companyDetails.css';
import { isNotANumber } from 'utils/constants/validations';


const EditCompanyDetails = () => {
    const navigate =useNavigate();
    const navigator=(path:string)=>{
        navigate(path)
      }
    const dispatch= useDispatch();
    const  { selectedCompany } = useSelector((state:any)=>state.dashboardSearch);
    const  { companyName, contactPerson:contactPersonName, companyEmail, companyPhone,  systemName, city:cityName, abn: abnName, loader } = useSelector((state:any)=>state.companyTabData);
    
    
    const [localState, setLocalState] =useReducer(
        (prevState:any,newState:any)=>({...prevState, ...newState}),
        {
            contactPerson: "",
            payrollSystem: "",
            email:"",
            attendanceSystem:"",
            phoneNumber:"",
            city:"",
            abn:""
        }
    )
    
    const { 
        contactPerson,
        payrollSystem,
        email,
        attendanceSystem,
        phoneNumber,
        city,
        abn
    } = localState;

    useEffect(()=>{
        setLocalState({
            contactPerson: contactPersonName,
            payrollSystem: systemName,
            email: companyEmail,
            phoneNumber: companyPhone,
            attendanceSystem: systemName,
            city:cityName,
            abn:abnName
        })
    },[])
    
    const onHandleChange =(e:any) =>{
        const {name, value} =e.target;
        if(name==="contactPerson")
        {
            if(isNotANumber(value))
            {
                setLocalState({[name]: value})        
            }
        }
        else
        {
            setLocalState({[name]: value});
        }
    }

    const renderCompanyInfo =()=>({
        contactPerson,
        payrollSystem,
        email,
        attendanceSystem,
        phoneNumber,
        city,
        abn,
        onHandleChange
    })
    
    const handleSave =()=>{
        const payload ={
            contact_person:contactPerson,
            abn: abn,
            city: city,
            phone_company: phoneNumber,
            email_company: email
        }
        dispatch(saveCompanyTabData(get(selectedCompany,"_id",""), payload, navigator))
    };
   
    return (<>
    {
        loader && <Loader />
    }

    <MainCard
        title={companyName}
        secondary={
            <Button onClick={handleSave} variant='contained' className='main-card-button'>
                {T.SAVE}
            </Button>
        }
    >
        <EditCompanyInfo {...renderCompanyInfo()}/>

    </MainCard>
    
    </>
    );
};

export default EditCompanyDetails;