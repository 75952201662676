//Material-UI
import {
    Table as TableView,
    Paper,
    TableContainer,
    Box,
    Card,
  } from "@mui/material";
  
  
  //Local imports
  import { EDIT_BANKING_TABLE_HEADER } from "utils/constants/companyDetails";
import EditBankingTableBody from "./EditBankingTableBody";
import { noop } from "lodash";
import EditBankingTableHeader from "./EditBankingTableHeader";
  
  interface EditBankingTableProps {
      allTableRows?: any[],
      handleAddChangeLender?: (id: string,accountName: string)=>void,
  }
  const EditBankingTable = ({
    allTableRows=[],
    handleAddChangeLender=noop
  }:EditBankingTableProps) => {
    return (
      <Card
        elevation={0}
        sx={{
          // p: "0px 8px",
          p: "0px 0px",
          mt: 1.5,
        }}
      >
        <Box overflow="hidden">
          <TableContainer
            component={Paper}
            // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
            sx={{ height: "inherit", overflow: "auto", padding: "0px" }}
          >
            <TableView stickyHeader>
              <EditBankingTableHeader columns={EDIT_BANKING_TABLE_HEADER} />
              <EditBankingTableBody
                allTableRows={allTableRows}
                handleAddChangeLender={handleAddChangeLender}
              />
            </TableView>
          </TableContainer>
        </Box>
      </Card>
    );
  };
  
  export default EditBankingTable;
  