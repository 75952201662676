const Employee = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2164_911)">
        <path
          opacity="0.973"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.42053 2.22512C9.65048 2.16622 11.181 3.16241 12.0121 5.21375C12.5102 6.97834 12.1208 8.5089 10.8439 9.80537C13.4725 10.9494 15.0574 12.9327 15.5985 15.7555C15.6779 16.2967 15.696 16.84 15.6528 17.3856C15.5532 17.6301 15.3811 17.8022 15.1366 17.9018C10.2824 17.9381 5.42809 17.9381 0.573854 17.9018C0.103119 17.6666 -0.0779922 17.2862 0.0304678 16.7607C0.260809 13.4724 1.87287 11.1539 4.86661 9.80537C3.42446 8.30019 3.10745 6.58852 3.91568 4.67036C4.67528 3.2675 5.84356 2.45242 7.42053 2.22512ZM7.36619 4.07264C8.9357 3.94657 9.94097 4.63488 10.382 6.13751C10.5343 7.70817 9.85506 8.71343 8.34428 9.1533C6.77362 9.30561 5.76836 8.62638 5.32849 7.1156C5.1996 5.55228 5.87883 4.53794 7.36619 4.07264ZM7.58354 10.9736C10.4208 11.0329 12.4042 12.3551 13.5336 14.9404C13.6762 15.3476 13.7758 15.7641 13.8325 16.1902C9.84767 16.1902 5.8628 16.1902 1.87798 16.1902C2.36268 13.5771 3.88417 11.9016 6.44243 11.1638C6.82867 11.0874 7.20904 11.024 7.58354 10.9736Z"
          fill="currentColor"
        />
        <path
          opacity="0.965"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0486 3.15039C15.8666 3.29406 16.9806 4.25406 17.3904 6.03034C17.559 7.11336 17.2963 8.07331 16.6025 8.91029C18.9264 10.1705 20.0585 12.1266 19.9986 14.7789C19.899 15.0234 19.727 15.1955 19.4824 15.2951C18.6854 15.3313 17.8885 15.3313 17.0915 15.2951C16.6035 15.052 16.4314 14.6626 16.5753 14.1268C16.6749 13.8823 16.847 13.7102 17.0915 13.6106C17.4538 13.5925 17.816 13.5743 18.1783 13.5562C17.7899 11.9724 16.8299 10.9038 15.2984 10.3503C14.8473 10.2301 14.3945 10.1214 13.9399 10.0242C13.3937 9.67826 13.276 9.21638 13.5867 8.6386C13.6713 8.53758 13.7709 8.45607 13.8856 8.39407C15.1247 8.20541 15.6952 7.48993 15.5972 6.24769C15.4536 5.71528 15.1366 5.32589 14.6463 5.07941C14.3537 4.99562 14.0639 4.90504 13.7769 4.80772C13.3717 4.44669 13.2902 4.021 13.5323 3.53076C13.6698 3.34682 13.8419 3.22005 14.0486 3.15039Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2164_911">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Employee;
