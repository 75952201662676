//RTK
import { createSlice } from "@reduxjs/toolkit";

//Lodash
import { dispatch } from "store";
import authApi from "interceptor/authInterceptor";

// types
export type transactionDataProps = {
  payPeriodList: [];
  scheduleList: [];
  businessUnitList: [];
  loader: Boolean;
  error: null;
};

// initial state
const initialState: transactionDataProps = {
  payPeriodList: [],
  scheduleList: [],
  businessUnitList: [],
  loader: false,
  error: null,
};

// ==============================|| SLICE - DEDUCTION_TRANSACTION_FILTERS ||============================== //

const deductionTransactionFilter = createSlice({
  name: "deductionTransactionFilter",
  initialState,
  reducers: {
    getPayPeriodList(state, action) {
      let { payPeriodList } = action.payload;
      state.payPeriodList = payPeriodList || initialState.payPeriodList;
    },
    getScheduleList(state, action) {
      let { scheduleList } = action.payload;
      state.scheduleList = scheduleList || initialState.scheduleList;
    },
    getBusinessUnitList(state, action) {
      let { businessUnitList } = action.payload;
      state.businessUnitList = businessUnitList || initialState.businessUnitList;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearDeductionTransactionsFilter: () => initialState
  },
});

export default deductionTransactionFilter.reducer;

export const { getPayPeriodList, getScheduleList, getBusinessUnitList, clearDeductionTransactionsFilter } = deductionTransactionFilter.actions;



// ==============================|| GET DEDUCTION_TRANSACTION PAY PERIODS LIST ||============================== //

export function fetchDeductionTransactionPayPeriodList(companyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/periods`
    );
    if (response.data) {
      const finalData = response.data.result.payPeriods;
      dispatch(getPayPeriodList({ payPeriodList: finalData }));
    }
  };
}




// ==============================|| GET DEDUCTION_TRANSACTION SCHEDULES LIST ||============================== //

export function fetchDeductionTransactionSchedulesList(companyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/schedules`
    );
    if (response.data) {
      const finalData = response.data.data;
      dispatch(getScheduleList({ scheduleList: finalData }));
    }
  };
}




// ==============================|| GET DEDUCTION_TRANSACTION BUSINESS UNIT LIST ||============================== //

export function fetchDeductionTransactionBusinessUnitList(companyId: string) {

  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/businessUnits`
    );
    if (response.data) {
      const finalData = response.data.data;
      dispatch(getBusinessUnitList({ businessUnitList: finalData }));
    }

  }
}