import { Divider, Grid } from "@mui/material";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
import { format } from "date-fns";
const ViewPayrollDetails = () => {
  
    const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);

    const checkSalaryWag = (salaryWag: any, systemCode: any) => {
        let employeeType;
        if (systemCode === "DEPUTY") {
            employeeType = "Timesheet";
        } else {
            if (salaryWag === 2) {
                employeeType = "Timesheet";
            } else if (salaryWag === 3) {
                employeeType = "Salary & TimeSheet";
            } else {
                employeeType = "Salary";
            }
        }
        return employeeType;
    }

    const date = (getUserDataByUSerID.startDate) ? new Date(getUserDataByUSerID.startDate) : ""
    const formattedStartDate = date ? format(date, "dd MMM yyyy") : ""
  return (
    <Grid item xs={12}>
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.FULL_NAME}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.employeeName}</StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.EMAIL}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.email}</StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.EMPLOYEE_TYPE}</StyledBodyTypography>
            <StyledTitleTypography>{checkSalaryWag(getUserDataByUSerID.employeeType, getUserDataByUSerID.systemCode)}</StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.START_DATE}</StyledBodyTypography>
            <StyledTitleTypography>{formattedStartDate}</StyledTitleTypography>
          </Grid>
          <Grid className="divider" item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.SCHEDULE}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.calenderType}</StyledTitleTypography>
          </Grid>
        </Grid>
      </CashDCard>
    </Grid>
  );
};

export default ViewPayrollDetails;
