//RTK
import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";

//Local imports
import { dispatch } from "store";

// types
export type dashboardSearchProps = {
  searchInput: any;
  companiesList: any[];
  selectedCompany: any;
  loader: Boolean;
  error: null;
};

// initial state
const initialState: dashboardSearchProps = {
  searchInput: "",
  companiesList: ["No results"],
  selectedCompany: {
    company_name: "",
  },
  loader: false,
  error: null,
};

// ==============================|| SLICE - ASTUTE ||============================== //

const dashboardSearch = createSlice({
  name: "dashboardSearch",
  initialState,
  reducers: {
    updateCompanyList(state, action) {
      let { companiesList } = action.payload;
      state.companiesList = companiesList || initialState.companiesList;
    },
    updateSelectedCompany(state, action) {
      let { selectedCompany } = action.payload;
      state.selectedCompany = selectedCompany || initialState.selectedCompany;
    },
    searchData(state, action) {
      let { searchInput } = action.payload;
      state.searchInput = searchInput || initialState.searchInput;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearDashboardSearch: () => initialState,
  },
});

export default dashboardSearch.reducer;

export const {
  updateCompanyList,
  updateSelectedCompany,
  searchData,
  clearDashboardSearch,
} = dashboardSearch.actions;

export function fetchCompanies(search: any) {
  let searchcInput = search ? search : "";
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/listCompanies?search=${searchcInput}`
    );
    if (response.data) {
      dispatch(updateCompanyList({ companiesList: response.data.data }));
    }
  };
}

export function dashboardStats(window: any, companyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/users/dashboardStats?companyId=${companyId}&window=${window}`
    );
    if (response.data) {
      return response.data.data;
    }
  };
}

export function getMonoovaBalance(companyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/linkedLender`
    );
    if (response.data) {
      return response.data.data;
    }
  };
};
