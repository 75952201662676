import { Table as TableView, Box, Card, Grid, Paper, TableContainer } from "@mui/material"
import TableHeader from "pages/TransactionScreeen/TableHeader"
// import MasterPagination from "utils/MasterPagination"
import MasterPagination from "utils/MasterPagination"
import UnregisteredEmployeesBody from "./UnregisteredEmployeesBody"
import unregisteredEmployeesHeader from "utils/constants/unregisteredEmployees"
import { useSelector } from "react-redux"
import { get } from "lodash"

interface UnregisteredEmployeesProps {
    employees: any,
    page: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number,
    open: boolean,
    anchorEl: any
    handleClick: any,
    handleClose: any,
    onPageChange: any,
    onRowsPerPageChange: any,
    handleAllSelected: any,
    isCheckBoxSelected: any,
    handleRowCheckBoxClick: any,
    inviteUnregisteredEmployee: any,
    selectAllEmployees: any,
    selectAllCheckboxRef: any,
    selectedCheckBoxes: any,
    sendInvitationReminder: any,
    deleteUserProfile:any,
    systemCode: string
}

const UnregisteredEmployees = ({
    employees,
    page,
    pageSize,
    totalRecords,
    totalPages,
    open,
    anchorEl,
    handleClick,
    handleClose,
    onPageChange,
    onRowsPerPageChange,
    handleAllSelected,
    isCheckBoxSelected,
    handleRowCheckBoxClick,
    inviteUnregisteredEmployee,
    selectAllEmployees,
    selectAllCheckboxRef,
    selectedCheckBoxes,
    sendInvitationReminder,
    deleteUserProfile,
    systemCode
}: UnregisteredEmployeesProps) => {

    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch)
    const companyId = get(selectedCompany, "_id", "");

    const onChangeAllSelected = (e: any) => {
        handleAllSelected(e)
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card
                    elevation={0}
                    sx={{
                        // p: "0px 8px",
                        p: "0px 0px",
                        mt: 1.5,
                    }}
                >
                    <Box overflow="hidden">
                        <TableContainer
                            component={Paper}
                            // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                            sx={{ height: "inherit", overflowY: "auto" }}
                        >
                            <TableView stickyHeader>
                                <TableHeader
                                    selectAllEmployees={selectAllEmployees}
                                    padding={1}
                                    columns={unregisteredEmployeesHeader(companyId, systemCode)}
                                    onChange={onChangeAllSelected}
                                    selectAllCheckboxRef={selectAllCheckboxRef}
                                    selectedCheckBoxes={selectedCheckBoxes}
                                    employees={employees}
                                />
                                <UnregisteredEmployeesBody
                                    allTableRows={employees}
                                    page={page}
                                    rowsPerPage={pageSize}
                                    totalTableRowsCount={totalRecords}
                                    totalPageCount={totalPages}
                                    opan={open}
                                    anchorEl={anchorEl}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    isCheckBoxSelected={isCheckBoxSelected}
                                    handleRowCheckBoxClick={handleRowCheckBoxClick}
                                    inviteUnregisteredEmployee={inviteUnregisteredEmployee}
                                    sendInvitationReminder={sendInvitationReminder}
                                    deleteUserProfile={deleteUserProfile}
                                    systemCode={systemCode}
                                />
                            </TableView>
                        </TableContainer>
                    </Box>

                    <MasterPagination
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onRowsPerPageChange}
                        totalTableRowsCount={totalRecords}
                        totalPageCount={totalPages}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default UnregisteredEmployees