import Loader from "components/Loader";

const MyobCallback = () => {

    // Get the current page URL
    const currentURL = window.location.href;

    // Parse the URL to extract query parameters
    const url = new URL(currentURL);

    // Extract the value of the "code" query parameter
    const code: any = url.searchParams.get('code');

    const decodedCode = decodeURIComponent(code);

    // Post the code back to the main window
    window.opener.postMessage(`MYOBCODE=${decodedCode}`);

    // close this window
    window.close()

    return (
        <>
            <Loader />
        </>
    )
}

export default MyobCallback