import { Card, CardContent } from '@mui/material';
import "./common.css"
const CashDCard = ({children, borderRadius='', border="1px", minHeight}:{children?:any, borderRadius?: string, border?: string, minHeight?: string}) => {
    return (
        <Card className='cashD-card' sx={{borderRadius:borderRadius,border: `${border} solid #D0D0D0`, minHeight:{minHeight}}} variant='outlined' elevation={0}>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    );
};

export default CashDCard;