import { createSelector } from "reselect";

export const getCurrentTableParams = createSelector(
  [(state) => state],
  (tableData = {}) => {
    const { result = [], totalItem, totalPage } = tableData;

    return {
      allTableRows: result,
      totalTableRowsCount: totalItem,
      totalPageCount: totalPage,
    };
  }
);
