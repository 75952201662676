import noop from "lodash/noop";
import {
  Checkbox,
  Typography,
  Autocomplete,
  AutocompleteRenderOptionState,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WithInputLabel from "./WithInputLabel";
import T from "utils/constants/T";
import CashDTextField from "./CashDTextfield";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Option {
  value: string;
  label: string;
}

interface CashDAutocompleteProps {
  multiple?: boolean;
  label?: any;
  listDetails?: any;
  value?: any;
  placeholder?: string;
  fontWeight?: string;
  width?: string;
  paddingStyle?: string;
  required?: boolean;
  fullWidth?: boolean;
  displayRemoveButton?: string;
  endIcon?: React.ReactNode;
  deprecatedLabel?: boolean;
  disabled?: boolean;
  error?: string;
  filterOptions?: any;
  getByLabelText?: any;
  handleOnChangeValue?: any;
  onHandleChange?(
    event: React.ChangeEvent<{}>,
    newValue: Option[] | Option | null,
    reason: AutocompleteChangeReason
  ): void;
  helperText?: string;
}

const CashDAutocomplete: React.FC<CashDAutocompleteProps> = ({
  multiple,
  label = "",
  listDetails,
  paddingStyle = "",
  fontWeight,
  width = "",
  value,
  placeholder = T.SELECT_OPTION,
  disabled = false,
  required,
  endIcon,
  displayRemoveButton = "inline-flex",
  fullWidth,
  deprecatedLabel = true,
  error = "",
  filterOptions,
  handleOnChangeValue = noop,
  getByLabelText = noop,
  onHandleChange = noop,
  helperText = "",
  ...rest
}) => {


  const renderOption = (
    props: any,
    option: Option,
    state: AutocompleteRenderOptionState
  ) => (
    <li {...props}>
      <Checkbox
        sx={{ p: 0, mr: 0 }}
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={state.selected}
      />
      <Typography>{getByLabelText(option, label)}</Typography>
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <WithInputLabel
      label={deprecatedLabel && label}
      required={required}
      endIcon={endIcon}
      error={error}
    >
      <CashDTextField
        variant="outlined"
        placeholder={placeholder}
        sx={{
          "& .MuiOutlinedInput-root": { p: 0, fontSize: "0.875rem" },
          "& .MuiButtonBase-root": { pt: "5px" },
          "& .MuiAutocomplete-clearIndicator": { display: displayRemoveButton },
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": { pb: paddingStyle },
          "& .MuiAutocomplete-input": { fontWeight: fontWeight },

          width: { width }
        }}
        {...params}
        onChange={handleOnChangeValue}
      />
    </WithInputLabel>
  );

  return (
    <Autocomplete
      multiple={multiple}
      options={listDetails}
      fullWidth={fullWidth}
      filterOptions={filterOptions}
      disableCloseOnSelect={multiple ? true : false}
      disabled={disabled}
      size="small"
      value={value}
      getOptionLabel={(option) => getByLabelText(option, label)}
      onChange={(event, newValue, reason) => onHandleChange(event, newValue, reason)}
      {...rest}
      //   helperText={helperText}
      renderOption={multiple ? renderOption : undefined}
      renderInput={renderInput}
    />
  );
};

export default CashDAutocomplete;