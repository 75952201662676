import { Children } from "react";
import {
  TableRow,
  TableHead,
  TableCell,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// import { BACKGROUND } from "theme/colors";
import { memo } from "utils/memo";
import { get } from "lodash";

const TableHeader = ({ columns = [], onChange = null, selectAllEmployees, selectAllCheckboxRef, selectedCheckBoxes, employees }: {
  columns: any,
  onChange: any,
  selectAllEmployees: any,
  selectAllCheckboxRef: any,
  selectedCheckBoxes: any,
  employees: any,
}) => {

  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          columns.map((column: any) => {
            return (
              <TableCell
                align={get(column, "align", "")}
                sx={{
                  border: "none",
                  textTransform: "capitalize",
                  // backgroundColor: BACKGROUND.cardDefault,
                  padding: 1,
                }}
              >
                {column.type === "text" ? (
                  <Typography
                    variant="body1"
                    fontSize={14}
                    noWrap
                    fontWeight={600}
                  >
                    {column.label}
                  </Typography>
                ) : column.type === "checkbox" ? (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox
                        inputRef={selectAllCheckboxRef}
                        checked={selectedCheckBoxes.size > 0 && selectedCheckBoxes.size === employees.length}
                      />}
                      label=""
                      onChange={selectAllEmployees}
                    />
                  </FormGroup>
                ) : (
                  <Typography
                    variant="body1"
                    fontSize={14}
                    noWrap
                    fontWeight={600}
                    typography={"checkbox"}

                  >
                    {column.label}
                  </Typography>
                )}
              </TableCell>
            );
          })
        )}
      </TableRow>
    </TableHead>
  );
};

export default memo(TableHeader);
