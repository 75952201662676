"use strict";
import axios from "axios";
import { dispatch } from "store";
import { hideLoader, showLoader } from "store/reducers/dashboard";

// Create an instance of Axios
const uploadApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_PRIVATE_BASE_URL,
});

// Axios request interceptor
uploadApi.interceptors.request.use(
  (config) => {
    // set the loader state to true to turn on the loading animation
    dispatch(showLoader());

    config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
    config.headers["service"] = "Web Admin";

    // Get the access token from local storage or any other storage mechanism
    const accessToken = localStorage.getItem("serviceToken");

    // Add the access token to the Authorization header
    if (accessToken) {
      config.headers.token = `${accessToken}`;
    }

    return config;
  },
  (error) => {
    // hide loader
    dispatch(hideLoader());

    return Promise.reject(error);
  }
);

// Axios response interceptor
uploadApi.interceptors.response.use(
  (response) => {
    // hide loader
    dispatch(hideLoader());

    return response;
  },
  async (error) => {
    // hide loader
    dispatch(hideLoader());

    console.log("error.response", error.response);

    // Check if the response status code is 401 (unauthorized) then redirect to login page
    if (error.response.status === 401) {
      console.log("in error, token expired");
      // window.location.replace('/login');
    }

    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default uploadApi;
