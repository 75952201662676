import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { get } from "lodash";
import { dispatch } from "store";
import { onBoardingStatusUpdate, onboardedCompanyDetails } from "./auth";
import { updateSelectedCompany } from "./dashboardSearch";

interface initialState {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  companyFiles: string[];
  companyFileId: string;
  companyName: string;
  companyEmail: string;
  companyPhone: string;
  companyAbn: string;
  loader: boolean;
}
const initialState: initialState = {
  accessToken: "",
  refreshToken: "",
  expiresIn: 0,
  companyFiles: [],
  companyFileId: "",
  companyName: "",
  companyEmail: "",
  companyPhone: "",
  companyAbn: "",
  loader: false,
};

const myobOnboarding = createSlice({
  name: "myobOnboarding",
  initialState,
  reducers: {
    setMyobTokens(state, action) {
      const { accessToken, refreshToken, expiresIn, companyFiles } =
        action.payload;

      console.log("setting myob token");
      console.log("companyFiles", companyFiles);

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expiresIn = expiresIn;
      state.companyFiles = companyFiles;
    },
    setMyobCompanyDetails(state, action) {
      state.companyFileId = action.payload.companyFileId;
      state.companyName = action.payload.companyName;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    clearMyob: () => initialState,
  },
});

export const { setMyobTokens, setMyobCompanyDetails, isLoading, clearMyob } =
  myobOnboarding.actions;

export default myobOnboarding.reducer;

export function processMyobAccessToken(code: string) {
  return async () => {
    const response = await authApi.post(`/v2/api/myob/accessToken`, { code });

    if (!response?.data?.data)
      throw new Error("Something went wrong, please try again");

    const { accessToken, refreshToken, expiresIn, companyFiles } =
      response?.data?.data;
    dispatch(
      setMyobTokens({ accessToken, refreshToken, expiresIn, companyFiles })
    );
  };
}

export function storeMyobCompanyDetails(data: any) {
  console.log("in api call");
  return async () => {
    const payload = {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      expiresIn: data.expiresIn,
      companyFileId: data.companyFileId,
      companyName: data.companyName,
      companyEmail: data.email,
      companyPhone: data.phone,
      companyAbn: data.abn,
    };

    console.log("storemyob payload", payload);

    const response = await authApi.post(`/v2/api/myob/saveDetails`, payload);
    console.log("response", response.data);
    const companyData = response?.data?.data?.company;

    // dispatch event to update company details
    dispatch(onBoardingStatusUpdate({ onBoardedCompanyData: companyData }));

    const onBoardedUserCompanyDetails = {
      company_name: get(companyData, "company_name", ""),
      _id: get(companyData, "_id", ""),
    };

    dispatch(
      onboardedCompanyDetails({
        onBoardedCompanyDetails: onBoardedUserCompanyDetails,
      })
    );

    dispatch(
      updateSelectedCompany({ selectedCompany: onBoardedUserCompanyDetails })
    );
  };
}
