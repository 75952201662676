import { Grid, useMediaQuery, useTheme } from "@mui/material";
import TablePagination from "components/common/CashDTablePagination";
import Pagination from 'components/common/CashDPagination';

import { PAGINATION } from "./constants/pagination";
import { noop } from "lodash";



const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION;

interface MasterPaginationProps{
    page?: number;
    onPageChange?:(page:number)=>void;
    rowsPerPage?:number;
    onRowsPerPageChange?:any;
    totalTableRowsCount?:number;
    totalPageCount?:number;
}

const MasterPagination = ({
  page = INITIAL_PAGE,
  onPageChange= noop,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange= noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
}:MasterPaginationProps) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ backgroundColor: "background.card", pl: 1, pr: 1 }}
    >
      <Grid item xs container justifyContent="flex-start">
        <div>
            <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            count={totalTableRowsCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => onPageChange(newPage)}
            onRowsPerPageChange={onRowsPerPageChange}
            />

        </div>
      </Grid>
      {isLg && (
        <Grid item xs container justifyContent="flex-end">
          <Pagination
            count={totalPageCount || 0}
            page={page + 1}
            onChange={(event:any, newPage:number) => onPageChange(newPage - 1)}
            shape="rounded"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MasterPagination;
