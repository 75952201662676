import  { Children } from "react";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";

// import { BACKGROUND } from "theme/colors";
import { memo } from "utils/memo";
import { get } from "lodash";
// import T from "utils/constants/T";

const EditBankingTableHeader = ({ columns = [] }:{columns:any[]}) => {
  return (
    <TableHead>
      <TableRow>
        {
            <TableCell
                align="center"
                sx={{
                  border: "none",
                  textTransform:"capitalize",
                  // backgroundColor: BACKGROUND.cardDefault,
                  
                   padding: 1,
                }}>
                {/* {T.SELECT_LENDER} */}
            </TableCell>
        }
        {Children.toArray(
          columns.map((column:any) => {
            return (
              <TableCell
                align={get(column, "align", "")}
                sx={{
                  border: "none",
                  textTransform:"capitalize",
                  // backgroundColor: BACKGROUND.cardDefault,
                  
                   padding: 1,
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={14}
                  noWrap
                  fontWeight={600}
                >
                  {column.label}
                </Typography>
              </TableCell>
            );
          })
        )}
      </TableRow>
    </TableHead>
  );
};

export default memo(EditBankingTableHeader);
