import { Children } from "react";

import {
    TableRow,
    TableCell,
    Typography,
    TableBody,
    styled,
    Box,
} from '@mui/material';
import { format } from 'date-fns';
import { memo } from "utils/memo";
import T from "utils/constants/T";
// import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";

import './walletTable.css'
import { formatAmount } from "utils/CommonMethods";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderTop: "inherit",
    overflow: "hidden",
    padding: "8px 0px 8px 10px",
    // ...theme.typography.subtitle1, 
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface walletBodyProps {
    allTableRows: any[]
    page: number,
    rowsPerPage: number,
    totalTableRowsCount: number,
    totalPageCount: number,
    opan: boolean,
    anchorEl: any
    handleClick: any,
    handleClose: any,

}

const walletBody = ({
    allTableRows = [],
    page,
    rowsPerPage,
    totalTableRowsCount,
    totalPageCount,
    opan,
    anchorEl,
    handleClick,
    handleClose,
}: walletBodyProps) => {
    return (
        <TableBody>
            {Children.toArray(
                allTableRows.map((record, index) => {
                    const startDate = get(record, "start_date", "");
                    const endDate = get(record, "end_date", "");
                    // const createdDate = get(record, "created_date", "");
                    const startDateFormatted = new Date(startDate);
                    const endDateFormatted = new Date(endDate);
                    // const createdDateFormatted = new Date(createdDate)
                    // const createdDateText = format(createdDateFormatted, "dd MMM yyyy h:mm a");
                    const startDateText = format(startDateFormatted, 'dd MMM yyyy');
                    const endDateText = format(endDateFormatted, 'dd MMM yyyy');
                    const accured_amount_total = get(record, "pay_calculates.accured_amount_total", "")
                    const withdrawalsTotal = get(record, "pay_calculates.withdrawals_total", "")
                    const salaryBalance = accured_amount_total - withdrawalsTotal

                    return (
                        <TableRow sx={{ background: "white" }} key={index}>
                            <StyledTableCell>
                                {get(record, "staff_infor.fullname", "")}
                            </StyledTableCell>
                            <StyledTableCell>
                                {get(record, "staff_infor.email", "")}
                            </StyledTableCell>
                            <StyledTableCell>{get(record, "staff_infor.system_employee_id", "")}</StyledTableCell>
                            {/* <StyledTableCell>{createdDateText}</StyledTableCell> */}
                            <StyledTableCell>
                                <Box className="table-column">
                                    {startDateText} To {endDateText}
                                </Box>
                            </StyledTableCell>
                            <StyledTableCell>{formatAmount(get(record, "pay_calculates.amount_available", ""))}</StyledTableCell>
                            <StyledTableCell>{formatAmount(get(record, "pay_calculates.accured_amount_total", ""))}</StyledTableCell>
                            <StyledTableCell>{formatAmount(get(record, "pay_calculates.limit_amount", ""))}</StyledTableCell>
                            <StyledTableCell>{formatAmount(get(record, "pay_calculates.current_fee", ""))}</StyledTableCell>
                            <StyledTableCell>{formatAmount(get(record, "pay_calculates.withdrawals_total", ""))}</StyledTableCell>
                            <StyledTableCell>{formatAmount(salaryBalance)}</StyledTableCell>
                        </TableRow>
                    );
                })
            )}

            {allTableRows && allTableRows.length === 0 && (
                <TableRow>
                    <StyledTableCell colSpan={10} sx={{ border: "none" }}>
                        <Typography variant="body1" textAlign="center">
                            {T.NO_RECORDS}
                        </Typography>
                    </StyledTableCell>
                </TableRow>
            )}
        </TableBody>
    );
};



export default memo(walletBody);
