import { Box } from '@mui/material'

const BankingSettingsIcon = () => {
    return (
        <Box sx={{ marginRight: '8px' }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2256_1540)">
                    <path d="M2.99855e-08 12.8333H14V13.9999H2.99855e-08V12.8333ZM14 4.6135V5.83325H12.25V10.4999H13.4167V11.6666H0.583333V10.4999H1.75V5.83325H2.99855e-08V4.6135C-5.8161e-05 4.29911 0.0845804 3.9905 0.245019 3.72013C0.405458 3.44976 0.635771 3.2276 0.91175 3.077L6.16175 0.213417C6.41875 0.0727521 6.70702 -0.000976562 7 -0.000976562C7.29298 -0.000976562 7.58125 0.0727521 7.83825 0.213417L13.0883 3.077C13.3642 3.2276 13.5945 3.44976 13.755 3.72013C13.9154 3.9905 14.0001 4.29911 14 4.6135ZM2.91667 10.4999H4.66667V5.83325H2.91667V10.4999ZM5.83333 5.83325V10.4999H8.16667V5.83325H5.83333ZM11.0833 5.83325H9.33333V10.4999H11.0833V5.83325ZM12.8333 4.6135C12.8334 4.5087 12.8051 4.40584 12.7517 4.31571C12.6982 4.22559 12.6214 4.15153 12.5294 4.10133L7.27942 1.23775C7.19371 1.19098 7.09764 1.16648 7 1.16648C6.90236 1.16648 6.80629 1.19098 6.72058 1.23775L1.47058 4.10133C1.37859 4.15153 1.30182 4.22559 1.24834 4.31571C1.19486 4.40584 1.16665 4.5087 1.16667 4.6135V4.66658H12.8333V4.6135Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_2256_1540">
                        <rect width="14" height="14" fill="currentColor" />
                    </clipPath>
                </defs>
            </svg>
        </Box>

    )
}

export default BankingSettingsIcon