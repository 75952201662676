import { parseISO, format } from "date-fns";
import moment from "moment";
export const BACKEND_DATE_FORMAT = "yyyy-MM-dd";
export const getBEDateFormat = (val: any) => format(val, BACKEND_DATE_FORMAT);

//convert yyyy-MM-DDT00:00:00.000Z  into yyyy-monthName-dd using date-fns
export const convertDateIntoFrontEndFormat = (dateString: string) => {
  if (dateString) {
    const parsedDate = new Date(dateString);
    const formattedDate = format(parsedDate, "dd MMM yyyy hh:mm:ss a");
    return formattedDate;
  } else {
    return dateString;
  }
};

export const dateFormat = (date: string) => {
  if (!date) return date;
  const parsedDate = moment(date);
  return parsedDate.format("DD MMM YYYY");
};

//convert yyyy-MM-DDT00:00:00.000Z  into yyyy-mm-dd using date-fns

export const convertDateIntoBEFormat = (dateString: any) => {
  const convertedDate = moment(dateString).format("YYYY-MM-DD");
  return convertedDate;
};

//convert yyyy-MM-DDT00:00:00.000Z  into dd/MM/yyyy hh:mm:ss AM using date-fns

export const convertDateIntoBankingFEFormat = (dateString: any) => {
  const dateObject = parseISO(dateString);
  const formattedDate = moment(dateObject).format("DD/MM/YYYY hh:mm:ss A");
  return formattedDate;
};

//convert 26-Sep-2022 into yyyy/mm/dd  using date-fns

export const convertDateIntoBEFormat1 = (dateString: any) => {
  const parsedDate = moment(dateString, "DD-MMM-YYYY");
  const convertedDate = parsedDate.format("YYYY-MM-DD");
  return convertedDate;
};
