import { Children } from "react";

import {
  Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  // Box,
} from "@mui/material";
// import { format } from 'date-fns';
import { memo } from "utils/memo";
import T from "utils/constants/T";
// import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import { dateFormat } from "utils/date";
import { format } from "date-fns";
import { formatAmount } from "utils/CommonMethods";
// import { formatAmount } from "utils/CommonMethods";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  // ...theme.typography.subtitle1,
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface TimesheetBodyProps {
  allTableRows: any[];
  page: number;
  rowsPerPage: number;
  totalTableRowsCount: number;
  totalPageCount: number;
  systemPayrollCode: string;
}

const TimesheetBody = ({
  allTableRows = [],
  page,
  rowsPerPage,
  totalTableRowsCount,
  totalPageCount,
  systemPayrollCode,
}: TimesheetBodyProps) => {
  const workingHours = (timesheetRecord: any) => {
    const astuteWorkingHours = () => {
      if (!timesheetRecord?.startTime || !timesheetRecord?.endTime)
        return "N/A";

      const parsedStartDate = new Date(timesheetRecord.startTime);
      const formattedStartTime = format(parsedStartDate, "hh:mm a");

      const parsedEndDate = new Date(timesheetRecord.endTime);
      const formattedEndTime = format(parsedEndDate, "hh:mm a");

      return `${formattedStartTime} - ${formattedEndTime}`;
    };

    const keypayWorkingHours = () => {
      if (!timesheetRecord?.startTime || !timesheetRecord?.endTime)
        return "N/A";

      const parsedStartDate = new Date(timesheetRecord.startTime);
      const formattedStartTime = format(parsedStartDate, "hh:mm a");

      const parsedEndDate = new Date(timesheetRecord.endTime);
      const formattedEndTime = format(parsedEndDate, "hh:mm a");

      return `${formattedStartTime} - ${formattedEndTime}`;
    };

    const deputyWorkingHours = () => {
      if (
        !timesheetRecord?.StartTimeLocalized ||
        !timesheetRecord?.EndTimeLocalized
      )
        return "N/A";

      const parsedStartDate = new Date(timesheetRecord.StartTimeLocalized);
      const formattedStartTime = format(parsedStartDate, "hh:mm a");

      const parsedEndDate = new Date(timesheetRecord.EndTimeLocalized);
      const formattedEndTime = format(parsedEndDate, "hh:mm a");

      const companyName =
        timesheetRecord?._DPMetaData?.OperationalUnitInfo?.OperationalUnitName;

      return `${formattedStartTime} - ${formattedEndTime} at ${companyName}`;
    };

    const myobWorkingHours = () => {
      return timesheetRecord.hoursWorked;
    };

    const xeroWorkingHours = () => {
      if (
        !timesheetRecord?.StartDateTimestamp ||
        !timesheetRecord?.EndDateTimestamp
      )
        return "N/A";

      const parsedStartDate = new Date(timesheetRecord?.StartDateTimestamp);
      const formattedStartTime = format(parsedStartDate, "hh:mm a");

      const parsedEndDate = new Date(timesheetRecord?.EndDateTimestamp);
      const formattedEndTime = format(parsedEndDate, "hh:mm a");

      return `${formattedStartTime} - ${formattedEndTime}`;
    };

    if (systemPayrollCode.toLowerCase() === "astute")
      return astuteWorkingHours(); // hudson
    else if (systemPayrollCode.toLowerCase() === "deputy")
      return deputyWorkingHours(); //challenger
    else if (systemPayrollCode.toLowerCase() === "keypay")
      return keypayWorkingHours(); //expedo
    else if (systemPayrollCode.toLowerCase() === "myobexo")
      return myobWorkingHours();
    else if (systemPayrollCode.toLowerCase() === "xero")
      return xeroWorkingHours();
  };

  const payRate = (timesheetRecord: any) => {
    const astutePayRate = () => {
      const salaryEarningRate = get(
        timesheetRecord,
        "employee[0].xero_salary_earnings_rates",
        ""
      );
      if (!salaryEarningRate) return "N/A";

      const earningObject = salaryEarningRate[salaryEarningRate.length - 1];
      if (!earningObject) return "N/A";

      // there will be more conditions in else if, so don't remove else code for now
      if (earningObject.name.toLowerCase() === "hourly") {
        return `${formatAmount(earningObject.rate)}/hrs`;
      } else {
        return `${formatAmount(earningObject.rate)}/hrs`;
      }
    };

    const deputyPayRate = () => {
      if (!timesheetRecord.PayRuleObject) return "N/A";
      return `$${timesheetRecord.PayRuleObject.HourlyRate}/hrs(${timesheetRecord.PayRuleObject.PayTitle})`;
    };

    const keypayPayRate = () => {
      const payrate = get(timesheetRecord, "employee[0].rate_per_hour", "N/A");
      const keypay_primary_pay_category = get(
        timesheetRecord,
        "employee[0].keypay_primary_pay_category",
        "N/A"
      );
      if (payrate === "N/A") return "N/A";
      return `$${payrate}/hrs(${keypay_primary_pay_category})`;
    };

    const myobPayRate = () => {
      return `${timesheetRecord.PayRate}/hrs`;
    };

    const xeroPayRate = () => {
        const payrate = get(timesheetRecord, "TimesheetLines[0].EarningsRate.RatePerUnit", "N/A");
                if (payrate === "N/A") return "N/A";
        return payrate;
      };

    if (systemPayrollCode.toLowerCase() === "astute") return astutePayRate();
    else if (systemPayrollCode.toLowerCase() === "deputy")
      return deputyPayRate();
    else if (systemPayrollCode.toLowerCase() === "keypay")
      return keypayPayRate();
    else if (systemPayrollCode.toLowerCase() === "myobexo")
      return myobPayRate();
    else if (systemPayrollCode.toLowerCase() === "xero")
        return xeroPayRate();
  };

  const getStatus = (statusIndex: number): any => {
    const statusLists = [
      {
        index: 0,
        value: "PENDING",
        type: "info",
      },
      {
        index: 1,
        value: "APPROVED",
        type: "success",
      },
      {
        index: 2,
        value: "ACCEPTED",
        type: "success",
      },
      {
        index: 3,
        value: "REJECTED",
        type: "error",
      },
      {
        index: 4,
        value: "PENDING",
        type: "info",
      },
      {
        index: 5,
        value: "SUBMITTED",
        type: "success",
      },
    ];

    const statusListsIndex = statusLists.findIndex(
      (list) => list.index === statusIndex
    );
    if (statusListsIndex < 0) return statusLists[0];
    else return statusLists[statusListsIndex];
  };

  const cashdStatus = (timesheetRecord: any) => {
    const astuteCashdStatus = () => {
      const cashdStatus = get(timesheetRecord, "CashDStatus", 0);
      const status = getStatus(cashdStatus);
      return (
        <Chip
          color={status.type}
          label={status.value}
          size="small"
          variant="light"
        />
      );
    };
    const deputyCashdStatus = () => {
      const cashdStatus = get(timesheetRecord, "CashDStatus", 0);
      const status = getStatus(cashdStatus);
      return (
        <Chip
          color={status.type}
          label={status.value}
          size="small"
          variant="light"
        />
      );
    };
    const keypayCashdStatus = () => {
      const cashdStatus = get(timesheetRecord, "CasdDStatus", 0);
      const status = getStatus(cashdStatus);
      return (
        <Chip
          color={status.type}
          label={status.value}
          size="small"
          variant="light"
        />
      );
    };

    const myobCashdStatus = () => {
      return <Chip color="info" label="PENDING" size="small" variant="light" />;
    };
    const xeroCashdStatus = () => {
      const cashdStatus = get(timesheetRecord, "CashDStatus", 0);
      const status = getStatus(cashdStatus);
      return (
        <Chip
          color={status.type}
          label={status.value}
          size="small"
          variant="light"
        />
      );
    };

    if (systemPayrollCode.toLowerCase() === "astute")
      return astuteCashdStatus();
    else if (systemPayrollCode.toLowerCase() === "deputy")
      return deputyCashdStatus();
    else if (systemPayrollCode.toLowerCase() === "keypay")
      return keypayCashdStatus();
    else if (systemPayrollCode.toLowerCase() === "myobexo")
      return myobCashdStatus();
    else if (systemPayrollCode.toLowerCase() === "xero")
      return xeroCashdStatus();
  };

  const timesheetDate = (timesheetRecord: any) => {
    const astuteTimesheetDate = () =>
      dateFormat(get(timesheetRecord, "date", ""));
    const deputyTimesheetDate = () =>
      dateFormat(get(timesheetRecord, "Date", ""));
    const keypayTimesheetDate = () =>
      dateFormat(get(timesheetRecord, "startTime", ""));
    const myobTimesheetDate = () => {
      const startDate = dateFormat(get(timesheetRecord, "StartDate", ""));
      const endDate = dateFormat(get(timesheetRecord, "EndDate", ""));
      return `${startDate} to ${endDate}`;
    };
    const xeroTimesheetDate = () => {
      const startDate = dateFormat(get(timesheetRecord, "StartDate", ""));
      const endDate = dateFormat(get(timesheetRecord, "EndDate", ""));
      return `${startDate} to ${endDate}`;
    };

    if (systemPayrollCode.toLowerCase() === "astute")
      return astuteTimesheetDate();
    else if (systemPayrollCode.toLowerCase() === "deputy")
      return deputyTimesheetDate();
    else if (systemPayrollCode.toLowerCase() === "keypay")
      return keypayTimesheetDate();
    else if (systemPayrollCode.toLowerCase() === "myobexo")
      return myobTimesheetDate();
    else if (systemPayrollCode.toLowerCase() === "xero")
      return xeroTimesheetDate();
  };

  const timesheetEmployeeName = (timesheetRecord: any) => {
    if (systemPayrollCode.toLowerCase() === "myobexo")
      return get(timesheetRecord, "StaffId.fullname", "");
    else if (systemPayrollCode.toLowerCase() === "xero") {
      const firstName = get(timesheetRecord, "Employee.FirstName", "");
      const lastName = get(timesheetRecord, "Employee.LastName", "");
    
      const name = firstName + " " + lastName;
      return name;
    } else {
      return get(timesheetRecord, "employee.fullname", "");
    }
  };
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {

          return (
            <TableRow sx={{ background: "white" }} key={index}>
              <StyledTableCell>{timesheetEmployeeName(record)}</StyledTableCell>
              <StyledTableCell>{timesheetDate(record)}</StyledTableCell>
              <StyledTableCell>{workingHours(record)}</StyledTableCell>
              <StyledTableCell>{payRate(record)}</StyledTableCell>
              <StyledTableCell>{cashdStatus(record)}</StyledTableCell>
            </TableRow>
          );
        })
      )}

      {allTableRows && allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={5} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              {T.NO_RECORDS}
            </Typography>
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default memo(TimesheetBody);
