import React, { useEffect, useReducer } from 'react'
import { Grid, Box, Button, InputBase } from "@mui/material"
import RegisteredEmployees from "./RegisteredEmployees"
import MainCard from "components/MainCard"
import { fetchRegisteredEmployees } from "store/reducers/registeredEmployees"
import { dispatch, useSelector } from "store"
import ScrollX from "components/ScrollX"
import { PAGINATION } from "utils/constants/pagination"
import { get } from 'lodash'
import { styled, alpha } from '@mui/material/styles';
import T from 'utils/constants/T';
import { Link } from 'react-router-dom'
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import authApi from 'interceptor/authInterceptor'
// import { checkSalaryWag, getScheduleName, getStatus } from 'utils/employees'
// import { format } from 'date-fns'
// import Papa from 'papaparse';
// import { exportData } from 'utils/CommonMethods'
// import toast from 'utils/ToastNotistack'
import SearchIcon from '@mui/icons-material/Search';
import CashDAutocomplete from 'components/common/CashDAutocomplete'
import { fetchDeductionTransactionBusinessUnitList } from 'store/reducers/deductionTransactionFilter'
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    border: "1px solid #d9d9d9",
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create('width'),
        width: '100%'
    },
}));
const RegisteredEmployeesPage = () => {

    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const { systemCode } = useSelector((state: any) => state.companyTabData);
    const companyId = get(selectedCompany, "_id", "");
    const registeredEmployees = useSelector((state) => state.registeredEmployees)
    const { businessUnitList } = useSelector((state: any) => state.deductionTransactionFilter)
    const { employees, page, pageSize, totalRecords, totalPages } = registeredEmployees
    const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any) => ({ ...prevState, ...newState }),
        {
            pageNumber: page,
            rowsPerPage: pageSize,
            isLoading: false,
            employeeName: '',
            businessUnit: null
        }
    );

    const { rowsPerPage, employeeName, businessUnit } = localState
    console.log('employee name', employeeName)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handlePageChange = (page: number) => {
        setLocalState({ page: page });

        dispatch(fetchRegisteredEmployees(page, rowsPerPage, companyId, employeeName, businessUnit?._id));
        document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
    }

    const handleRowsPerPageChange = (event: any) => {
        const { value } = event.target;
        setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
        dispatch(fetchRegisteredEmployees(INITIAL_PAGE, value, companyId, employeeName, businessUnit?._id));
    };

    // const fetchEmployees: any = async (page: number, pageSize: number, companyId: string) => {

    //     // fetch registered employees
    //     const response = await authApi.get(`/v2/api/users/registered?page=${page}&pageSize=${pageSize}&companyId=${companyId}`);
    //     const { data: employees, totalPages, page: currentPage } = response.data

    //     // check for page size, if current page is equal or greater than totalPages, then return
    //     if (currentPage + 1 >= totalPages) return employees

    //     // recursiverly fetch all registeredEmployees from fetchemployees method and return
    //     const nextPage = await fetchEmployees(page + 1, pageSize, companyId)
    //     return [...employees, ...nextPage]
    // }

    // const formattedExportData = (employees: any) => {
    //     const formattedData: any = []

    //     employees.forEach((employee: any) => {

    //         const schedule = getScheduleName(employee, get(employee, "companies[0].systems.code", ""))
    //         const registrationDate = get(employee, "created_date", "");
    //         const date = new Date(registrationDate);
    //         let status = getStatus(parseInt(employee.is_active))
    //         const registrationDateText = format(date, 'dd MMM yyyy h:mm a');

    //         formattedData.push({
    //             companyName: get(employee, "companies[0].company_name", ""),
    //             firstName: get(employee, "first_name", ""),
    //             lastName: get(employee, "last_name", ""),
    //             payrollID: get(employee, "system_employee_id", ""),
    //             payType: checkSalaryWag(get(employee, "salary_wag", ""), get(employee, "companies[0].systems.code", "")),
    //             schedule: schedule?.payPeriodName,
    //             status: status.status,
    //             registrationDate: registrationDateText
    //         })
    //     })

    //     return formattedData
    // }

    // const handleCSVExport = async () => {
    //     try {
    //         setLocalState({ isLoading: true })
    //         const employees = await fetchEmployees(INITIAL_PAGE, EXPORT_PAGE_SIZE, companyId)
    //         const finalExportData = formattedExportData(employees)
    //         console.log('final export data', finalExportData)
    //         const csvData = Papa.unparse(finalExportData);
    //         exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
    //         setLocalState({ isLoading: false })
    //     } catch (error) {
    //         toast(get(error, "message", ""), { variant: "error" });
    //     }
    // }

    // clear filter values if company is changed
    useEffect(() => {
        setLocalState({ businessUnit: null })
    }, [companyId])

    // fetch business unit ids if compnay is selected and company payroll is astute
    useEffect(() => {

        dispatch(fetchRegisteredEmployees(INITIAL_PAGE, ROWS_PER_PAGE, companyId, employeeName, businessUnit?._id))

        if (companyId && systemCode == T.ASTUTE.toUpperCase()) {
            dispatch(fetchDeductionTransactionBusinessUnitList(companyId))
        }

    }, [companyId, businessUnit, systemCode])

    let debounceTimer: any = null;

    const handleEmployeeNameSearch = (employeeName: any) => {
        const { name, value } = employeeName.target;
        setLocalState({ [name]: value })
        clearTimeout(debounceTimer);
        setLocalState({ isLoading: false })

        if (value?.length >= 3 || value?.length === 0) {
            setLocalState({ isLoading: true })
            debounceTimer = setTimeout(() => {
                dispatch(fetchRegisteredEmployees(INITIAL_PAGE, ROWS_PER_PAGE, companyId, value))
                setLocalState({ isLoading: false })
            }, 500)
        }

    }

    const onHandleBusinessUnitChange = (value: string) => {
        setLocalState({ 'businessUnit': value })
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard>

                        {/* search filteres */}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Box>
                                <Button variant='contained'>Registered</Button>
                            </Box>
                            <Box>
                                <Button component={Link} to="/employees/unregistered" variant='text'>Unregistered</Button>
                            </Box>
                            <Box sx={{ marginLeft: '20px' }}>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder={T.EMPLOYEE}
                                        name="employeeName"
                                        value={employeeName}
                                        inputProps={{ "aria-label": "search" }}
                                        onChange={handleEmployeeNameSearch}
                                    />
                                </Search>
                            </Box>
                            {companyId && systemCode == T.ASTUTE.toUpperCase() && (
                                <Box>
                                    <CashDAutocomplete
                                        placeholder={T.BUSINESS_UNIT}
                                        listDetails={businessUnitList}
                                        value={businessUnit}
                                        getByLabelText={(option: any) =>
                                            `${option.name}`
                                        }
                                        onHandleChange={(event: any, newValue: any) =>
                                            onHandleBusinessUnitChange(newValue)
                                        }
                                        fullWidth
                                        width="200px"
                                    />
                                </Box>
                            )}

                        </Box>



                        <ScrollX>
                            <RegisteredEmployees
                                employees={employees}
                                page={page}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                totalPages={totalPages}
                                open={open}
                                anchorEl={anchorEl}
                                handleClick={handleClick}
                                handleClose={handleClose}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                companyId={companyId}
                                systemCode={systemCode}
                            />
                        </ScrollX>
                    </MainCard >
                </Grid >
            </Grid >
        </>
    )
}

export default RegisteredEmployeesPage