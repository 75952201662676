import { Divider, Grid } from "@mui/material";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
const ViewCompanyDetails = () => {
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);
  return (
    <Grid item xs={12}>
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.COMPANY_NAME}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.companyName}</StyledTitleTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.ABN}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.ABN}</StyledTitleTypography>
          </Grid>
          <Grid className="divider" item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{T.ADDRESS}</StyledBodyTypography>
            <StyledTitleTypography>{getUserDataByUSerID.address}</StyledTitleTypography>
          </Grid>
        </Grid>
      </CashDCard>
    </Grid>
  );
};

export default ViewCompanyDetails;
