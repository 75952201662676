import { Children, useState } from "react";

import {
  Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  IconButton,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import { format } from 'date-fns';
import { memo } from "utils/memo";
import T from "utils/constants/T";
// import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import { Menu } from "@mui/material";
import moment from "moment";
import {
  deleteEmployeeProfile,
  fetchRegisteredEmployees,
} from "store/reducers/registeredEmployees";
import { dispatch } from "store";
import { useNavigate } from "react-router";
import { getUserByUserID } from "store/reducers/getEmployeeData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1,
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface RegisteredEmployeesBodyProps {
  allTableRows: any[];
  page: number;
  rowsPerPage: number;
  totalTableRowsCount: number;
  totalPageCount: number;
  opan: boolean;
  anchorEl: any;
  handleClick: any;
  handleClose: any;
  companyId: string;
  systemCode: string;
}

interface Status {
  status: String;
  type: String;
}
const RegisteredEmployeesBody = ({
  allTableRows = [],
  page,
  rowsPerPage,
  totalTableRowsCount,
  totalPageCount,
  opan,
  anchorEl,
  handleClick,
  handleClose,
  companyId,
  systemCode,
}: RegisteredEmployeesBodyProps) => {
  const [selectedEmploiyeedId, setSelectedEmploiyeedId] = useState(null);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const navigate = useNavigate();
  const getScheduleName = (data: any, systemCode: String) => {
    let scheduleName = { payPeriodName: "", payPeriodId: "" };

    if (systemCode == T.DEPUTY.toUpperCase()) {
      scheduleName.payPeriodName = get(
        data,
        "pay_period_originations[0].name",
        ""
      );
      scheduleName.payPeriodId = get(
        data,
        "pay_period_originations[0]._id",
        ""
      );
    } else if (systemCode == T.KEYPAY.toUpperCase()) {
      scheduleName.payPeriodName = get(
        data,
        "keypay_pay_schedules[0].name",
        ""
      );
      scheduleName.payPeriodId = get(data, "keypay_pay_schedules[0]._id", "");
    } else {
      scheduleName.payPeriodName = get(data, "xero_pay_calendars[0].Name", "");
      scheduleName.payPeriodId = get(data, "xero_pay_calendars[0]._id", "");
    }

    return scheduleName;
  };

  const checkSalaryWag = (salaryWag: any, systemCode: any) => {
    let employeeType;
    if (systemCode === "DEPUTY") {
      employeeType = "Timesheet";
    } else {
      if (salaryWag === 2) {
        employeeType = "Timesheet";
      } else if (salaryWag === 3) {
        employeeType = "Salary & TimeSheet";
      } else {
        employeeType = "Salary";
      }
    }
    return employeeType;
  };

  const getStatus = (statusType: any): any => {
    const status: Status = { status: "", type: "" };
    switch (statusType) {
      case 0:
        status.status = "Blocked";
        status.type = "error";
        break;
      case 1:
        status.status = "Active";
        status.type = "success";
        break;
      case 2:
        status.status = "Uninvite";
        status.type = "info";
        break;
      case 3:
        status.status = "Terminated";
        status.type = "error";
        break;

      default:
        status.status = "Unknown";
        status.type = "error";
        break;
    }

    return status;
  };

  const deleteUserProfile = async () => {
    const isConfirmed = confirm(
      "Are you sure you want to delete this user profile?"
    );
    if (isConfirmed) {
      await dispatch(deleteEmployeeProfile(selectedEmploiyeedId));
      await dispatch(fetchRegisteredEmployees(page, rowsPerPage, companyId));
      handleClose();
    }
  };

  const handleViewUserDetail = async () => {
    await dispatch(getUserByUserID(selectedUserID));
    navigate(
      `/employees/employeeDetails/${selectedUserID}/personalProfile/view`
    );
  };

  return (
    <>
      <TableBody>
        {Children.toArray(
          allTableRows.map((record, index) => {
            console.log(record._id);
            const scheduleName = getScheduleName(
              record,
              get(record, "companies[0].systems.code", "")
            );
            const registrationDate = get(record, "createdAt", "")
              ? get(record, "createdAt", "")
              : get(record, "created_date", "");
            const registrationDateText =
              moment(registrationDate).format("DD MMM yyyy h:mm A");
            // const registrationDateText = format(date, 'dd MMM yyyy h:mm a');
            let status = getStatus(parseInt(record.is_active));
            const businessUnitName = get(record, "business_units[0].name");

            return (
              <TableRow sx={{ background: "white" }} key={index}>
                {!companyId && (
                  <StyledTableCell>
                    {get(record, "companies[0].company_name", "")}
                  </StyledTableCell>
                )}

                <StyledTableCell>{get(record, "fullname", "")}</StyledTableCell>
                <StyledTableCell>{get(record, "email", "")}</StyledTableCell>
                <StyledTableCell>
                  {get(record, "system_employee_id", "")}
                </StyledTableCell>
                <StyledTableCell>
                  {checkSalaryWag(
                    get(record, "salary_wag", ""),
                    get(record, "companies[0].systems.code", "")
                  )}
                </StyledTableCell>
                <StyledTableCell>{scheduleName.payPeriodName}</StyledTableCell>
                {systemCode == T.ASTUTE.toUpperCase() && (
                  <StyledTableCell>{businessUnitName}</StyledTableCell>
                )}
                <StyledTableCell>
                  <Chip
                    color={status.type}
                    label={status.status}
                    size="small"
                    variant="light"
                  />
                </StyledTableCell>
                <StyledTableCell>{registrationDateText}</StyledTableCell>
                {/* {systemCode == T.ASTUTE.toUpperCase() && (
                )} */}
                <StyledTableCell>
                  <IconButton
                    //  id="basic-button"
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    //  aria-expanded={'true' : undefined}
                    onClick={(e: any) => {
                      handleClick(e);
                      setSelectedEmploiyeedId(record._id);
                      setSelectedUserID(record.user[0]._id);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })
        )}

        {allTableRows && allTableRows.length === 0 && (
          <TableRow>
            <StyledTableCell colSpan={10} sx={{ border: "none" }}>
              <Typography variant="body1" textAlign="center">
                {T.NO_RECORDS}
              </Typography>
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleViewUserDetail}>View</MenuItem>
        <MenuItem>Block</MenuItem>
        <MenuItem onClick={deleteUserProfile}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default memo(RegisteredEmployeesBody);
