// material-ui
import { FormHelperText, Grid, Stack, Button } from '@mui/material';

//Lodash
import { get, noop } from 'lodash';

// project imports
import MainCard from 'components/MainCard';
import UploadMultiFile from 'components/third-party/dropzone/MultiFile';

// third-party
import { Formik } from 'formik';
import * as yup from 'yup';

//Local Imports
import T from 'utils/constants/T';
import { useNavigate } from 'react-router';
import { saveKybTabData } from 'store/reducers/companyKybTab';
import { useSelector, useDispatch } from 'store';
import Loader from 'components/Loader';

//CSS
import '../../companyDetails.css';
// ==============================|| PLUGINS - DROPZONE ||============================== //
interface KybDropzoneProps{
  status?: string;
  comments?: string;
  cashDStaffName?: string;
  employeeId?: string;
  verificationCompletedDate?: string;
  reVerificationDueDate?: string;
  documentUrl?: any[];
  onUpload?: any;
  handleRemoveUrl?: (index:number)=>void;
  onHandleChange?: (e:any)=>void;
  onHandleDateChange?: (newValue:any, type:string)=>void;
  handleRadioChange?: (e:any)=>void;
}
const KybDropzone = ({
  status="",
  comments="",
  cashDStaffName="",
  employeeId="",
  verificationCompletedDate="",
  reVerificationDueDate="",
  documentUrl=[],
  onUpload= noop,
  handleRemoveUrl= noop,
  onHandleChange= noop,
  onHandleDateChange= noop,
  handleRadioChange= noop,
}:KybDropzoneProps) => {
  const navigate =useNavigate();
  const navigator=(path:string)=>{
    navigate(path)
  }
  console.log(documentUrl)
  const dispatch= useDispatch();
  const  { selectedCompany } = useSelector((state:any)=>state.dashboardSearch);
  const { companyName } = useSelector((state: any) => state.companyTabData);
  const  { loader} = useSelector((state:any)=>state.kybTabData);
    const handleSave = () =>{
      const payload= {
        documentUrl: documentUrl,
        staffName: cashDStaffName,
        verificationCompletedDate: verificationCompletedDate,
        reverifiedDueDate: reVerificationDueDate,
        employeeId: employeeId,
        comments:comments,
        company_id: get(selectedCompany,"_id",""),
        status: status,
      }
        dispatch(saveKybTabData(payload,navigator))
    }

  return (
    <>
    {
      loader && <Loader />
    }
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard
            title={companyName}
            secondary={
              <Button 
                variant='contained'
                onClick={handleSave}
                className='main-card-button'
              >
                {T.SAVE}
              </Button>
            }
          >
            <Formik
              initialValues={{ files: null }}
              onSubmit={(values: any) => {
              }}
              validationSchema={yup.object().shape({
                files: yup.mixed().required('Avatar is a required.')
              })}
            >
              {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.5} alignItems="center">
                        <UploadMultiFile
                          setFieldValue={setFieldValue}
                          files={values.files}
                          error={touched.files && !!errors.files}
                          status={status}
                          onUpload={onUpload}
                          documentUrl={documentUrl}
                          comments={comments}
                          cashDStaffName={cashDStaffName}
                          employeeId={employeeId}
                          verificationCompletedDate={verificationCompletedDate}
                          reVerificationDueDate={reVerificationDueDate}
                          handleRemoveUrl={handleRemoveUrl}
                          onHandleChange={onHandleChange}
                          onHandleDateChange={onHandleDateChange}
                          handleRadioChange={handleRadioChange}
                        />
                        {touched.files && errors.files && (
                          <FormHelperText error id="standard-weight-helper-text-password-login">
                            {errors.files}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default KybDropzone;
