// material-ui
import { useTheme } from '@mui/material/styles';
// import { useSelector } from 'store';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
import logoIconDark from 'assets/images/CashD-logo-full.svg';
import logoIcon from 'assets/images/CashD-logo-full.svg';

//Material-UI
// import { Typography } from '@mui/material';

//css
import './logo.css'

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {

  // const { selectedCompany } = useSelector((state) => state.dashboardSearch);
  
  const theme = useTheme();
  return (
    <>
     {/* {selectedCompany?.company_name ? <Typography variant='h4' className="logo-typo">{ selectedCompany?.company_name }</Typography> : <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="CashD" width="200" />}      */}
     {<img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="CashD" width="200" />}     
    </>
  );
};

export default LogoMain;
