import { useState } from 'react';
// material-ui
import IconButton from 'components/@extended/IconButton';
// assets
import {
  SettingOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import T from 'utils/constants/T';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //
const Search = () => {
  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
  const { onBoardedCompanyData, isCashDSuperAdmin, isEmployerAdmin } = useSelector((state: any) => state.auth);

  const selectedCompanyId = get(selectedCompany, "_id", "");
  const navigate = useNavigate();
  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    selectedCompanyId ? navigate('companyDetails/company/view') : alert(T.FIRST_SELECT_A_COMPANY);
  };

  const settingsDisabled = () => {

    // is super admin and company is selected, then show disabled will be false
    if (isCashDSuperAdmin && !selectedCompany?._id) return true

    // if company admin and oboared is not completed then disable the button
    if (isEmployerAdmin && !onBoardedCompanyData?._id) return true

    // otherwise return false
    return false

    return false;
  }

  return (
    <IconButton
      color="secondary"
      variant="light"
      sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
      aria-label="settings toggler"
      onClick={handleToggle}
      disabled={settingsDisabled()}
    >
      <SettingOutlined />
    </IconButton>

  )
};

export default Search;
