import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { get } from "lodash";
import { dispatch } from "store";
import toast from "utils/ToastNotistack";

interface initialState {
  loader: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userAddress: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankBSBNumber: number;
  employeeName: string;
  employeeEmail: string;
  maxWithdrawal: number;
  maxWithdrawalMoney: number;
  maxWithdrawalPercent: number;
  minWithdrawal: number;
  startDate: string;
  employeeType: string;
  companyName: string;
  ABN: number;
  address: string;
  calenderType: string;
  selectedEmploiyeedId: string;
  systemCode: string;
  staffID: string;
  maxType: boolean;
  companyID: string;
}
const initialState: initialState = {
  loader: false,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  bankAccountName: "N/A",
  bankAccountNumber: "N/A",
  bankBSBNumber: 0,
  employeeName: "",
  employeeEmail: "",
  maxWithdrawal: 0,
  maxWithdrawalMoney: 0,
  maxWithdrawalPercent: 0,
  minWithdrawal: 0,
  startDate: "",
  employeeType: "",
  companyName: "",
  ABN: 0,
  address: "",
  userAddress: "",
  calenderType: "",
  selectedEmploiyeedId: "",
  systemCode: "",
  staffID: "",
  maxType: false,
  companyID: "",
};

const getEmployeeData = createSlice({
  name: "getEmployeeData",
  initialState,
  reducers: {
    getUserDataByUSerID(state, action) {
      let {
        firstName,
        lastName,
        email,
        phone,
        bankAccountName,
        bankAccountNumber,
        bankBSBNumber,
        employeeName,
        employeeEmail,
        maxWithdrawal,
        maxWithdrawalMoney,
        maxWithdrawalPercent,
        minWithdrawal,
        startDate,
        employeeType,
        companyName,
        ABN,
        address,
        userAddress,
        systemCode,
        calenderType,
        staffID,
        maxType,
        companyID,
      } = action.payload;
      state.firstName = firstName || initialState.firstName;
      state.lastName = lastName || initialState.lastName;
      state.email = email || initialState.email;
      state.phone = phone || initialState.phone;
      state.bankAccountName = bankAccountName || initialState.bankAccountName;
      state.bankAccountNumber =
        bankAccountNumber || initialState.bankAccountNumber;
      state.bankBSBNumber = bankBSBNumber || initialState.bankBSBNumber;
      state.employeeName = employeeName || initialState.employeeName;
      state.employeeEmail = employeeEmail || initialState.employeeEmail;
      state.maxWithdrawal = maxWithdrawal || initialState.maxWithdrawal;
      state.maxWithdrawalMoney =
        maxWithdrawalMoney || initialState.maxWithdrawalMoney;
      state.maxWithdrawalPercent =
        maxWithdrawalPercent || initialState.maxWithdrawalPercent;
      state.minWithdrawal = minWithdrawal || initialState.minWithdrawal;
      state.startDate = startDate || initialState.startDate;
      state.employeeType = employeeType || initialState.employeeType;
      state.companyName = companyName || initialState.companyName;
      state.ABN = ABN || initialState.ABN;
      state.address = address || initialState.address;
      state.userAddress = userAddress || initialState.userAddress;
      state.calenderType = calenderType || initialState.calenderType;
      state.systemCode = systemCode || initialState.systemCode;
      state.staffID = staffID || initialState.staffID;
      state.maxType = maxType || initialState.maxType;
      state.companyID = companyID || initialState.companyID;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    clearRegisteredEmployees: () => initialState,
  },
});

export const { getUserDataByUSerID, isLoading, clearRegisteredEmployees } =
  getEmployeeData.actions;

export default getEmployeeData.reducer;

export function getUserByUserID(selectedEmploiyeedId: any) {
  console.log(selectedEmploiyeedId);
  return async () => {
    const response = await authApi.get(
      `/v2/api/users?userId=${selectedEmploiyeedId}`
    );
    if (response.data) {
      const userData = response.data.data;
      const addressLine1: any = get(userData, "address_line_1", "")
        ? get(userData, "address_line_1", "")
        : "";
      const addressLine2: any = get(userData, "address_line_2", "")
        ? get(userData, "address_line_2", "")
        : "";
      const addressLine3: any = get(userData, "address_line_3", "")
        ? get(userData, "address_line_3", "")
        : "";
      const addressLineCity: any = get(userData, "address_line_city", "")
        ? get(userData, "address_line_city", "")
        : "";
      const addressUser: any =
        `${addressLine1} ${addressLine2} ${addressLine3} ${addressLineCity}`.trim();
      dispatch(
        getUserDataByUSerID({
          firstName: get(userData, "first_name", ""),
          lastName: get(userData, "last_name", ""),
          email: get(userData, "email", ""),
          phone: get(userData, "mobile", ""),
          bankAccountName: get(
            userData,
            "staffs.bank_account.bank_account_name",
            ""
          )
            ? get(userData, "staffs.bank_account.bank_account_name", "")
            : get(userData, "staffs.bank_account_name", ""),
          bankAccountNumber: get(
            userData,
            "staffs.bank_account.bank_account_number",
            ""
          )
            ? get(userData, "staffs.bank_account.bank_account_number", "")
            : get(userData, "staffs.bank_account_number", ""),
          bankBSBNumber: get(
            userData,
            "staffs.bank_account.bank_bsb_number",
            ""
          )
            ? get(userData, "staffs.bank_account.bank_bsb_number", "")
            : get(userData, "staffs.bank_bsb_number", ""),
          employeeName: get(userData, "fullname", ""),
          employeeEmail: get(userData, "email", ""),
          maxWithdrawal: get(userData, "staffs.limit_money", "")
            ? get(userData, "staffs.limit_money", "")
            : get(userData, "staffs.limit_allowable_percent_drawdown", ""),
          maxWithdrawalMoney: get(userData, "staffs.limit_money", ""),
          maxWithdrawalPercent: get(
            userData,
            "staffs.limit_allowable_percent_drawdown",
            ""
          ),
          maxType: get(userData, "staffs.is_limit_money_max_wallet", false),
          minWithdrawal: get(userData, "staffs.min_withdrawal", ""),
          startDate: get(userData, "staffs.start_date", ""),
          companyName: get(userData, "companies.company_name", ""),
          companyID: get(userData, "companies._id", ""),
          ABN: get(userData, "companies.abn", ""),
          address: get(userData, "companies.address", ""),
          employeeType: get(userData, "staffs.salary_wag", ""),
          userAddress: addressUser,
          calenderType: get(userData, "xero_pay_calendar.CalendarType", "")
            ? get(userData, "xero_pay_calendar.Name", "")
            : get(userData, "keypay_pay_schedule.name", "")
            ? get(userData, "keypay_pay_schedule.name", "")
            : get(userData, "pay_period_origination.name", ""),
          staffID: get(userData, "staffs._id", ""),
        })
      );
      // toast(response.data.message, { variant: "success" });
    }
    return response;
  };
}

// --------------------SAVE EMPLOYEE SETTINGS-----------------------
export function saveEmployeeSetting(staffID: any, payload: any) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/users/updateDrawdownAndMinimumWithdrawal/${staffID}`,
      payload
    );
    if (response) {
      toast(response.data.message, { variant: "success" });
      return response.data;
    }
  };
}
