import axios from "axios";
import { dispatch } from "store";
import { hideLoader, showLoader } from "store/reducers/dashboard";

// Create an instance of Axios
const publicApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_PRIVATE_BASE_URL,
});

// Axios request interceptor
publicApi.interceptors.request.use(
  (config) => {
    // set the loader state to true to turn on the loading animation
    dispatch(showLoader());

    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["service"] = "Web Admin";
    config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;

    return config;
  },
  (error) => {
    // hide loader
    dispatch(hideLoader());

    return Promise.reject(error);
  }
);

publicApi.interceptors.response.use(
  (response) => {
    // hide loader
    dispatch(hideLoader());

    return response;
  },
  (error) => {
    // hide loader
    dispatch(hideLoader());

    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default publicApi;
