import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { get } from "lodash";
import { dispatch } from "store";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";

export type emailSettingsProps = {
  emailType: string;
  businessUnitID: number;
  loader: boolean;
  error: null;
  logo: any[];
  templateType: string;
  invitedBanner: any[];
  logoHuds:any[];
  findOutURL: string;
  templateText: string;
  enteredCompanyName: string;
};
//initialState

const initialState: emailSettingsProps = {
  emailType: "",
  businessUnitID: 0,
  loader: false,
  error: null,
  logo: [],
  templateType: "",
  invitedBanner: [],
  logoHuds:[],
  findOutURL: "",
  templateText: "",
  enteredCompanyName: "",
};

// ==============================|| SLICE - Email Settings Tab ||============================== //
const getEmailSettingsData = createSlice({
  name: "getEmailSettingsData",
  initialState,
  reducers: {
    getEmailSettings(state, action) {
      let {
        emailType,
        businessUnitID,
        logo,
        templateType,
        templateText,
        invitedBanner,
        logoHuds,
        findOutURL,
        enteredCompanyName,
      } = action.payload;
      state.emailType = emailType || initialState.emailType;
      state.businessUnitID = businessUnitID || initialState.businessUnitID;
      state.logo = logo || initialState.logo;
      state.templateType = templateType || initialState.templateType;
      state.templateText = templateText || initialState.templateText;
      state.invitedBanner = invitedBanner || initialState.invitedBanner;
      state.logoHuds=logoHuds||initialState.logoHuds;
      state.findOutURL = findOutURL || initialState.findOutURL;
      state.enteredCompanyName =
        enteredCompanyName || initialState.enteredCompanyName;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearEmailSettings: () => initialState,
  },
});

export default getEmailSettingsData.reducer;

export const { getEmailSettings, isLoading, hasError, clearEmailSettings } =
  getEmailSettingsData.actions;

export function getEmailSettingType(companyId: string, payload: any) {
  return async () => {
    const response = await authApi.post(
      `v2/api/companies/${companyId}/EmailSettings`,
      payload
    );
    if (response.data) {
      const data = response.data;
      dispatch(
        getEmailSettings({
          emailType: get(data, "data.emailType", ""),
          templateType: get(data, "data.templateType", ""),
          templateText: get(data, "data.templateText", ""),
          findOutURL: get(data, "data.findOutUrl", ""),
          businessUnitID: get(data, "data._id", ""),
          invitedBanner: get(data, "data.inviteBanner", ""),
          logoHuds:get(data, "data.businessUnitLogo",""),
          enteredCompanyName: get(data, "data.companyName", ""),
        })
      );
      return data;
    }
    return response.data;
  };
}

// ==============================|| UPLOAD LOGO - Email Settings Tab ||============================== //

export function uploadLogo(companyId: string, payload: any) {
  return async () => {
    const response = await authApi.post(
      `v2/api/companies/${companyId}/EmailSettings/logo`,
      payload
    );
    if (response.data) {
      const data = response.data;
      toast(T.UPLOAD_SUCCESS, {
        variant: "success",
      });
      return data;
    }
    return response.data;
  };
}

// ==============================|| SAVE EMAIL SETTINGS - Email Settings Tab ||============================== //

export function saveEmailSettings(companyId: string, payload: any) {
  return async () => {
    const response = await authApi.put(
      `v2/api/companies/${companyId}/EmailSettings`,
      payload
    );
    if (response.data) {
      const data = response.data;
      toast(T.EMAIL_SETTINGS_HAS_BEEN_SAVED_SUCCESSFULY, {
        variant: "success",
      });
      dispatch(
        getEmailSettings({
          emailType: get(data, "data.emailType", ""),
          templateType: get(data, "data.templateType", ""),
          templateText: get(data, "data.templateText", ""),
          findOutURL: get(data, "data.findOutUrl", ""),
          businessUnitID: get(data, "data._id", ""),
          invitedBanner: get(data, "data.inviteBanner", ""),
          enteredCompanyName: get(data, "data.companyName", ""),
        })
      );
      return data;
    }
    return response.data;
  };
}
