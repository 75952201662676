// loaderSlice.js (Redux Toolkit slice file)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
    clearDashboard: (state) => initialState,
  },
});

export const { showLoader, hideLoader, clearDashboard } = dashboard.actions;
export default dashboard.reducer;
