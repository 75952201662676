//RTK
import { createSlice } from "@reduxjs/toolkit";

//Local imports
import { dispatch } from "store";
// import ServerConstants from "utils/ServerConstants";
import toast from "utils/ToastNotistack";

import authApi from "interceptor/authInterceptor";
import T from "utils/constants/T";
import { fetchCompanyDetails } from "./companyTabDetails";

export type whiteLabellingProps = {
    primaryColor: string,
    secondaryColor: string,
    logo: any[],
    loader: boolean,
    error: null,
    custom: boolean
}

//initialState 

const initialState: whiteLabellingProps = {
    primaryColor: "",
    secondaryColor: "",
    logo: [],
    loader: false,
    error: null,
    custom: false
}

// ==============================|| SLICE - WhiteLabelling Settings Tab ||============================== //
const whiteLabellingSettings = createSlice({
    name: "whiteLabellingSettings",
    initialState,
    reducers: {
        getWhiteLabellingSettings(state, action) {
            let { primaryColor, secondaryColor, logo, custom } = action.payload;
            state.primaryColor = primaryColor || initialState.primaryColor;
            state.secondaryColor = secondaryColor || initialState.secondaryColor;
            state.logo = logo || initialState.logo;
            state.custom = custom || initialState.custom
        },
        isLoading(state, action) {
            state.loader = action.payload;
        },
        hasError(state, action) {
            state.error = action.payload;
        },
        clearWhiteLabellingSettings: () => initialState
    }
});

export default whiteLabellingSettings.reducer;

export const { getWhiteLabellingSettings, isLoading, hasError, clearWhiteLabellingSettings } = whiteLabellingSettings.actions

export function UpdateWhiteLabelling(companyId: string, payload: any, navigator: any) {
    return async () => {
        await authApi.put(`/v2/api/companies/${companyId}/brandData`, payload).then((res) => {
            dispatch(fetchCompanyDetails(companyId));
            if (res) {
                toast(T.WHITELABELLING_SETTINGS_HAS_BEEN_SAVED_SUCCESSFULY, { variant: "success" });
                navigator("/companyDetails/whiteLabelling/view")
            }
        })
    }
}

export function deleteLogo(companyId: string) {
    return async () => {
        const response = await authApi.delete(`/v2/api/companies/${companyId}/logo`);
        if (response) {
            toast(T.LOGO_DELETED_SUCCESSFULLY, {
                variant: "success",
            });
            dispatch(fetchCompanyDetails(companyId));
        }

    }
}