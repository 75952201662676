import React, { useEffect, useReducer, useState } from "react";
import {
  Grid,
  Alert,
  Box,
  Button,
  InputBase,
  alpha,
  styled,
} from "@mui/material";
import MainCard from "components/MainCard";
import { dispatch, useSelector } from "store";
import ScrollX from "components/ScrollX";
import { PAGINATION } from "utils/constants/pagination";
import { get } from "lodash";
import Wallets from "./Wallets";
import { fetchWallets } from "store/reducers/wallets";
import { WarningFilled } from "@ant-design/icons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { exportData, formatAmount } from "utils/CommonMethods";
import toast from "utils/ToastNotistack";
import authApi from "interceptor/authInterceptor";
import { format } from "date-fns";
import Papa from "papaparse";
import SearchIcon from "@mui/icons-material/Search";
import T from "utils/constants/T";
import Loader from "components/Loader";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // width: '100%',
  border: "1px solid #d9d9d9",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
const WalletsPage = () => {
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
   const { systemCode } = useSelector((state: any) => state.companyTabData);
   console.log(systemCode)
  const companyId = get(selectedCompany, "_id", "");
  const result = useSelector((state) => state.wallets);
  const { wallets, page, pageSize, totalRecords, totalPages } = result;
  const { INITIAL_PAGE, ROWS_PER_PAGE, EXPORT_PAGE_SIZE } = PAGINATION;
  const [dataWindow, setDataWindow] = useState("CURRENT")

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      pageNumber: page,
      rowsPerPage: pageSize,
      employeeName: "",
      isLoading: false,
      payPeriodType: "current"
    }
  );

  const { rowsPerPage, employeeName, payPeriodType,isLoading } = localState;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (page: number) => {
    setLocalState({ page: page });
    dispatch(fetchWallets(page, rowsPerPage, companyId, employeeName,payPeriodType));
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
    dispatch(fetchWallets(INITIAL_PAGE, value, companyId, employeeName,payPeriodType));
  };

  const exportWallets: any = async (
    page: number,
    pageSize: number,
    companyId: string
  ) => {
    // fetch wallets
    const response = await authApi.get(
      `/v2/api/users/wallets?page=${page}&page_size=${pageSize}&company_id=${companyId}`
    );
    const { data: employees, totalPages, page: currentPage } = response.data;

    // check for page size, if current page is equal or greater than totalPages, then return
    if (currentPage + 1 >= totalPages) return employees;

    // recursiverly fetch all wallets and return
    const nextPage = await exportWallets(page + 1, pageSize, companyId);
    return [...employees, ...nextPage];
  };

  const formattedExportData = (wallets: any) => {
    const formattedData: any = [];

    wallets.forEach((wallet: any) => {
      const startDate = get(wallet, "start_date", "");
      const endDate = get(wallet, "end_date", "");
      // const createdDate = get(wallet, "created_date", "");
      const startDateFormatted = new Date(startDate);
      const endDateFormatted = new Date(endDate);
      // const createdDateFormatted = new Date(createdDate)
      // const createdDateText = format(createdDateFormatted, "dd MMM yyyy h:mm a");
      const startDateText = format(startDateFormatted, "dd MMM yyyy");
      const endDateText = format(endDateFormatted, "dd MMM yyyy");
      const accuredAmount = formatAmount(
        get(wallet, "pay_calculates.accured_amount", "")
      );
      const withdrawalsTotal = formatAmount(
        get(wallet, "pay_calculates.withdrawals_total", "")
      );

      const salaryBalance =
        get(wallet, "pay_calculates.accured_amount", "") +
        get(wallet, "pay_calculates.withdrawals_total", "");

      formattedData.push({
        "First Name": get(wallet, "staff_infor.first_name", ""),
        "Last Name": get(wallet, "staff_infor.last_name", ""),
        "Full Name": get(wallet, "staff_infor.fullname", ""),
        Email: get(wallet, "staff_infor.email", ""),
        "Payroll Id": get(wallet, "staff_infor.system_employee_id", ""),
        // "Date & Time": createdDateText,
        "Pay Period": `${startDateText} To ${endDateText}`,
        "Balance Available": formatAmount(
          get(wallet, "pay_calculates.amount_available", "")
        ),
        "Salary (Cycle)": `$${salaryBalance}`,
        "CashD Limit": formatAmount(
          get(wallet, "pay_calculates.limit_amount", "")
        ),
        "Current Fee": formatAmount(
          get(wallet, "pay_calculates.current_fee", "")
        ),
        "CashD Withdrawals": withdrawalsTotal,
        "Salary Balance (cycle)": accuredAmount,
      });
    });

    return formattedData;
  };

  const handleCSVExport = async () => {
    try {
      setLocalState({ isLoading: true });
      const employees = await exportWallets(
        INITIAL_PAGE,
        EXPORT_PAGE_SIZE,
        companyId
      );
      const finalExportData = formattedExportData(employees);
      const csvData = Papa.unparse(finalExportData);
      exportData(csvData, "data.csv", "text/csv;charset=utf-8;");
      setLocalState({ isLoading: false });
    } catch (error) {
      toast(get(error, "message", ""), { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      setLocalState({ isLoading: true });
      await dispatch(
        fetchWallets(INITIAL_PAGE, ROWS_PER_PAGE, companyId, employeeName,payPeriodType)
      );
      setLocalState({ isLoading: false });
    };

    if (companyId) fetchWalletData();
  }, [companyId,payPeriodType]);

  let debounceTimer: any = null;
  const handleEmployeeNameSearch = (employeeName: any) => {
    const { name, value } = employeeName.target;
    setLocalState({ [name]: value });
    clearTimeout(debounceTimer);
    setLocalState({ isLoading: false });

    if (value?.length >= 3 || value?.length === 0) {
      setLocalState({ isLoading: true });
      debounceTimer = setTimeout(() => {
        dispatch(fetchWallets(INITIAL_PAGE, ROWS_PER_PAGE, companyId, value,payPeriodType));
        setLocalState({ isLoading: false });
      }, 500);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {!companyId && (
            <Alert color="warning" icon={<WarningFilled />}>
              Please select a company first
            </Alert>
          )}

          {companyId && (
            <MainCard>
              <Box sx={{ display: "flex" }}>
                <Grid container>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder={T.EMPLOYEE}
                      name="employeeName"
                      value={employeeName}
                      inputProps={{ "aria-label": "search" }}
                      onChange={handleEmployeeNameSearch}
                    />
                  </Search>
                </Grid>
                <Grid container justifyContent="flex-end" gap={1}>
                {companyId && systemCode === T.ASTUTE.toUpperCase() && (
                  <>
                <Button
                variant={dataWindow === "CURRENT" ? "contained" : "text"}
                onClick={(e) => {setLocalState({payPeriodType: "current"}); setDataWindow("CURRENT")}}
              >
                Current
              </Button>
              <Button
                variant={dataWindow === "LAST" ? "contained" : "text"}
                onClick={(e) => {setLocalState({payPeriodType: "last"}); setDataWindow("LAST")}}
              >
                Last
              </Button>
              </>)}
                  <Box>
                    <Button
                      startIcon={<FileDownloadOutlinedIcon />}
                      variant="contained"
                      onClick={handleCSVExport}
                      disabled={!totalRecords ? true : false}
                    >
                      {" "}
                      Export
                    </Button>
                  </Box>
                </Grid>
              </Box>

              <ScrollX>
                <Wallets
                  wallets={wallets}
                  dataWindow={dataWindow}
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  totalPages={totalPages}
                  open={open}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </ScrollX>
            </MainCard>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default WalletsPage;
