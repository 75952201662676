//React
import { useEffect, useReducer } from 'react';

//react-redux
import { useDispatch, useSelector } from "react-redux";

//Material-UI
import { Typography } from '@mui/material';

//lodash
import { get } from 'lodash';

//react-router
import { useNavigate } from 'react-router';

//local-imports
import EditPayrollSettings from "./EditPayrollSettings";
import T from "utils/constants/T";
import EditPayrollSettingsTimeSheet from "./EditPayrollSettingsTimeSheet";
import { fetchPayrollSettingsTabData, savePayrollSettingsTabData } from 'store/reducers/companyPayrollSettings';
import { getCompanyTabData } from 'store/reducers/companyTabDetails';

const EditPayrollTab = () => {
    const dispatch= useDispatch();
    const navigate = useNavigate();
    const navigator =(path: string)=>{
        navigate(path);
    }
    const { companyName, companyEmail, companyPhone, companyMemberSince, city, abn, systemName, contactPerson, allowablePercentageDrawdown, minimumWithdrawl, transactionFee, transactionFeeType, floatAlertThresholdAmount, totalNumberOfEmployees, totalAmountPerCompany, floatAlertRecipients, systemPayrollId, systemPayrollCode, cashDCapitalId: storedCashDCapitalId, cashDFeeId:storedCashDFeeId, includeSubmitted:storedIncludeSubmitted } = useSelector((state: any)=>state.companyTabData);
    const {selectedCompany} =useSelector((state: any)=>state.dashboardSearch);
    const selectedCompanyId = get(selectedCompany,"_id","");
    const { deductionFileCategories } =useSelector((state: any)=>state.payrollSettingsTabData);
    
    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any)=>({...prevState, ...newState}),
        {
            cashDCapital:{},
            cashDFee:{},
            includeSubmitted:false
        }
        )

    const {
        cashDCapital,
        cashDFee,
        includeSubmitted
    }= localState;
    
    
    useEffect(()=>{
        dispatch(fetchPayrollSettingsTabData(selectedCompanyId,systemPayrollCode));
    },[])
    
    useEffect(() => {
        if(storedCashDFeeId && deductionFileCategories.length!==0)
        {
            setLocalState({
                cashDCapital: getCategory(storedCashDCapitalId),
                cashDFee: getCategory(storedCashDFeeId),
            })
        }
        if(storedIncludeSubmitted)
        {
            setLocalState({includeSubmitted:storedIncludeSubmitted});
        }
    }, [storedCashDFeeId, storedIncludeSubmitted])
    
    const getCategory =(id:string)=>{
        const category = deductionFileCategories.find((item: any)=>item._id===id);
        return category;
    }
    
    const onHandleAutoCompleteChange =(type:string, newValue:any)=>{
        setLocalState({ [type]: newValue });
    }
    

   
    const handleSave = () =>{
        if(systemPayrollCode ===T.KEYPAY.toUpperCase())
        {
            const payload ={
                code:systemPayrollCode,
                salaryAdvanceId: get(cashDCapital,"_id",""),
                salaryFeeId: get(cashDFee,"_id","")
            }
            dispatch(savePayrollSettingsTabData(payload,selectedCompanyId, navigator));
            dispatch(getCompanyTabData({ companyName, companyEmail, companyPhone, companyMemberSince, city, abn, systemName, contactPerson, allowablePercentageDrawdown, minimumWithdrawl, transactionFee, transactionFeeType, floatAlertThresholdAmount, totalNumberOfEmployees, totalAmountPerCompany, floatAlertRecipients, systemPayrollId, systemPayrollCode,  includeSubmitted:storedIncludeSubmitted, cashDCapitalId: get(cashDCapital,"_id",""), cashDFeeId:get(cashDFee,"_id","") }));
        }
        else if(systemPayrollCode ===T.ASTUTE.toUpperCase())
        {
            const payload ={
                code:systemPayrollCode,
                isSystemApproveProcess:includeSubmitted
            }
            dispatch(savePayrollSettingsTabData(payload,selectedCompanyId, navigator));
            dispatch(getCompanyTabData({ companyName, companyEmail, companyPhone, companyMemberSince, city, abn, systemName, contactPerson, allowablePercentageDrawdown, minimumWithdrawl, transactionFee, transactionFeeType, floatAlertThresholdAmount, totalNumberOfEmployees, totalAmountPerCompany, floatAlertRecipients, systemPayrollId, systemPayrollCode,  includeSubmitted:includeSubmitted, cashDCapitalId:storedCashDCapitalId, cashDFeeId:storedCashDFeeId }))
        }
        
    }

    const handleCheck=(e:any)=>{
        const { name, checked } = e.target;
        setLocalState({[name]:checked})
    }
    return (
        <>
            {
                systemPayrollCode === T.ASTUTE.toUpperCase()
                ?
                <EditPayrollSettingsTimeSheet
                    includeSubmitted={includeSubmitted}
                    handleCheck={handleCheck}
                    handleSave={handleSave}
                    companyName={companyName}
                />
                :
                systemPayrollCode === T.KEYPAY.toUpperCase()
                ?
                <EditPayrollSettings 
                    onHandleAutoCompleteChange={onHandleAutoCompleteChange}  
                    cashDCapital={cashDCapital} 
                    cashDFee={cashDFee} 
                    handleSave={handleSave}
                    deductionCategories={deductionFileCategories} 
                    companyName={companyName}
                />
                :
                <Typography align='center' pt={5}>
                    Un-Identified Payroll Found
                </Typography>
            }
        </>
    );
};

export default EditPayrollTab;