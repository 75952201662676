//Material UI
import { Button, Grid } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';



//Local Imports
import MainCard from "components/MainCard";
import T from "utils/constants/T";
import { StyledBodyTypography } from '../CompanyTab/CompanyInfo';
import CashDCard from 'components/common/CashDCard';
import CashDAutocomplete from 'components/common/CashDAutocomplete';

//CSS
import './payrollSettings.css';
import '../../companyDetails.css';
import { get } from 'lodash';
interface EditPayrollSettingsProps{
    deductionCategories: any[],
    cashDCapital :any,
    cashDFee :any,
    onHandleAutoCompleteChange: any
    handleSave: any,
    companyName: string
}
const EditPayrollSettings = ({deductionCategories, cashDCapital, cashDFee, onHandleAutoCompleteChange, handleSave, companyName}:EditPayrollSettingsProps) => {
    
    
    return (
        <MainCard 
            title={companyName} 
            secondary={
                <>
                    {/* <Button 
                        variant="outlined"
                        className='main-card-button' 
                        startIcon={<CachedIcon />}
                    >
                        {T.SYNC_PAYROLL}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                    <Button
                        variant="contained"
                        className='main-card-button' 
                        onClick={handleSave}
                        >
                        {T.SAVE}
                    </Button>
                </>
            }>

                <CashDCard borderRadius='20px'>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <StyledBodyTypography>
                                {T.DEDUCTION_FILE_CATEGORY_SELECTION}
                            </StyledBodyTypography>  
                        </Grid>
                            <Grid item xs={3}/>
                            <Grid item xs={6}>
                                <Grid container className='align-field'>
                                    <Grid item xs={4}>
                                        <StyledBodyTypography>{T.CASH_D_CAPITAL}</StyledBodyTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CashDAutocomplete 
                                            placeholder={T.CHOOSE_DEDUCTION_CATEGORY}
                                            fullWidth
                                            listDetails={deductionCategories}
                                            value={cashDCapital}
                                            getByLabelText={(option: any) => get(option,"name","")}
                                            onHandleChange={(event: any, newValue: any) =>
                                            onHandleAutoCompleteChange(
                                                "cashDCapital",
                                                newValue
                                            )
                                        }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid item xs={3}/>
                            <Grid item xs={6}>
                                <Grid container className='align-field'>
                                    <Grid item xs={4}>
                                        <StyledBodyTypography>{T.CASH_D_FEE}</StyledBodyTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CashDAutocomplete 
                                                placeholder={T.CHOOSE_DEDUCTION_CATEGORY}
                                                fullWidth
                                                listDetails={deductionCategories}
                                                value={cashDFee}
                                                getByLabelText={(option: any) => get(option,"name","")}
                                                onHandleChange={(event: any, newValue: any) =>
                                                onHandleAutoCompleteChange(
                                                    "cashDFee",
                                                    newValue
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}/>
                    </Grid>
                </CashDCard>
        </MainCard>
    );
};

export default EditPayrollSettings;