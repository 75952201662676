//Material UI
import { Button, Grid, Divider } from '@mui/material';
//local
import MainCard from 'components/MainCard';
import CashDCard from 'components/common/CashDCard';
import T from 'utils/constants/T';
import { StyledBodyTypography, StyledTitleTypography } from '../CompanyTab/CompanyInfo';

//react-router
import { useNavigate } from 'react-router';

//react-redux
import { useDispatch, useSelector } from 'react-redux';

//CSS
import '../../companyDetails.css';
import { useEffect } from 'react';
import { get } from 'lodash';
import { fetchFinancialSettingsTabData } from 'store/reducers/companyFinancialSettingsTab';
const ViewFinancialSettings = () => {
    const dispatch= useDispatch();
    const {selectedCompany}= useSelector((state: any)=>state.dashboardSearch);
    const { companyName } = useSelector((state: any) => state.companyTabData);
    const selectedCompanyId= get(selectedCompany,"_id","");
    const navigate= useNavigate();
    const { monthlyActiveEmployees, totalAmountDrawnOnThisPeriod } = useSelector((state:any)=>state.financialSettingsTabData);

    const { totalNumberOfEmployees, totalAmountPerCompany, allowablePercentageDrawdown,
        minimumWithdrawl, transactionFee, transactionFeeType }= useSelector((state: any)=>state.companyTabData)
    // const { totalNumberOfEmployees, totalAmountPerCompany, allowablePercentageDrawdown, floatAlertThresholdAmount,
    //     minimumWithdrawl, transactionFee, transactionFeeType, floatAlertRecipients }= useSelector((state: any)=>state.companyTabData)
     
    const balanceAvailable = totalAmountPerCompany - totalAmountDrawnOnThisPeriod;
        
    useEffect(()=>{
        dispatch(fetchFinancialSettingsTabData(selectedCompanyId));
    },[]);

    const handleEdit=()=>{
        navigate("/companyDetails/financialSettings/edit")
    }
    return (
        <MainCard
        title={companyName}
        secondary={
            <Button 
              variant='contained'
              onClick={handleEdit}
              className='main-card-button'
            >
              {T.EDIT}
            </Button>
        }
        >
            <CashDCard borderRadius='20px'>
                <Grid container rowSpacing={2}>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.TOTAL_NO_OF_EMPLOYEES}</StyledTitleTypography>
                        <StyledBodyTypography>{totalNumberOfEmployees}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.MONTHLY_ACTIVE_EMPLOYEES}</StyledTitleTypography>
                        <StyledBodyTypography>{monthlyActiveEmployees}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.TOTAL_AMOUNT_PER_COMPANY}</StyledTitleTypography>
                        <StyledBodyTypography>{totalAmountPerCompany}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.TOTAL_AMOUNT_DRAWN_ON_THIS_PERIOD}</StyledTitleTypography>
                        <StyledBodyTypography>{totalAmountDrawnOnThisPeriod}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.ALLOWABLE_PERCENTAGE_DRAWDOWN}</StyledTitleTypography>
                        <StyledBodyTypography>{allowablePercentageDrawdown}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{`${T.BALANCE_AVAILABALE} (${T.DOLLAR})`}</StyledTitleTypography>
                        <StyledBodyTypography>{balanceAvailable}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.TRANSACTION_FEE}</StyledTitleTypography>
                        {transactionFeeType === "$"?
                        <StyledBodyTypography>{`${transactionFeeType}${transactionFee} `}</StyledBodyTypography>
                        :
                        <StyledBodyTypography>{`${transactionFee} ${transactionFeeType}`}</StyledBodyTypography>

                        }
                    </Grid>
                    {/* <Grid item xs={6}>
                        <StyledTitleTypography>{T.FLOAT_ALERT_THRESHOLD_AMOUNT}</StyledTitleTypography>
                        <StyledBodyTypography>{floatAlertThresholdAmount}</StyledBodyTypography>
                    </Grid> */}
                    <Grid item xs={6}>
                        <StyledTitleTypography>{`${T.MINIMUM_WITHDRAWAL} (${T.DOLLAR})`}</StyledTitleTypography>
                        <StyledBodyTypography>{minimumWithdrawl}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <StyledTitleTypography>{T.FLOAT_ALERT_RECIPIENTS}</StyledTitleTypography>
                        <StyledBodyTypography>{floatAlertRecipients.toString()}</StyledBodyTypography>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Divider />
                    </Grid> */}
                </Grid>
            </CashDCard>
        </MainCard>
    );
};

export default ViewFinancialSettings;