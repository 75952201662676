import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";
import { dispatch } from "store";

const initialState = {
  wallets: [],
  page: 0,
  pageSize: 100,
  totalRecords: 0,
  totalPages: 0,
};

const wallets = createSlice({
  name: "wallets",
  initialState,
  reducers: {
    getWallets: (state, action) => {
      const { wallets, page, pageSize, totalRecords, totalPages } =
        action.payload;
      state.wallets = wallets;
      state.page = page;
      state.pageSize = pageSize;
      state.totalRecords = totalRecords;
      state.totalPages = totalPages;
    },
    clearWallet: () => initialState,
  },
});

export const { getWallets, clearWallet } = wallets.actions;

export default wallets.reducer;

export function fetchWallets(
  page: number,
  pageSize: number,
  companyId: string,
  employeeName: string,
  payPeriodType?:string
) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/users/wallets?page=${page}&page_size=${pageSize}&company_id=${companyId}&search_key=${employeeName}&payperiod_type=${payPeriodType}`
    );
    if (response.data) {
      dispatch(
        getWallets({
          wallets: response.data,
          page: response.data.page,
          pageSize: response.data.page_size,
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        })
      );
    }
  };
}
