//Lodash
import noop from "lodash/noop";

//Material-UI
import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";


//Local imports
import TableHeader from "../TableHeader";
import { PAGINATION } from "utils/constants/pagination";
import MasterPagination from "utils/MasterPagination";
import DeductionTableBody from "./DeductionTableBody";
import deductionTableHeader from "utils/constants/deductions";
import { useSelector } from 'store';
import { get } from "lodash";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

interface DeductionTableProps {
  page?: number,
  onPageChange?: (page: number) => void;
  rowsPerPage?: number;
  handleExportViewDeduction?: any,
  handleExportViewTransaction?: any,
  deductionViewList?: any[],
  onRowsPerPageChange?: (page: number) => void;
  totalTableRowsCount?: number;
  totalPageCount?: number;
  allTableRows?: any;
  open?: boolean,
  anchorEl?: any,
  handleClick?: any,
  handleClose?: any,
  handleViewDeduction?: any,
  handleRunDeduction?: any,
  handleReverseDeduction?: any,

}
const DeductionTable = ({
  page = INITIAL_PAGE,
  onPageChange = noop,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange = noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleExportViewDeduction = noop,
  handleExportViewTransaction = noop,
  deductionViewList = [],
  allTableRows = [],
  anchorEl,
  open = false,
  handleClick = noop,
  handleClose = noop,
  handleViewDeduction = noop,
  handleRunDeduction = noop,
  handleReverseDeduction = noop,
}: DeductionTableProps) => {
  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const { systemPayrollCode } = useSelector(
    (state: any) => state.companyTabData
  );
  return (
    <Card
      elevation={0}
      sx={{
        // p: "0px 8px",
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
          sx={{ height: "inherit", overflowY: "auto" }}
        >
          <TableView stickyHeader>
            <TableHeader columns={deductionTableHeader(selectedCompanyId, systemPayrollCode)} />
            <DeductionTableBody
              page={page}
              rowsPerPage={rowsPerPage}
              totalTableRowsCount={totalTableRowsCount}
              totalPageCount={totalPageCount}
              allTableRows={allTableRows}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              handleExportViewDeduction={handleExportViewDeduction}
              handleExportViewTransaction={handleExportViewTransaction}
              deductionViewList={deductionViewList}
              handleViewDeduction={handleViewDeduction}
              handleRunDeduction={handleRunDeduction}
              handleReverseDeduction={handleReverseDeduction}
            />
          </TableView>
        </TableContainer>
      </Box>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default DeductionTable;
