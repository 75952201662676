import T from "./T";

const { EMPLOYEE_NAME, DATE, WORKING_HOURS, PAY_RATE, STATUS } = T;

export const TIMESHEET_TABLE_HEADER = [
  { label: EMPLOYEE_NAME },
  { label: DATE },
  { label: WORKING_HOURS },
  { label: PAY_RATE },
  { label: STATUS },
];
