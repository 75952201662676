//Material-UI
import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";


//Local imports
import ViewBankingTableHeader from "./ViewBankingTableHeader";
import ViewBankingTableBody from "./ViewBankingTableBody";
import { VIEW_BANKING_TABLE_HEADER } from "utils/constants/companyDetails";

interface ViewBankingTableProps {
    accountName?:string,
    fundingType?: string,
    nameOfBank?: string,
    bsb?: string,
    accountNumber?: string,
    balance?: string,
    interestRate?: string

}
const ViewBankingTable = ({
    accountName= "",
    fundingType= "",
    nameOfBank= "",
    bsb= "",
    accountNumber= "",
    balance= "",
    interestRate= ""
}:ViewBankingTableProps) => {
  return (
    <Card
      elevation={0}
      sx={{
        // p: "0px 8px",
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          // sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
          sx={{ height: "inherit", overflow: "auto", padding: "0px" }}
        >
          <TableView stickyHeader>
            <ViewBankingTableHeader columns={VIEW_BANKING_TABLE_HEADER} />
            <ViewBankingTableBody
              accountName={accountName}
              fundingType={fundingType}
              nameOfBank={nameOfBank}
              bsb={bsb}
              accountNumber={accountNumber}
              balance={balance}
              interestRate={interestRate}
            />
          </TableView>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default ViewBankingTable;
