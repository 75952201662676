//RTK
import { createSlice } from "@reduxjs/toolkit";

//Lodash
import { get } from "lodash";

//Axios
// import axios from "utils/axios";

//Local imports
// import ServerConstants from "utils/ServerConstants";
import { dispatch } from "store";
import { PAGINATION } from "utils/constants/pagination";
// import { cashdCurrentUser } from "utils/constants/validations";
// import authApi from "interceptor/authInterceptor";
// import publicApi from "interceptor/publicInterceptor";
import authApi from "interceptor/authInterceptor";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
// const { SERVER_URL } = ServerConstants;

// types
export type transactionDataProps = {
  transactionTableList: [];
  countStastics: [];
  totalTransactionItems: number;
  totalTransactionPages: number;
  pageNo: number;
  pageSize: number;
  //   selectedCompany: {},
  loader: Boolean;
  error: null;
};

// initial state
const initialState: transactionDataProps = {
  transactionTableList: [],
  totalTransactionItems: 10,
  totalTransactionPages: 10,
  pageNo: INITIAL_PAGE,
  pageSize: ROWS_PER_PAGE,
  //   selectedCompany: {},
  loader: false,
  error: null,
  countStastics: []
};

// ==============================|| SLICE - TRANSACTION ||============================== //

const transactionData = createSlice({
  name: "transactionData",
  initialState,
  reducers: {
    getTransactionData(state, action) {
      let {
        transactionTableList,
        countStastics,
        totalTransactionItems,
        totalTransactionPages,
        pageNo,
        pageSize,
      } = action.payload;
      state.transactionTableList =
        transactionTableList || initialState.transactionTableList;
      state.countStastics =
        countStastics || initialState.countStastics;
      state.totalTransactionItems = totalTransactionItems;
      state.totalTransactionPages = totalTransactionPages;
      state.pageNo = pageNo || initialState.pageNo;
      state.pageSize = pageSize || initialState.pageSize;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearTransactions: () => initialState,
  },
});

export default transactionData.reducer;

export const { getTransactionData, clearTransactions } =
  transactionData.actions;

export function fetchTransactionData(
  page: any,
  pageSize: any,
  fromDate: any,
  toDate: any,
  selectedCompanyId: string,
  pay_period_id: string | null,
  schedule_Id: string | null,
  business_Unit_Id: string | null,
  payrollId: string,
  employee: string
) {
  const startDate = !fromDate ? "" : fromDate;
  const endDate = !toDate ? "" : toDate;
  const size = pageSize < 5 ? 10 : pageSize;
  const payPeriodId = pay_period_id ? pay_period_id : "";
  const scheduleId = schedule_Id ? schedule_Id : "";
  const businessUnitId = business_Unit_Id ? business_Unit_Id : "";
  return async () => {
    const response = await authApi.get(
      `/v2/api/timesheets/getAllDeductions?page=${page}&pageSize=${size}&company_id=${selectedCompanyId}&pay_period_id=${payPeriodId}&schedule_id=${scheduleId}&business_unit_id=${businessUnitId}&startDate=${startDate}&endDate=${endDate}&mode=0&isAll=0&payroll_id=${payrollId}&employee_name=${employee}`
    );
    if (response.data) {
      console.log("DATA", response);
      const { data, totalRecords, totalPages, countStastics } = get(response, "data", {});
      dispatch(
        getTransactionData({
          transactionTableList: data,
          countStastics: countStastics,
          totalTransactionItems: totalRecords,
          totalTransactionPages: totalPages,
          pageNo: page,
          pageSize: size,
        })
      );
    }
  };
}
