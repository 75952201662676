//react
import { Children } from 'react';

//Material-UI
import {
    TableRow,
    TableCell,
    TableBody,
    styled,
    Typography
  } from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  
  
  
  //Date-fns
  // import { format } from 'date-fns';
  
  //Local imports
  import { memo } from "utils/memo";
import { get, noop } from 'lodash';
import T from 'utils/constants/T';
import { maskNumber } from 'utils/CommonMethods';
  
  
  const StyledTableCell = styled(TableCell)(({theme}) => ({
    borderTop: "inherit",
    overflow: "hidden",
    padding: "8px 0px 8px 10px",
    // flexWrap: "wrap"
    // ...theme.typography.subtitle1, 
  }));
  
  // const StyledTableRow = styled(TableRow)(() => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: "white",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "white",
  //   },
  // }));
  
  interface EditBankingTableBodyProps{
      allTableRows: any[],
      handleAddChangeLender: (id: string,accountName: string)=>void,
  }
  const EditBankingTableBody = ({
      allTableRows=[],
      handleAddChangeLender=noop
  }:EditBankingTableBodyProps) => {


    
    return (
      
      <TableBody>
        {Children.toArray(
          allTableRows.map((record, index) => {
            const lenderId= get(record,"lenderId","");
            const lenderAccountName= get(record,"accountName","");
            return (
          <TableRow sx={{ background: "white" }} key={index} >
            <StyledTableCell onClick={()=>handleAddChangeLender(lenderId,lenderAccountName)}>
                <AddCircleIcon
                fontSize='medium'
                className='add-icon'
                />
            </StyledTableCell>
            {/* <StyledTableCell>{index+1}</StyledTableCell> */}
            <StyledTableCell>{record.accountName}</StyledTableCell>
            <StyledTableCell>{record.fundingType}</StyledTableCell>
            <StyledTableCell>{record.nameOfBank}</StyledTableCell>
            <StyledTableCell>{record.bsb}</StyledTableCell>
            <StyledTableCell>{record.accountNumber ? maskNumber(record.accountNumber): ""}</StyledTableCell>
            <StyledTableCell>{record.balance}</StyledTableCell>
            <StyledTableCell>{record.interestRate}</StyledTableCell>
          </TableRow>
          );
        })
      )}
  
        {allTableRows && allTableRows.length === 0 && (
          <TableRow>
            <StyledTableCell colSpan={10} sx={{ border: "none" }}>
              <Typography variant="body1" textAlign="center">
                {T.NO_RECORDS}
              </Typography>
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  
  
  
  export default memo(EditBankingTableBody);
  