import { createSlice } from "@reduxjs/toolkit";
import { getCurrentTableParams } from "data/transaction/transactionTableSelector";
import authApi from "interceptor/authInterceptor";
import { get } from "lodash";
import { dispatch } from "store";
import toast from "utils/ToastNotistack";
import T from "utils/constants/T";
import { PAGINATION } from "utils/constants/pagination";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
let abortController = new AbortController();

// types
export type deductionDataProps = {
  deductionTableList: any[];
  deductionViewList: any[];
  deductionExportTableList: any[];
  totalDeductionItems: number;
  totalViewDeductionItems: number;
  totalViewDeductionPages: number;
  totalDeductionPages: number;
  totalDeductionExportPages: number;
  viewPageNo: number;
  viewPageSize: number;
  pageNo: number;
  pageSize: number;
  loader: Boolean;
  error: null;
};

// initial state
const initialState: deductionDataProps = {
  deductionTableList: [],
  deductionViewList: [],
  deductionExportTableList: [],
  totalViewDeductionItems: 10,
  totalDeductionItems: 10,
  totalViewDeductionPages: 1,
  totalDeductionPages: 10,
  totalDeductionExportPages: 0,
  viewPageNo: INITIAL_PAGE,
  viewPageSize: ROWS_PER_PAGE,
  pageNo: INITIAL_PAGE,
  pageSize: ROWS_PER_PAGE,
  loader: false,
  error: null,
};

// ==============================|| SLICE - DEDUCTOINS ||============================== //

const deductionData = createSlice({
  name: "deductionData",
  initialState,
  reducers: {
    getDeductionData(state, action) {
      let {
        deductionTableList,
        totalDeductionItems,
        totalDeductionPages,
        pageNo,
        pageSize,
      } = action.payload;
      state.deductionTableList =
        deductionTableList || initialState.deductionTableList;
      state.totalDeductionItems = totalDeductionItems;
      state.totalDeductionPages = totalDeductionPages;
      state.pageNo = pageNo || initialState.pageNo;
      state.pageSize = pageSize || initialState.pageSize;
    },
    getViewDeductionData(state, action) {
      let {
        deductionViewList,
        totalViewDeductionItems,
        totalViewDeductionPages,
        viewPageSize,
        viewPageNo,
      } = action.payload;
      state.deductionViewList =
        deductionViewList || initialState.deductionViewList;
      state.totalViewDeductionItems =
        totalViewDeductionItems || initialState.totalViewDeductionItems;
      state.totalViewDeductionPages =
        totalViewDeductionPages || initialState.totalViewDeductionPages;
      state.viewPageSize = viewPageSize;
      state.viewPageNo = viewPageNo;
    },
    getExportDeductionData(state, action) {
      let { deductionExportTableList, totalDeductionExportPages } =
        action.payload;
      state.deductionExportTableList =
        deductionExportTableList || initialState.deductionExportTableList;
      state.totalDeductionExportPages =
        totalDeductionExportPages || initialState.totalDeductionExportPages;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    resetDeductions: () => initialState,
  },
});

export default deductionData.reducer;

export const {
  getDeductionData,
  getViewDeductionData,
  getExportDeductionData,
  resetDeductions,
} = deductionData.actions;

// ==============================|| GET ALL DEDUCTIONS ||============================== //

export function fetchDeductionData(
  page: any,
  pageSize: any,
  companyId: string,
  fromDate: any,
  toDate: any,
  pay_period_id: any,
  schedule_id: string,
  business_unit_id: string,
  deduction_status: string | null
) {
  const startDate = !fromDate ? "" : fromDate;
  const endDate = !toDate ? "" : toDate;
  const size = pageSize < 5 ? 10 : pageSize;
  const payPeriodId = pay_period_id ? pay_period_id : "";
  const scheduleId = schedule_id ? schedule_id : "";
  const businessUnitId = business_unit_id ? business_unit_id : "";
  const deductionStatus =
    deduction_status === T.SENT
      ? true
      : deduction_status === T.UNSENT
      ? false
      : "";
  return async () => {
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    abortController = new AbortController();

    const response = await authApi.get(
      `/v2/api/companies/DeductionFiles?company_id=${companyId}&page=${page}&pageSize=${size}&from_date=${startDate}&to_date=${endDate}&pay_period_id=${payPeriodId}&schedule_id=${scheduleId}&business_unit_id=${businessUnitId}&deduction_status=${deductionStatus}`,
      {
        signal: abortController.signal, // Assign the cancel token to the request
      }
    );
    if (response.data) {
      const { allTableRows, totalTableRowsCount, totalPageCount } =
        getCurrentTableParams(get(response, "data", {}));

      dispatch(
        getDeductionData({
          deductionTableList: allTableRows,
          totalDeductionItems: totalTableRowsCount,
          totalDeductionPages: totalPageCount,
          pageNo: page,
          pageSize: size,
        })
      );
    }
  };
}

// ==============================|| VIEW DEDUCTIONS ||============================== //

export function fetchViewDeductionData(
  page: any,
  pageSize: any,
  companyId: any,
  payPeriodId: any,
  deductionStatus: any
) {
  const size = pageSize < 5 ? 10 : pageSize;
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/${companyId}/DeductionFiles/${payPeriodId}?page=${page}&pageSize=${size}&deduction_type=${deductionStatus}`
    );
    if (response.data) {
      const { totalItem, totalPage, page, result, pageSize } = response.data;
      dispatch(
        getViewDeductionData({
          deductionViewList: result,
          totalViewDeductionItems: totalItem,
          totalViewDeductionPages: totalPage,
          viewPageNo: page,
          viewPageSize: pageSize,
        })
      );
    }
  };
}

// ==============================|| RUN DEDUCTIONS ||============================== //

export function runDeduction(companyId: any, payPeriodId: any) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/companies/DeductionFiles/runDeduction`,
      {
        companyId,
        payPeriodId,
      }
    );
    if (response.data) {
      toast(T.DEDUCTION_RUN_SUCCESSFULLY, { variant: "success" });
    }
  };
}

// ==============================|| REVERSE DEDUCTIONS ||============================== //

export function reverseDeduction(companyId: string, payPeriodId: string) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/companies/${companyId}/DeductionFiles/${payPeriodId}/undo`
    );
    if (response.data) {
      toast(T.DEDUCTION_REVERSED_SUCCESSFULLY, { variant: "success" });
    }
  };
}
