import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, ListItemButton } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { DRAWER_WIDTH } from 'config';
import T from 'utils/constants/T';
import { useSelector } from 'react-redux';
import { RootStateProps } from 'types/root';

import Admincenter from 'assets/images/icons/admincenter';
// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
  window?: () => Window;
  handleDrawerToggle?: () => void;
}

const MainDrawer = ({ open, handleDrawerToggle, window }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  const AdminSupport = () => {
    return (
      <Box sx={{
        backgroundColor: '#e6f7ff',
        textAlign: 'center',
        "&:hover": {
         backgroundColor: '#e6f7ff',
        }
      }}>
        <ListItemButton sx={{ display: 'inline-flex', fontSize: '20px',"&:hover": {
         backgroundColor: '#e6f7ff',color: '#1890ff'
        } }}
          onClick={() => alert(T.NO_PERMISSION)}
        > <Admincenter />  { drawerOpen ? "Admin Center": ""} </ListItemButton> 
      </Box>
    )
  }
  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          {drawerHeader}
          {drawerContent}
          {<AdminSupport />}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit'
            }
          }}
        >
          {drawerHeader}
          {drawerContent}
          {<AdminSupport />}
        </Drawer>
      )}
    </Box>
  );
};

export default MainDrawer;
