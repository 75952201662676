import T from "./T";

const {
  DOLLAR,
  PERCENTAGE,
  LIVE,
  TEST,
  TRUE,
  FALSE,
  LENDER_ACCOUNT_NAME,
  FUNDING_TYPE,
  NAME_OF_BANK,
  BSB,
  ACCOUNT_NUMBER,
  BALANCE,
  INTEREST,
} = T;

export const TRANSACTION_FEE_DEDUCTION_TYPES = [DOLLAR, PERCENTAGE];
export const TRUE_OR_FALSE_OPTIONS = [TRUE, FALSE];
export const BANKING_ENVIRONMENT_LIST = [LIVE, TEST];

export const VIEW_BANKING_TABLE_HEADER = [
  { label: LENDER_ACCOUNT_NAME },
  { label: FUNDING_TYPE },
  { label: NAME_OF_BANK },
  { label: BSB },
  { label: ACCOUNT_NUMBER },
  { label: BALANCE },
  { label: INTEREST },
];

export const EDIT_BANKING_TABLE_HEADER = [
  // { label: S_NO },
  { label: LENDER_ACCOUNT_NAME },
  { label: FUNDING_TYPE },
  { label: NAME_OF_BANK },
  { label: BSB },
  { label: ACCOUNT_NUMBER },
  { label: BALANCE },
  { label: INTEREST },
];
