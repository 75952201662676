// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';


// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {

  const { isLoading } = useSelector((state: any) => state.dashboard)

  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                {isLoading && <Loader />}
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  )
}

export default App;
