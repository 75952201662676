//React
import { useEffect, useReducer } from "react";

//Local imports
import KybDropzone from "./KybDropzone";
import { uploadKyb } from "store/reducers/companyKybTab";
import { convertDateIntoBEFormat, getBEDateFormat } from "utils/date";

//react-redux
import { useDispatch, useSelector } from "react-redux";

//Date-fns
import { isValid } from "date-fns";

const EditKyb = () => {
    const dispatch =useDispatch();
    // const  { selectedCompany } = useSelector((state:any)=>state.dashboardSearch);
    const {  documentUrls, status: kybStatus, comments: kybComments, staffName: kybStaffName, employeeId: kybEmployeeId, verificationCompletedDate : kybVerificationCompletedDate, reVerificationDueDate: kybReVerificationDueDate}= useSelector((state: any)=>state.kybTabData);
    
    const [localState, setLocalState]= useReducer(
        (prevState:any, newState:any)=>({...prevState, ...newState}),
        {
            documentUrl:[],
            status:"Unverified",
            comments:"",
            cashDStaffName:"",
            employeeId:"",
            verificationCompletedDate:null,
            reVerificationDueDate:null,
        }
    );

    const {
        documentUrl,
        status,
        comments,
        cashDStaffName,
        employeeId,
        verificationCompletedDate,
        reVerificationDueDate,
    } = localState;
    

    useEffect(()=>{
        const verificationCompletedDatePayload = kybVerificationCompletedDate?convertDateIntoBEFormat(kybVerificationCompletedDate):null;
        const reVerificationDueDatePayload = kybReVerificationDueDate?convertDateIntoBEFormat(kybReVerificationDueDate):null;
        setLocalState({
            documentUrl: documentUrls,
            status: kybStatus,
            comments: kybComments,
            cashDStaffName: kybStaffName,
            employeeId: kybEmployeeId,
            verificationCompletedDate: verificationCompletedDatePayload,
            reVerificationDueDate: reVerificationDueDatePayload,
        })
    },[documentUrls]);

    const onHandleChange =(e:any)=>{
        const {name, value} = e.target;
        setLocalState({[name]: value});
    };

    const handleRadioChange =(e: any)=>{
        const {name, value} =e.target;
        setLocalState({[name]:value});
    };

     const onHandleDateChange = (newValue:any,type:string) => {
    const validDate = newValue ? new Date(newValue) : null;

    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null
    });
  };
  const handleRemoveUrl=(index: number)=>{
    let urlArray= [...documentUrl];
    urlArray.splice(index,1);
    setLocalState({documentUrl:urlArray})
  }

    const onUpload =(files:any)=>{
        let formdata = new FormData();
        for (let i = 0; i < files.length; i++) {
            formdata.append('files', files[i]);
          }
        const payload ={
            formdata,
            documentUrl,
            status,
            comments,
            cashDStaffName,
            employeeId,
            verificationCompletedDate,
            reVerificationDueDate};
        dispatch(uploadKyb(payload));
    }
    

    const renderKbyEdit = ()=> ({
        status,
        comments,
        cashDStaffName,
        employeeId,
        verificationCompletedDate,
        reVerificationDueDate,
        documentUrl,
        onUpload,
        handleRemoveUrl,
        onHandleChange,
        onHandleDateChange,
        handleRadioChange
    });

    return (
        <>
            <KybDropzone {...renderKbyEdit()} />   
        </>
    );
};

export default EditKyb;